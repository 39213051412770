import React from 'react';
import { Field } from 'formik';
import classNames from 'classnames';
import Get from 'lodash.get';
import Translated from '../elements/Translated';
import Required from '../elements/Required';

const RadioButton = ({
  field: {
    name, value, onChange, onBlur,
  },
  id,
  label,
  vertical,
  afterChange,
  ...props
}) => (
  <label
    className={classNames('radio', {
      vertical,
    })}
  >
    <input
      type="radio"
      name={name}
      id={id}
      value={id}
      checked={id === value}
      onChange={(e) => {
        onChange(e);
        if (afterChange) {
          afterChange();
        }
      }}
      onBlur={onBlur}
      className="radio-button"
      {...props}
    />
    <Translated k={label} />
  </label>
);

const InputRadio = ({
  name, label, options, errors, vertical, afterChange, required,
}) => (
  <div className="field">
    {label && (
      <label className="label">
        <Translated k={label} />
        <Required required={required} />
      </label>
    )}
    <div className="control">
      {options.map(o => (
        <Field
          key={o.value}
          component={RadioButton}
          name={name}
          id={o.value}
          label={o.label}
          vertical={vertical}
          afterChange={afterChange}
        />
      ))}
    </div>
    {Get(errors, name) && (
      <p className="help is-danger">
        <Translated k={Get(errors, name)} />
      </p>
    )}
  </div>
);

export default InputRadio;
