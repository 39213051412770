import { Component } from 'react';
import Axios from 'axios';
import buildUrl from './filter';
import appUrl from './remote';

class CrudList extends Component {
  filters = {};

  loadingTimeout = null;

  constructor(props) {
    super(props);
    const { initialSorting } = props;

    this.state = {
      loading: true,
      params: {
        startPosition: 0,
        ...initialSorting,
      },
      data: null,
      error: null,
    };
  }

  componentDidMount() {
    const { initialFilter } = this.props;
    this.filters = initialFilter;
    this.refreshResults();
  }

  handleNavigation = (page) => {
    const { params } = this.state;
    const startPosition = (page - 1) * this.filters.maxResults;

    this.setState({ params: { ...params, startPosition } }, this.refreshResults);
  };

  changeOrdering = (name) => {
    const { params } = this.state;
    this.setState(
      { params: { ...params, sort: name, asc: params.sort === name ? !params.asc : true } },
      this.refreshResults,
    );
  };

  updateFilters = (values) => {
    this.filters = values;
    this.refreshResults();
  };

  refreshResults = async () => {
    const { params } = this.state;
    const { restUrl } = this.props;

    this.loadingTimeout = setTimeout(() => {
      this.setState({ loading: true });
    }, 200);

    try {
      const { data } = await Axios.get(
        buildUrl(appUrl(restUrl), Object.assign(this.filters, params)),
      );
      clearTimeout(this.loadingTimeout);
      this.setState({ loading: false, data });
    } catch (err) {
      clearTimeout(this.loadingTimeout);
      this.setState({ loading: false, error: (err.response !== undefined) ? `${err.response.data.message}` : err.message });
    }
  };

  removeEntity = async (id) => {
    const { restUrl } = this.props;

    try {
      await Axios.delete(appUrl(`${restUrl}/${id}`));
      this.refreshResults();
    } catch (err) {
      this.setState({ loading: false, error: (err.response !== undefined) ? `${err.response.data.message}` : err.message });
    }
  };

  render() {
    const { render, initialFilter } = this.props;

    const {
      loading, data, error, params,
    } = this.state;

    return render({
      loading,
      data,
      error,
      params,
      initialFilter,
      filters: this.filters,
      handleNavigation: this.handleNavigation,
      changeOrdering: this.changeOrdering,
      updateFilters: this.updateFilters,
      refreshResults: this.refreshResults,
      removeEntity: this.removeEntity,
    });
  }
}

export default CrudList;
