import React from 'react';

const Required = ({
  required,
}) => (
  <span>
    {required === true ? '*' : ''}
  </span>
);

export default Required;
