import React from 'react';
import Logo from '../../images/canadian-logo.png';


const Privacy = () => (
  <section className="is-fullheight">
    <div className="body">
      <div className="container">
        <figure className="avatar has-text-centered login-logo">
          <img src={Logo} alt="Canadian Island" />
        </figure>

        <p>
          <b>
INFORMATIVA AI SENSI DEL REGOLAMENTO
UE 2016/679 E DELLA NORMATIVA ITALIANA VIGENTE
          </b>
        </p>
        <p>
In ottemperanza a quanto previsto dal
Regolamento Europeo sulla protezione dei dati personali 2016/679 e
dal D.lgs. 30 giugno 2003, n. 196 c.d. Codice Privacy, La informiamo
che i dati personali e sensibili da Lei forniti e quelli che
eventualmente fornirà anche successivamente nell’ambito della
richiesta di iscrizione formeranno oggetto di trattamento nel
rispetto delle normative sopra richiamate. La presente informativa ha
lo scopo di farLe conoscere per quale finalità e con quali modalità
verranno trattati i Suoi dati, quali sono i Suoi diritti e come li
potrà esercitare:
        </p>
        <p>
          <b>TITOLARE DEL TRATTAMENTO:</b>
          {' '}
Il
Titolare del trattamento è CANADIAN ISLAND S.r.l. con sede in via
Gioberti 15, 50121 FIRENZE, il cui legale rappresentante è LUCIA
MINGARELLI, P.IVA 05069920485.
        </p>
        <p>
          <b>NATURA DEI DATI TRATTATI</b>
:
trattiamo i vostri dati anagrafici e fiscali, nonché dati di natura
economica che sono necessari per lo svolgimento dei rapporti
contrattuali; La informiamo, inoltre, che il trattamento potrà
riguardare anche categorie particolari di dati personali, i cd. dati
“sensibili”, in particolare relativi a:
        </p>
        <p>a) abitudini alimentari</p>
        <p>b) condizioni generali di salute</p>
        <p>
c) certificati attestanti particolari
patologie influenti sulla conoscenza di base del bambino;
        </p>
        <p>
d) iscrizione alla nostra newsletter
per informarvi riguardo a notizie inerenti nuovi campi scuola o altre
attività organizzate dalla scuola
        </p>
        <p>
La finalità per la quale vengono
raccolti e trattati questi dati non costituisce in alcun modo
profilazione o processo automatizzato, ma è strettamente ed
esclusivamente connessa ad un’erogazione funzionale del servizio.
        </p>
        <p>
          <b>FINALITÀ DEL TRATTAMENTO</b>
: i
dati da Voi forniti verranno trattati in relazione alle esigenze
contrattuali ed ai conseguenti adempimenti degli obblighi di legali e
fiscali, nonché per consentire un’efficace gestione dei rapporti
finanziari e commerciali.
        </p>
        <p>
          <b>MODALITÀ DEL TRATTAMENTO</b>
: il
trattamento sarà effettuato secondo le seguenti modalità:
manuale-cartaceo ed informatizzato, nel rispetto della normativa
vigente e comunque mediante misure tecniche ed organizzative idonee a
garantire la loro sicurezza e riservatezza.
        </p>
        <p>
          <b>DURATA DEL TRATTAMENTO</b>
: I dati
verranno trattati per tutta la durata del rapporto contrattuale ed
anche successivamente, per l’espletamento di obblighi di legge e
per finalità amministrative e commerciali.
        </p>
        <p>
          <b>
AMBITO DI CONOSCENZA DEI VOSTRI
DATI
          </b>
: i dati potranno anche essere comunicati e trattati da altri
enti, pubblici e privati: per obblighi di legge, per l’erogazione e
la gestione del servizio od in quanto da Voi espressamente
autorizzati. Tali soggetti o categorie di soggetti opereranno in
qualità di responsabili o come soggetti incaricati all’autorità
del Titolare del trattamento. Presso l’Ente è disponibile l’elenco
dei soggetti cui i dati sono stati o saranno comunicati. I dati non
vengono diffusi né trasferiti all’estero e possono essere trattati
esclusivamente per le finalità di cui sopra.
        </p>
        <p>
          <b>
OBBLIGO O
FACOLTÀ DI CONFERIRE I DATI
          </b>
: il conferimento dei dati è
obbligatorio, in quanto da essi dipende la corretta gestione ed
erogazione del servizio. L’eventuale rifiuto di fornire tali dati
comporta, quindi l’impossibilità da parte nostra di erogare i
servizi e di adempiere agli obblighi nei suoi confronti. In merito ai
dati ritenuti dalla legge sensibili la legge prevede un consenso
scritto da parte dell’interessato, vi chiediamo pertanto di
restituire firmato il presente documento per permetterci di assolvere
ai necessari adempimenti di legge. È, inoltre, necessario il Vostro
consenso per l’eventuale pubblicazione della foto del Vostro
bambino sul nostro sito e sui social (Instagram e Facebook), oltre a
varie rappresentazioni cartacee, e per l’iscrizione alla
newsletter.
        </p>
        <p>
          <b>I VOSTRI DIRITTI</b>
: La informiamo
che, in relazione ai predetti trattamenti, ha diritto di accesso
(art.15), rettifica (art.16), cancellazione (diritto all’oblio,
art.17) dei Suoi dati personali, oltre al diritto di ottenere la
limitazione del trattamento (art.18) e il diritto alla portabilità
dei dati (art.20).
        </p>
        <p>
Per esercitare tali diritti potete
telefonare allo 055677567 o scrivere all’indirizzo di posta
elettronica
          {' '}
          {' '}
          <font color="#000080"><u><a href="mailto:info@canadianisland.it">info@canadianisland.it</a></u></font>
.
        </p>
        <p>
          <br />

        </p>
        <p><i><b>CANADIAN ISLAND S.r.l.</b></i></p>
        <p><i><b>Lucia Mingarelli</b></i></p>
      </div>
    </div>
  </section>
);

export default Privacy;
