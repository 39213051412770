import Axios from 'axios';
import {Formik} from 'formik';
import moment from 'moment';
import React, {Fragment} from 'react';
import * as Yup from 'yup';
import ButtonModal from '../../../base/components/modal/ButtonModal';
import TabPanel from '../../../base/components/tabs/TabPanel';
import ColumnOutput from '../../../base/elements/ColumnOutput';
import DateOutput from '../../../base/elements/DateOutput';
import ErrorNotification from '../../../base/elements/ErrorNotification';
import Translated from '../../../base/elements/Translated';
import InputDate from '../../../base/form/InputDate';
import InputText from '../../../base/form/InputText';
import InvoiceTotal from '../../../pages/back/orders/invoices/InvoiceTotal';
import appUrl from '../../../utils/remote';
import {PeopleSchema} from '../../PeopleForm';
import momentDate from '../../utils/momentSchema';
import OrderBillingDetailsEditForm from '../billing/OrderBillingDetailsEditForm';
import InvoiceRowForm from './InvoiceRowForm';
import RemoteInputSelect from "../../../base/form/RemoteInputSelect";
import MoneyInput from "../../../base/elements/MoneyInput";
import RemoteEntityInputSelect from "../../../base/form/RemoteEntityInputSelect";

const InvoiceFormSchema = Yup.object().shape({
  billingHolder: PeopleSchema,
  date: momentDate().required('general.validation.required'),
  bankAccount: Yup.object().when(['paymentMethod'], {
    is: (paymentMethod) => paymentMethod !== 'CASH',
    then: Yup.object().shape({
      id: Yup.number().required('general.validation.required')
    })
  }),
  expirationDate: Yup.string()
      .required('general.validation.required'),
  invoiceNumber: Yup.string()
    .required('general.validation.required')
    .default(''),
  invoiceSuffix: Yup.string()
});

const updateInvoice = async (resourceId, data, handleUpdateTotal) => {
  const response = await Axios.put(appUrl('/calculator/updateInvoice'), data);
  handleUpdateTotal(response.data);
};

const updateInvoiceNumber = async (resourceId, data, setFieldValue) => {
  let url = `/orders/${resourceId}/invoices/invoiceNumber/${moment(data.date).format('YYYY')}`;
  const response = await Axios.get(
    appUrl(url), {params: {suffix: data.invoiceSuffix}},
  );
  setFieldValue('invoiceNumber', response.data);
};

const handleButtonClick = async (data, resourceId, onSuccess, addPayment, formik) => {
  formik.setSubmitting(true);
  await formik.submitForm();
  if (!formik.isValidating && formik.isValid) {
    try {
      await Axios.post(appUrl(`/orders/${resourceId}/invoices?addPayment=${addPayment}`), data);
      onSuccess();
    } catch (err) {
      formik.setErrors({
        failed: err.response !== undefined ? `${err.response.data.message}` : err.message,
      });
      formik.setSubmitting(false);
    }
  }
}

const InvoiceForm = ({
  onSuccess,
  handleClose,
  handleUpdateTotal,
  initialValues = {},
  resourceId,
  invoiceResidue,
  invoiceTotal,
}) => (
  <Formik
    initialValues={InvoiceFormSchema.cast(initialValues)}
    validationSchema={InvoiceFormSchema}
    onSubmit={() => {}}
    render={({
      dirty,
      isSubmitting,
      setErrors,
      values,
      setFieldValue,
      ...others
    }) => (
      <form>
        <header className="modal-card-head">
          <p className="modal-card-title">
            <Translated k="pages.back.orders.details.billing.createInvoice" />
          </p>
          <button type="button" className="delete" aria-label="close" onClick={handleClose} />
        </header>
        <section className="modal-card-body">
          <ErrorNotification clearError={() => setErrors({ failed: null })} {...others} />
          <TabPanel
            titles={[
              'pages.back.orders.details.billing.name',
              'pages.back.orders.details.billing.information',
            ]}
          >
            <div>
              <div className="columns">
                <div className="column">
                  <InputText
                    name="invoiceNumber"
                    label="pages.back.orders.details.billing.number"
                    small
                    values={values}
                    setFieldValue={setFieldValue}
                    {...others}
                  />
                </div>
                <div className="column">
                  <InputText
                    name="invoiceSuffix"
                    label="pages.back.orders.details.billing.suffix"
                    small
                    values={values}
                    setFieldValue={setFieldValue}
                    {...others}
                    handleBlur={async () => {
                      updateInvoiceNumber(resourceId, values, setFieldValue);
                    }}
                  />
                </div>
                <div className="column">
                  <InputDate
                    name="date"
                    label="pages.back.orders.details.billing.date"
                    values={values}
                    setFieldValue={setFieldValue}
                    handleBlurExtraAction
                    {...others}
                    small
                    handleBlur={async () => {
                      updateInvoiceNumber(resourceId, values, setFieldValue);
                    }}
                  />
                </div>
              </div>
              <InputText
                name="invoiceDescription"
                label="pages.back.orders.details.billing.description"
                small
                values={values}
                setFieldValue={setFieldValue}
                {...others}
              />
              <div className="columns">
                <div className="column">
                  <RemoteInputSelect
                      values={values}
                      setFieldValue={setFieldValue}
                      name="stampType"
                      label="pages.back.orders.details.billing.stampType"
                      url="/orders/stampTypes"
                      small
                      {...others}
                      handleValueChange={async (e) => {
                        updateInvoice(resourceId, values, handleUpdateTotal)
                      }}
                  />
                </div>
                <div className="column">
                  <MoneyInput
                      values={values}
                      setFieldValue={setFieldValue}
                      name="stampAmount.value"
                      label="pages.back.orders.details.billing.stampAmount"
                      leftLabel="€"
                      disableAutoComplete
                      small
                      {...others}
                      handleBlur={async (e) => {
                        others.handleBlur(e);
                        updateInvoice(resourceId, values, handleUpdateTotal)
                      }}
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <RemoteInputSelect
                      values={values}
                      setFieldValue={setFieldValue}
                      name="paymentMethod"
                      label="model.order.paymentMethod.entityName"
                      url="/fo/orders/paymentMethods"
                      small
                      {...others}
                  />
                </div>
                <div className="column">
                  <InputDate
                      name="expirationDate"
                      label="pages.back.orders.details.billing.expiration.date"
                      values={values}
                      setFieldValue={setFieldValue}
                      handleBlurExtraAction
                      {...others}
                      small
                  />
                </div>
                <div className="column">
                  <RemoteEntityInputSelect
                      values={values}
                      setFieldValue={setFieldValue}
                      name="bankAccount.id"
                      label="pages.back.orders.details.bankAccount"
                      url="/bankAccounts"
                      small
                      {...others}
                  />
                </div>
              </div>
              <InvoiceRowForm
                resourceId={resourceId}
                handleUpdateTotal={async (e) => {
                  others.handleBlur(e);
                  updateInvoice(resourceId, values, handleUpdateTotal);
                }}
                values={values}
                setFieldValue={setFieldValue}
                {...others}
              />
              <InvoiceTotal invoiceResidue={invoiceResidue} invoiceTotal={invoiceTotal} />
            </div>
            <div>
              <div className="box">
                <div className="columns">
                  <ColumnOutput
                    label="model.person.personType.entityName"
                    render={() => (
                      <Translated
                        k={`model.person.personType.${values.billingHolder.personType}`}
                      />
                    )}
                  />
                </div>
                <div className="columns">
                  <ColumnOutput
                    label="model.person.firstName"
                    render={() => values.billingHolder.firstName}
                  />
                  <ColumnOutput
                    label="model.person.lastName"
                    render={() => values.billingHolder.lastName}
                  />
                  {values.billingHolder.personType === 'JURIDICAL' ? (
                    <ColumnOutput
                      label="model.person.registeredName"
                      render={() => values.billingHolder.registeredName}
                    />
                  ) : (
                    <div className="column" />
                  )}
                </div>

                <div className="columns">
                  <ColumnOutput
                    label="model.person.dob"
                    render={() => <DateOutput value={values.billingHolder.dateOfBirth} />}
                  />
                  <ColumnOutput
                    label="model.person.gender"
                    render={() => values.billingHolder.personGender}
                  />
                  <ColumnOutput
                    label="model.person.ssnCode"
                    render={() => values.billingHolder.ssnCode}
                  />
                </div>
                <div className="columns">
                  <ColumnOutput
                    label="model.person.email"
                    render={() => values.billingHolder.email}
                  />
                  <ColumnOutput
                    label="model.person.phoneNumber"
                    render={() => values.billingHolder.phoneNumber}
                  />
                  {values.billingHolder.personType === 'JURIDICAL' ? (
                    <ColumnOutput
                      label="model.person.vatId"
                      render={() => values.billingHolder.vatId}
                    />
                  ) : (
                    <div className="column" />
                  )}
                </div>
                <div className="columns">
                  <ColumnOutput
                    label="model.person.address.street"
                    render={() => (values.billingHolder.address !== undefined
                      ? values.billingHolder.address.street
                      : '')
                    }
                  />
                  <ColumnOutput
                    label="model.person.address.number"
                    render={() => (values.billingHolder.address !== undefined
                      ? values.billingHolder.address.number
                      : '')
                    }
                  />
                  <ColumnOutput
                    label="model.person.address.district"
                    render={() => (values.billingHolder.address !== undefined
                      ? values.billingHolder.address.district
                      : '')
                    }
                  />
                </div>
                <div className="columns">
                  <ColumnOutput
                    label="model.person.address.code"
                    render={() => (values.billingHolder.address !== undefined
                      ? values.billingHolder.address.code
                      : '')
                    }
                  />
                  <ColumnOutput
                    label="model.person.address.city"
                    render={() => (values.billingHolder.address !== undefined
                      ? values.billingHolder.address.city
                      : '')
                    }
                  />
                  <ColumnOutput
                    label="model.person.address.country"
                    render={() => (values.billingHolder.address !== undefined
                      ? <Translated k={`model.country.${values.billingHolder.address.country}`} />
                      : '')
                    }
                  />
                </div>
              </div>

              <div className="field is-grouped is-grouped-right">
                <ButtonModal
                  buttonClassName="is-default"
                  buttonLabel="pages.shared.orders.billingDetails.edit"
                  render={({ handleClose: close }) => (
                    <Fragment>
                      <header className="modal-card-head">
                        <p className="modal-card-title">
                          <Translated k="pages.shared.orders.billingDetails.edit" />
                        </p>
                        <button
                          type="button"
                          className="delete"
                          aria-label="close"
                          onClick={close}
                        />
                      </header>
                      <OrderBillingDetailsEditForm
                        onCancel={close}
                        initialValues={values.billingHolder}
                        onSuccess={(b) => {
                          setFieldValue('billingHolder', b);
                          close();
                        }}
                      />
                    </Fragment>
                  )}
                />
              </div>
            </div>
          </TabPanel>
        </section>
        <footer className="modal-card-foot" style={{ justifyContent: 'flex-end' }}>
          <button
              type="button"
              className="button is-primary"
              disabled={isSubmitting}
              onClick={() => handleButtonClick(values, resourceId, onSuccess, false, others)}
          >
            <Translated k="general.confirm" />
          </button>
          <button
              type="button"
              className="button is-info"
              disabled={isSubmitting}
              onClick={() => handleButtonClick(values, resourceId, onSuccess, true, others)}
          >
            <Translated k="pages.back.orders.invoices.confirmAndAdd" />
          </button>
          <button type="button" className="button" onClick={handleClose}>
            <Translated k="general.close" />
          </button>
        </footer>
      </form>
    )}
  />
);

export default InvoiceForm;
