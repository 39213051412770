import React, { Fragment } from 'react';
import Translated from '../../../../base/elements/Translated';
import PaymentForm from '../../../../forms/orders/payments/PaymentForm';
import WithRemoteData from '../../../../utils/WithRemoteData';

const PaymentAdd = ({ handleSuccess, handleClose, resourceId }) => (
  <WithRemoteData
    url={`/orders/${resourceId}/payments/defaultPayment`}
    render={({ loading, data }) => loading === false
    && data !== null && (
    <Fragment>
      <header className="modal-card-head">
        <p className="modal-card-title">
          <Translated k="pages.back.orders.details.payments.add" />
        </p>
        <button type="button" className="delete" aria-label="close" onClick={handleClose} />
      </header>
      <PaymentForm
        onCancel={handleClose}
        onSuccess={() => {
          handleSuccess();
          handleClose();
        }}
        initialValues={data}
        resourceId={resourceId}
      />
    </Fragment>
    )
  }
  />
);

export default PaymentAdd;
