import React from 'react';

import CartForm from '../../../../forms/orders/cart/CartForm';

const CartOverviewDetails = ({ initialValues, handleSuccess, readOnly }) => (
  <CartForm
    initialValues={initialValues}
    resourceId={initialValues.id}
    onSuccess={handleSuccess}
    readOnly={readOnly}
  />
);

export default CartOverviewDetails;
