import React from 'react';
import { Formik } from 'formik';
import Axios from 'axios';
import 'moment/locale/it';
import appUrl from '../utils/remote';
import ErrorNotification from '../base/elements/ErrorNotification';
import SubmitButton from '../base/form/SubmitButton';
import { PartecipantSchema } from './orders/people/OrderPeopleForm';
import PartecipantFormFields from '../pages/back/partecipants/PartecipantFormFields';
import Translated from '../base/elements/Translated';

const PartecipantForm = ({
  onSuccess, onCancel, resourceId, initialValues = {},
}) => (
  <Formik
    initialValues={PartecipantSchema.cast(initialValues)}
    validationSchema={PartecipantSchema}
    onSubmit={async (data, actions) => {
      try {
        await Axios.put(appUrl(`/partecipants/${resourceId}/person/`), { ...data });
        onSuccess();
      } catch (err) {
        actions.setErrors({ failed: (err.response !== undefined) ? `${err.response.data.message}` : err.message });
      }
      actions.setSubmitting(false);
    }}
    render={({
      dirty, isSubmitting, handleSubmit, setErrors, ...others
    }) => (
      <form onSubmit={handleSubmit}>
        <section className="modal-card-body">
          <ErrorNotification clearError={() => setErrors({ failed: null })} {...others} />
          <PartecipantFormFields {...others} />
        </section>
        <footer className="modal-card-foot" style={{ justifyContent: 'flex-end' }}>
          <SubmitButton dirty={dirty} isSubmitting={isSubmitting} />
          <button type="button" className="button" onClick={onCancel}>
            <Translated k="general.close" />
          </button>
        </footer>
      </form>
    )}
  />
);

export default PartecipantForm;
