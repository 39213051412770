import React from 'react';
import Translated, { UnsafeTranslated } from '../../../base/elements/Translated';
import Title from '../../../base/elements/Title';

const EventDelete = ({ name, handleConfirm, handleClose }) => (
  <div className="message is-warning">
    <div className="message-body">
      <div className="level">
        <Title size={3} label="pages.back.events.delete.title" />
      </div>
      <p style={{ marginBottom: '1.5rem' }}>
        <UnsafeTranslated k="pages.back.events.delete.message" p={name} />
      </p>
      <div className="field is-grouped is-grouped-right">
        <div className="control">
          <button
            type="button"
            className="button is-primary"
            onClick={handleConfirm}
          >
            <Translated k="general.confirm" />
          </button>
        </div>
        <div className="control">
          <button type="button" className="button" onClick={handleClose}>
            <Translated k="general.close" />
          </button>
        </div>
      </div>
    </div>
  </div>
);

export default EventDelete;
