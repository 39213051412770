import React, { Fragment } from 'react';
import Axios from 'axios';
import WithRemoteData from '../../utils/WithRemoteData';
import Translated from '../../base/elements/Translated';
import CustomerForm from '../../forms/CustomerForm';
import appUrl from '../../utils/remote';

const ModifyUserData = ({ handleClose, resourceId }) => (
  <WithRemoteData
    url="/fo/users"
    render={({ loading, data }) => loading === false
      && data !== null && (
        <Fragment>
          <div className="has-text-black">
            <header className="modal-card-head">
              <p className="modal-card-title">
                <Translated k="pages.back.customers.edit" />
              </p>
              <button type="button" className="delete" aria-label="close" onClick={handleClose} />
            </header>
            <CustomerForm
              mandatoryPeopleFields
              onCancel={handleClose}
              onSuccess={() => {
                handleClose();
              }}
              onSubmit={async ({ person, locale, newsletterConsent }) => Axios.put(appUrl('/fo/users'), {
                person,
                locale,
                newsletterConsent,
              })}
              initialValues={{
                ...data,
                role: data.roles[0],
              }}
              resourceId={resourceId}
              showLocale
              showNewsletterConsent
            />
          </div>
        </Fragment>
    )
    }
  />
);

export default ModifyUserData;
