/* eslint
    jsx-a11y/anchor-is-valid: "off",
    jsx-a11y/interactive-supports-focus: "off",
    jsx-a11y/click-events-have-key-events: "off"
*/
import React from 'react';
import Modal from './modal/Modal';

const Card = ({
  imgUrl, title, subtitle, description, ...others
}) => (
  <Modal
    renderTrigger={({ handleOpen }) => (
      <div role="button" className="media clickable" onClick={handleOpen}>
        {imgUrl && (
        <figure className="image event-type-image media-left">
          <img src={imgUrl} alt={title} />
        </figure>
        )}
        <div className="media-content">
          <div className="content">
            <p className="has-text-primary is-size-3">{title}</p>
            <p>{subtitle}</p>
            {description}
          </div>
        </div>
      </div>
    )}
    {...others}
  />
);

export default Card;
