import React, {Fragment} from 'react';
import WithRemoteData from '../../../utils/WithRemoteData';
import TabPanel from '../../../base/components/tabs/TabPanel';
import Translated from '../../../base/elements/Translated';
import PaymentOverviewDetails from './payments/PaymentOverviewDetails';
import InvoiceOverviewDetails from './invoices/InvoiceOverviewDetails';
import OrderDetailsOverview from './OrderDetailsOverview';
import CartOverviewDetails from './cart/CartOverviewDetails';
import PartecipantOverviewDetails from './PartecipantOverviewDetails';
import BillingInformationOverviewDetails from './BillingInformationOverviewDetails';
import PersonDetails from '../people/PersonDetails';
import CreditNoteOverviewDetails from "./invoices/creditNotes/CreditNoteOverviewDetails";

const OrderDetails = ({ orderId, handleClose, refreshResults }) => (
  <WithRemoteData
    url={`/orders/${orderId}`}
    render={({ loading, data, refresh }) => loading === false
      && data !== null && (
        <Fragment>
          <header className="modal-card-head">
            <p className="modal-card-title">
              <Translated k="pages.back.orders.details.purchase" />
            </p>
            <button type="button" className="delete" aria-label="close" onClick={handleClose} />
          </header>
          <section className="modal-card-body">
            <TabPanel
              titles={[
                'pages.back.orders.details.description.user',
                'pages.back.orders.details.description.title',
                'general.navbar.partecipants',
                'pages.back.orders.details.cart.name',
                'pages.back.orders.details.billing.information',
                'pages.back.orders.details.billing.name',
                'pages.back.orders.details.billing.creditnote',
                'pages.back.orders.details.payments.name',
              ]}
            >
              <div>
                <PersonDetails data={data.author.person} />
              </div>
              <div>
                <OrderDetailsOverview data={data} handleSuccess={refresh} />
              </div>
              <div>
                <PartecipantOverviewDetails data={data} />
              </div>
              <div>
                <CartOverviewDetails
                  initialValues={data}
                  handleSuccess={refresh}
                />
              </div>
              <div>
                <BillingInformationOverviewDetails data={data} handleSuccess={refresh} />
              </div>
              <div>
                <InvoiceOverviewDetails data={data} handleSuccess={refresh} />
              </div>
              <div>
                <CreditNoteOverviewDetails data={data} handleSuccess={refresh} />
              </div>
              <div>
                <PaymentOverviewDetails data={data} handleSuccess={refresh} />
              </div>
            </TabPanel>
          </section>
          <footer className="modal-card-foot" style={{ justifyContent: 'flex-end' }}>
            <button type="button" className="button" onClick={() => { refreshResults(); handleClose(); }}>
              <Translated k="general.close" />
            </button>
          </footer>
        </Fragment>
    )
    }
  />
);

export default OrderDetails;
