import React from 'react';
import {Formik} from 'formik';
import Axios from 'axios';
import * as Yup from 'yup';
import classNames from 'classnames';
import InputText from '../base/form/InputText';
import InputPassword from '../base/form/InputPassword';
import appUrl from '../utils/remote';
import Translated from '../base/elements/Translated';
import constants from '../utils/constants';
import ErrorNotification from '../base/elements/ErrorNotification';

const LoginSchema = Yup.object().shape({
  email: Yup.string()
    .required('general.validation.required')
    .default(''),
  password: Yup.string()
    .min(constants.passwordLength, 'general.validation.password.length')
    .required('general.validation.required')
    .default(''),
});

const LoginForm = ({ onSuccess, submitColor }) => (
  <Formik
    initialValues={LoginSchema.cast({})}
    validationSchema={LoginSchema}
    onSubmit={async ({ email, password }, actions) => {
      try {
        await Axios.post(appUrl('/auth/login'), { username: email, password });
        onSuccess();
      } catch (err) {
        actions.setErrors({
          failed: err.response !== undefined ? `${err.response.data.message}` : err.message,
        });
      }
      actions.setSubmitting(false);
    }}
    render={({
      dirty, isSubmitting, handleSubmit, ...others
    }) => (
      <form onSubmit={handleSubmit}>
        <ErrorNotification {...others} />
        <InputText
          name="email"
          label="model.person.email"
          placeholder="E-mail"
          {...others}
        />
        <InputPassword
          name="password"
          label="model.user.password"
          passwordLength={constants.passwordLength}
          {...others}
        />
        <button
          type="submit"
          className={classNames('button is-primary is-fullwidth has-text-weight-bold', {
            'is-loading': isSubmitting,
          })}
          style={{backgroundColor: submitColor || undefined, borderRadius: '50px'}}
          disabled={isSubmitting}
        >
          <Translated k="pages.auth.login" />
        </button>
      </form>
    )}
  />
);

export default LoginForm;
