import React, { Fragment } from 'react';
import WithRemoteData from '../../utils/WithRemoteData';
import Translated from '../../base/elements/Translated';
import TabPanel from '../../base/components/tabs/TabPanel';
import OrderDetailsOverview from '../back/orders/OrderDetailsOverview';
import InvoiceOverviewDetails from '../back/orders/invoices/InvoiceOverviewDetails';
import PaymentOverviewDetails from '../back/orders/payments/PaymentOverviewDetails';
import CartOverviewDetailsFo from './CartOverviewDetailsFo';
import BillingInformationOverviewDetails from '../back/orders/BillingInformationOverviewDetails';
import PartecipantOverviewDetails from '../back/orders/PartecipantOverviewDetails';

const OrderDetails = ({ url, handleClose, readOnly }) => (
  <WithRemoteData
    url={url}
    render={({ loading, data, refresh }) => loading === false
      && data !== null && (
        <Fragment>
          <header className="modal-card-head">
            <p className="modal-card-title">
              <Translated k="pages.back.orders.details.purchase" />
            </p>
            <button type="button" className="delete" aria-label="close" onClick={handleClose} />
          </header>
          <section className="modal-card-body">
            <TabPanel
              titles={[
                'pages.back.orders.details.description.title',
                'general.navbar.partecipants',
                'pages.back.orders.details.cart.name',
                'pages.back.orders.details.billing.information',
                'pages.back.orders.details.billing.name',
                'pages.back.orders.details.payments.name',
              ]}
            >
              <div>
                <OrderDetailsOverview data={data} handleSuccess={refresh} readOnly={readOnly} />
              </div>
              <div>
                <PartecipantOverviewDetails data={data} readOnly={readOnly} />
              </div>
              <div>
                <CartOverviewDetailsFo
                  initialValues={data}
                  handleSuccess={refresh}
                  readOnly={readOnly}
                />
              </div>
              <div>
                <BillingInformationOverviewDetails
                  data={data}
                  handleSuccess={refresh}
                  readOnly={readOnly}
                />
              </div>
              <div>
                <InvoiceOverviewDetails data={data} handleSuccess={refresh} readOnly={readOnly} />
              </div>
              <div>
                <PaymentOverviewDetails data={data} handleSuccess={refresh} readOnly={readOnly} />
              </div>
            </TabPanel>
          </section>
          <footer className="modal-card-foot" style={{ justifyContent: 'flex-end' }}>
            <button type="button" className="button" onClick={handleClose}>
              <Translated k="general.close" />
            </button>
          </footer>
        </Fragment>
    )
    }
  />
);

export default OrderDetails;
