/* eslint
    jsx-a11y/anchor-is-valid: "off",
    jsx-a11y/click-events-have-key-events: "off",
    react/no-array-index-key: "off"
*/

import React, { Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { AppContext } from '../../../App';
import Icon from '../../elements/Icon';

const MinimalAccount = () => (
  <Fragment>
    <AppContext.Consumer>
      {({ getUsername }) => (
        <Fragment>
          <NavLink to="/logout" className="navbar-item">
                  Logout
          </NavLink>
          <span className="navbar-item is-size-6 has-text-black-bis">
            <Icon name="fa-user-circle" />
            <span className="is-size-7">{getUsername()}</span>
          </span>
        </Fragment>
      )}
    </AppContext.Consumer>
  </Fragment>
);

export default MinimalAccount;
