import React, {Fragment} from 'react';
import Translated from "../../../../base/elements/Translated";

const DeleteModal = ({handleRemove, handleClose}) => (
  <Fragment>
    <header className="modal-card-head">
      <p className="modal-card-title">
        <Translated k="pages.shared.orders.partecipation.modal.confirmation.title"/>
      </p>
      <button type="button" className="delete" aria-label="close" onClick={handleClose}/>
    </header>
    <div className="modal-card-body">
      <p className="is-size-4 has-text-centered">
        <Translated k="pages.shared.orders.partecipation.modal.confirmation.question"/>
      </p>
      <div>
        <button type="button" className="button is-primary" onClick={handleRemove}>
          <Translated k="general.confirm"/>
        </button>
        <button type="button" className="button is-light" onClick={handleClose}>
          <Translated k="general.back"/>
        </button>
      </div>
    </div>
  </Fragment>
)

export default DeleteModal;