import React, { Fragment, Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import Logo from '../../../images/canadian-logo.png';
import SignupForm from '../../forms/SignupForm';
import Translated from '../../base/elements/Translated';
import LanguageSelector from './LanguageSelector';
import Title from '../../base/elements/Title';
import Axios from "axios";
import appUrl from "../../utils/remote";

class SignupPage extends Component {
  state = {
    signup: false,
    recaptchaKey: null,
  };

  componentDidMount() {
    this.initRecaptcha();
  }

  async initRecaptcha(){
    const result = await Axios.get(appUrl('/fo/users/recaptcha-web-key'));
    this.setState({recaptchaKey: result.data});
  }

  onSuccess = () => {
    this.setState({ signup: true });
  };

  render = () => {
    const { signup, recaptchaKey } = this.state;
    const { location } = this.props;
    return recaptchaKey && (
      <div className="has-background-white login-card">
        <figure className="avatar has-text-centered login-logo">
          <img src={Logo} alt="Canadian Island" />
        </figure>
        {signup === false && (
          <Fragment>
            <Title size={4} section label="pages.auth.signUp" />
            <SignupForm onSuccess={this.onSuccess} recaptchaKey={recaptchaKey}/>
          </Fragment>
        )}
        {signup && (
          <p className="has-text-centered">
            <strong>
              <Translated k="model.user.email.password" />
            </strong>
          </p>
        )}
        <hr />
        <p className="has-text-centered" style={{ fontWeight: 700 }}>
          <Link to={`/?locale=${queryString.parse(location.search).locale || 'it-IT'}`} className="has-text-grey">
            <Translated k="pages.auth.login" />
          </Link>
        </p>
        <div className="columns is-centered columns-content">
          <LanguageSelector />
        </div>
      </div>
    );
  };
}

export default withRouter(SignupPage);
