/* eslint
    jsx-a11y/anchor-is-valid: "off",
    jsx-a11y/interactive-supports-focus: "off",
    jsx-a11y/click-events-have-key-events: "off"
*/
import React from 'react';
import classNames from 'classnames';
import Translated from '../../elements/Translated';

const Tabs = ({ titles, activeIndex, handleClick }) => (
  <div className="tabs is-toggle is-fullwidth tab-panel-header">
    <ul>
      {titles.map((t, i) => (
        <li key={t} className={classNames({ 'is-active': i === activeIndex })}>
          <a role="button" onClick={() => handleClick(i)}>
            <span>
              <Translated k={t} />
            </span>
          </a>
        </li>
      ))}
    </ul>
  </div>
);

export default Tabs;
