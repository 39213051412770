import React, { Component } from 'react';
import debounce from 'lodash.debounce';
import isEqual from 'lodash.isequal';

class SubmitOnChange extends Component {
  commit = debounce(() => {
    const { handleSubmit } = this.props;
    handleSubmit({ preventDefault: () => {} });
  }, 300);

  componentWillReceiveProps(nextProps) {
    const { values } = this.props;
    if (!isEqual(nextProps.values, values)) {
      this.commit();
    }
  }

  render() {
    return <div />;
  }
}

export default SubmitOnChange;
