import React, {Component} from 'react';
import Get from 'lodash.get';
import classNames from 'classnames';
import DatePicker from 'react-datepicker';
import Translated from '../elements/Translated';
import {AppContext} from '../../App';
import Required from '../elements/Required';

class InputDate extends Component {
  handleChange = (value) => {
    const { name, setFieldValue } = this.props;
    setFieldValue(name, value);
  };

  handleBlur = () => {
    const {
      name, setFieldTouched, handleBlur, handleBlurExtraAction,
    } = this.props;
    setFieldTouched(name, true);

    if (handleBlurExtraAction) {
      handleBlur();
    }
  };

  render() {
    const {
      name, label, values, errors, touched, small, required,
    } = this.props;

    return (
      <div className="field">
        {label && (
          <label
            className={classNames('label', {
              'is-small': small,
            })}
          >
            <Translated k={label} />
            <Required required={required} />
          </label>
        )}
        <div className="control">
          <AppContext.Consumer>
            {({ getLocale, translate }) => (
              <DatePicker
                id={name}
                name={name}
                className={classNames('input', {
                  'is-danger': Get(errors, name) && Get(touched, name),
                  'is-small': small,
                })}
                locale={getLocale()}
                selected={Get(values, name)}
                onChange={this.handleChange}
                onBlur={this.handleBlur}
                dayClassName={date => (date.isSame(Get(values, name)) ? 'has-background-primary' : '')}
                showYearDropdown
                disabledKeyboardNavigation
                autoComplete="off"
                popperPlacement="top"
                placeholderText={translate('general.dateFormat')}
              />)}
          </AppContext.Consumer>
        </div>
        {Get(errors, name) && (
          <p className="help is-danger">
            <Translated k={Get(errors, name)} />
          </p>
        )}
      </div>
    );
  }
}

export default InputDate;
