import React from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
import SubmitButton from '../../../base/form/SubmitButton';
import ErrorNotification from '../../../base/elements/ErrorNotification';
import InputDate from '../../../base/form/InputDate';
import Translated from '../../../base/elements/Translated';
import appUrl from '../../../utils/remote';
import momentDate from '../../utils/momentSchema';
import constants from '../../../utils/constants';
import MoneyInput from '../../../base/elements/MoneyInput';
import priceSchema from "../../utils/priceSchema";
import InputText from "../../../base/form/InputText";
import InputSelect from "../../../base/form/InputSelect";
import InputNumber from "../../../base/form/InputNumber";

const CreditNoteFormSchema = Yup.object().shape({
  invoiceId: Yup.number().required('general.validation.required'),
  date: momentDate().required('general.validation.required'),
  creditNoteAmount: priceSchema,
  creditNoteNumber: Yup.string()
    .required('general.validation.required'),
  vat: Yup.object().shape({
    value: Yup.number()
      .typeError('general.validation.number')
      .required('general.validation.required')
      .default(0),
    description: Yup.string().default(''),
  })
});

const CreditNoteForm = ({onSuccess, onCancel, resourceId, creditNoteId, invoices}) => (
  <Formik
    initialValues={CreditNoteFormSchema.cast({})}
    validationSchema={CreditNoteFormSchema}
    onSubmit={async (data, actions) => {
      try {
        if (creditNoteId) {
          await Axios.put(appUrl(`/orders/${resourceId}/creditNotes/${creditNoteId}`), data);
        } else {
          await Axios.post(appUrl(`/orders/${resourceId}/creditNotes`), data);
        }
        onSuccess();
      } catch (err) {
        actions.setErrors({failed: (err.response !== undefined) ? `${err.response.data.message}` : err.message});
      }
      actions.setSubmitting(false);
    }}
    render={({
               dirty, isSubmitting, handleSubmit, setErrors, values, ...others
             }) => (
      <form onSubmit={handleSubmit}>
        <section className="modal-card-body">
          <InputSelect
            name="invoiceId"
            label="pages.back.orders.details.creditNotes.invoice"
            options={
              invoices
                ? invoices.map(option => ({
                  value: option.id,
                  label: option.invoiceNumber + option.invoiceSuffix,
                }))
                : []
            }
            {...others}
          />
          <div className="columns">
            <div className="column">
              <MoneyInput
                name="creditNoteAmount.value"
                label="pages.back.orders.details.creditNotes.amount"
                disableAutoComplete
                leftLabel="€"
                moreThan={constants.priceMoreThan}
                {...others}
              />
            </div>
            <div className="column">
              <InputNumber
                name="vat.value"
                label="pages.back.orders.details.creditNotes.vat"
                disableAutoComplete
                leftLabel="%"
                moreThan={constants.priceMoreThan}
                {...others}
              />
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <InputText
                name="creditNoteNumber"
                label="pages.back.orders.details.creditNotes.number"
                values={values}
                {...others}
              />
            </div>
            <div className="column">
              <InputText
                name="creditNoteSuffix"
                label="pages.back.orders.details.creditNotes.suffix"
                values={values}
                {...others}
              />
            </div>
          </div>
          <InputDate
            name="date"
            label="pages.back.orders.details.billing.date"
            values={values}
            handleBlurExtraAction
            {...others}
          />
          <ErrorNotification clearError={() => setErrors({failed: null})} {...others} />
        </section>
        <footer className="modal-card-foot" style={{justifyContent: 'flex-end'}}>
          <SubmitButton dirty={dirty} isSubmitting={isSubmitting}/>
          <button type="button" className="button" onClick={onCancel}>
            <Translated k="general.close"/>
          </button>
        </footer>
      </form>
    )}
  />
);

export default CreditNoteForm;
