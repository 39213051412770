import React from 'react';
import { NavLink } from 'react-router-dom';
import Logo from '../../../../images/canadian-navbar.png';

const Brand = () => (
  <NavLink to="/" className="navbar-item">
    <img src={Logo} alt="Canadian Island" style={{ maxHeight: '3rem' }} />
  </NavLink>
);

export default Brand;
