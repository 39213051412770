import React from 'react';
import Get from 'lodash.get';
import classNames from 'classnames';
import Translated from '../elements/Translated';
import Required from '../elements/Required';

function checkNumber(v){
    const re = /^[0-9\b]+$/;
    return v === '' || re.test(v)
}


// FIXME supporto per decimali
const InputNumber = ({
  name,
  label,
  disableAutoComplete,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  autoFocus,
  rightLabel,
  leftLabel,
  small,
  moreThan,
  required,
}) => (
  <div className="field">
    <div
      className={classNames('label', {
        'is-small': small,
      })}
    >
      {label && (
        <label>
          <Translated k={label} />
          <Required required={required} />
        </label>
      )}
    </div>
    <div className="field-body">
      <div className="field is-expanded">
        <div className="field has-addons">
          {rightLabel && (
            <p className="control">
              <span
                className={classNames('button is-static', {
                  'is-small': small,
                })}
              >
                {rightLabel}
              </span>
            </p>
          )}
          <p className="control is-expanded">
            <input
              name={name}
              className={classNames('input', {
                'is-danger': Get(errors, name) && Get(touched, name),
                'is-small': small,
              })}
              onChange={
                  (event) => {
                      if(checkNumber(event.target.value)){
                          handleChange(event);
                      }
                  }
              }
              onBlur={handleBlur}
              value={Get(values, name)}
              autoFocus={autoFocus}
              type="text"
              autoComplete={disableAutoComplete && 'off'}
            />
          </p>
          {leftLabel && (
            <p className="control">
              <span
                className={classNames('button is-static', {
                  'is-small': small,
                })}
              >
                {leftLabel}
              </span>
            </p>
          )}
        </div>
        {Get(errors, name) && Get(touched, name) && (
          <p className="help is-danger">
            <Translated k={Get(errors, name)} p={moreThan} />
          </p>
        )}
      </div>
    </div>
  </div>
);

export default InputNumber;
