import React, { Component } from 'react';
import Axios from 'axios';
import ErrorNotification from '../../../base/elements/ErrorNotification';
import appUrl from '../../../utils/remote';
import OrderStepsNavigation from '../OrderStepsNavigation';
import Title from '../../../base/elements/Title';
import ButtonModal from '../../../base/components/modal/ButtonModal';
import OrderContactEditForm from './OrderContactEditForm';
import IconTooltip from '../../../base/elements/IconTooltip';
import { UnsafeTranslated } from '../../../base/elements/Translated';


class OrderContactsForm extends Component {
  constructor(props) {
    super(props);

    const { initialValues } = this.props;
    this.state = {
      emergencyContacts: initialValues.items[0].emergencyContacts,
      selected: initialValues.items[0].emergencyContacts.map(c => c.uuid),
      loading: false,
      error: null,
    };
  }

  addContact = (c, callback) => {
    const { emergencyContacts, selected } = this.state;

    this.setState(
      {
        emergencyContacts: [...emergencyContacts, c],
        selected: [...selected, c.uuid],
      },
      callback,
    );
  };

  toggleSelection = ({ target }) => {
    const { selected } = this.state;
    const value = target.attributes.uuid.nodeValue;
    const idx = selected.indexOf(value);

    if (idx > -1) {
      this.setState({
        selected: [...selected.slice(0, idx), ...selected.slice(idx + 1)],
      });
    } else {
      this.setState({ selected: [...selected, value] });
    }
  };

  submitAndNext = () => {
    const { initialValues, onSuccess, requestOptions } = this.props;
    const { emergencyContacts, selected } = this.state;
    const data = {
      ...initialValues,
      items: [
        {
          ...initialValues.items[0],
          emergencyContacts: emergencyContacts.filter(f => selected.indexOf(f.uuid) > -1),
        },
      ],
    };

    this.setState({ loading: true }, async () => {
      try {
        const result = await Axios.put(appUrl(`/fo/orders/${data.id}`), data, requestOptions);
        onSuccess(result.data);
      } catch (err) {
        this.setState({
          loading: false,
          error:
            err.response !== undefined
              ? `${err.response.data.message}`
              : err.message,
        });
      }
    });
  };

  render() {
    const {
      emergencyContacts, selected, error, loading,
    } = this.state;

    const { onCancel } = this.props;

    return (
      <div>
        <OrderStepsNavigation
          renderCenter={() => <Title info size={4} centered label="pages.shared.orders.steps.4" />}
          onClickRight={this.submitAndNext}
          loading={loading}
          submitting={loading}
          showRight
          showLeft
          onClickLeft={onCancel}
        />
        <div className="notification is-info is-light media">
          <i className="media-left fas fa-info-circle" />
          <div className="media-content">
            <UnsafeTranslated k="pages.shared.orders.steps.description.4" />
          </div>
        </div>
        {loading === false && error && (
          <ErrorNotification
            clearError={() => this.setState({ error: null })}
            errors={{ failed: error }}
          />
        )}
        {emergencyContacts.map(c => (
          <div key={c.uuid}>
            <label className="checkbox">
              <input
                type="checkbox"
                uuid={c.uuid}
                checked={selected.indexOf(c.uuid) > -1}
                onChange={this.toggleSelection}
                disabled={loading}
              />
              <span className="partecipant-label is-unselectable">{`${c.name} (${c.phone})`}</span>
            </label>
            {c.notes && <IconTooltip icon="fa-info-circle" tooltipText={c.notes} untranslated />}
          </div>
        ))}
        <ButtonModal
          buttonClassName="is-default form-wizard-buttons is-primary"
          buttonLabel="pages.shared.orders.contacts.add"
          render={({ handleClose }) => (
            <OrderContactEditForm
              onSuccess={(p) => {
                this.addContact(p, handleClose);
              }}
              onCancel={handleClose}
              card
            />
          )}
        />
        <OrderStepsNavigation
          onClickRight={this.submitAndNext}
          loading={loading}
          submitting={loading}
          showRight
          showLeft
          onClickLeft={onCancel}
        />
      </div>
    );
  }
}

export default OrderContactsForm;
