import React from 'react';
import { withRouter } from 'react-router-dom';
import Axios from 'axios';
import Translated from '../../base/elements/Translated';
import SubmitPostButton from '../../base/elements/SubmitPostButton';
import appUrl from '../../utils/remote';
import Title from '../../base/elements/Title';
import WithError from '../../utils/WithError';
import ErrorNotification from '../../base/elements/ErrorNotification';

const EventTypeDetails = ({ data, history, handleClose }) => (
  <WithError
    render={({ error, showError, clearError }) => (
      <div className="box">
        <ErrorNotification errors={{ failed: error }} clearError={clearError} />
        <Title size={3} label={data.name} section untranslated />
        <hr className="compact" />
        <p>{data.description}</p>
        <div className="columns is-centered form-buttons">
          <div className="column is-half">
            <div className="buttons is-centered">
              <button type="button" className="button" onClick={handleClose}>
                <Translated k="general.close" />
              </button>
              <SubmitPostButton
                label="pages.front.events.details.confirm"
                handleSubmit={async () => {
                  try {
                    const result = await Axios.post(appUrl(`/fo/orders?eventTypeId=${data.id}`));
                    history.push(`/orders/${result.data.id}`);
                  } catch (err) {
                    showError(err.message);
                  }
                }}
              />
            </div>
          </div>
        </div>
      </div>
    )}
  />
);

export default withRouter(EventTypeDetails);
