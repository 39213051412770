import React, { Fragment } from 'react';
import UserForm from '../../../forms/UserForm';
import WithRemoteData from '../../../utils/WithRemoteData';
import Translated from '../../../base/elements/Translated';

const UserEdit = ({ handleSuccess, handleClose, resourceId }) => (
  <WithRemoteData
    url={`/users/${resourceId}`}
    render={({ loading, data }) => loading === false
      && data !== null && (
        <Fragment>
          <header className="modal-card-head">
            <p className="modal-card-title">
              <Translated k="pages.back.users.edit" />
            </p>
            <button type="button" className="delete" aria-label="close" onClick={handleClose} />
          </header>
          <UserForm
            onCancel={handleClose}
            onSuccess={() => {
              handleSuccess();
              handleClose();
            }}
            initialValues={{
              ...data,
              role: data.roles[0],
            }}
            resourceId={resourceId}
          />
        </Fragment>
    )
    }
  />
);

export default UserEdit;
