import React from 'react';
import { Formik } from 'formik';
import Axios from 'axios';
import classNames from 'classnames';
import * as Yup from 'yup';
import InputPassword from '../base/form/InputPassword';
import Translated from '../base/elements/Translated';
import appUrl from '../utils/remote';
import constants from '../utils/constants';
import ErrorNotification from '../base/elements/ErrorNotification';

const ChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .min(constants.passwordLength, 'general.validation.password.length')
    .required('general.validation.required')
    .default(''),
  newPassword: Yup.string()
    .min(constants.passwordLength, 'general.validation.password.length')
    .notOneOf([Yup.ref('oldPassword')], 'general.validation.password.matchWithOld')
    .required('general.validation.required')
    .default(''),
  newPasswordRepeat: Yup.string()
    .min(constants.passwordLength, 'general.validation.password.length')
    .oneOf([Yup.ref('newPassword')], 'general.validation.password.match')
    .required('general.validation.required')
    .default(''),
});

const ChangePasswordForm = ({ onSuccess }) => (
  <Formik
    initialValues={ChangePasswordSchema.cast({})}
    validationSchema={ChangePasswordSchema}
    onSubmit={async ({ oldPassword, newPassword }, actions) => {
      try {
        await Axios.put(appUrl('/auth/passwordChange'), { oldPassword, newPassword });
        onSuccess();
      } catch (err) {
        actions.setErrors({
          failed: err.response !== undefined ? `${err.response.data.message}` : err.message,
        });
      }
      actions.setSubmitting(false);
    }}
    render={({
      dirty, isSubmitting, handleSubmit, ...others
    }) => (
      <form onSubmit={handleSubmit}>
        <ErrorNotification {...others} />
        <InputPassword name="oldPassword" label="model.user.password" {...others} />
        <InputPassword name="newPassword" label="pages.auth.newPassword" {...others} />
        <InputPassword
          name="newPasswordRepeat"
          label="pages.auth.passwordRepeat"
          {...others}
        />
        <button
          type="submit"
          className={classNames('button is-primary is-fullwidth', {
            'is-loading': isSubmitting,
          })}
          disabled={!dirty || isSubmitting}
        >
          <Translated k="pages.auth.login" />
        </button>
      </form>
    )}
  />
);

export default ChangePasswordForm;
