import React, { Fragment } from 'react';
import Translated from '../../../base/elements/Translated';
import WithRemoteData from '../../../utils/WithRemoteData';

const PartecipantEmailExport = ({ handleClose, filters }) => (
  <WithRemoteData
    url="/eventPackagePartecipations/export/emails"
    filters={filters}
    render={({ loading, data }) => (
      <Fragment>
        <header className="modal-card-head">
          <p className="modal-card-title">
            <Translated k="pages.back.partecipants.exportEmails.title" />
          </p>
          <button type="button" className="delete" aria-label="close" onClick={handleClose} />
        </header>
        <section className="modal-card-body">
          <div>
            {loading === false && (
              <div className="field">
                <div className="control">
                  <textarea
                    className="textarea"
                    value={data.map(e => e.value).join('\n')}
                    onClick={(e) => {
                      e.target.select();
                      document.execCommand('copy');
                    }}
                    rows="20"
                    readOnly
                  />
                </div>
                <p className="help is-info">
                  <Translated k="pages.back.partecipants.exportEmails.message" />
                </p>
              </div>
            )}
          </div>
        </section>
        <footer className="modal-card-foot" style={{ justifyContent: 'flex-end' }}>
          <button type="button" className="button" onClick={handleClose}>
            <Translated k="general.close" />
          </button>
        </footer>
      </Fragment>

    )}
  />
);

export default PartecipantEmailExport;
