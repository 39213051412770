import {Component} from 'react';
import Axios from 'axios';
import appUrl from './remote';
import buildUrl from './filter';

class WithRemoteData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: null,
      loading: true,
      error: null,
    };
  }

  componentDidMount() {
    this.load();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevProps.url !== this.props.url || prevProps.filters !== this.props.filters){
      this.load();
    }
  }


  load = async () => {
    const {url, filters, isDisabled} = this.props;



    try {
      let data;
      if(isDisabled === true){
        data = {elements: [], count: 0 };
      }else {
          data = (await Axios.get(buildUrl(appUrl(url), filters))).data;
      }
      this.setState({
        data,
        loading: false,
      });
    } catch (err) {
      this.setState({
        error: (err.response !== undefined) ? `${err.response.data.message}` : err.message,
        loading: false,
      });
    }
  };

  render() {
    const {render} = this.props;
    const {data, loading, error} = this.state;
    return render({
      data,
      loading,
      error,
      refresh: this.load,
    });
  }
}

export default WithRemoteData;
