import React, {Component} from 'react';
import Axios from 'axios';
import appUrl from './utils/remote';
import translate from './utils/translate';
import {MandatoryUserSchema} from './forms/CustomerForm';
import {withRouter} from "react-router-dom";
import {AppContext} from "./App";

class AppProvider extends Component {
    state = {
        loggedUser: null,
        messages: null,
        generalError: null,
        loading: true,
    };

    componentDidMount() {
        this.loadUser();
        this.keepAlive();
    }

    isLogged = () => {
        const { loggedUser } = this.state;
        return loggedUser != null;
    };

    getLocale = () => {
        const { loggedUser } = this.state;
        return loggedUser != null ? loggedUser.locale : null;
    };

    isEnabled = () => {
        const { loggedUser } = this.state;
        return loggedUser.enabled;
    };

    isChangePasswordNeeded = () => {
        const { loggedUser } = this.state;
        return loggedUser.changePasswordNeeded;
    };

    isUserDetailsNeeded = () => {
        const { loggedUser } = this.state;
        return !this.isBackOffice() && !MandatoryUserSchema.isValidSync(loggedUser);
    };

    isBackOffice = () => {
        const { loggedUser } = this.state;
        return loggedUser != null && loggedUser.roles.indexOf('ADMIN') > -1;
    };

    getUsername = () => {
        const { loggedUser } = this.state;
        return loggedUser != null && loggedUser.username;
    };

    getUserId = () => {
        const { loggedUser } = this.state;
        return loggedUser != null && loggedUser.id;
    };

    translate = (key, ...params) => {
        const { messages } = this.state;
        return translate(messages, key, ...params);
    };

    loadUser = () => {
        this.setState({ loading: true }, async () => {
            let logged = false;
            try {
                const userResponse = await Axios.get(appUrl('/auth/loggedUser'));
                this.setState({ loggedUser: userResponse.data });
                logged = true;
            } catch (err) {
                this.setState({ loading: false, generalError: (err.response !== undefined) ? `${err.response.data.message}` : err.message });
            } finally {
                let locale = '';
                if(!logged) {
                    locale = new URLSearchParams(this.props.location.search).get('locale') || 'it-IT';
                }
                const messagesResponse = await Axios.get(appUrl(`/i18n/messages/${locale}`));
                this.setState({messages: messagesResponse.data});
            }
        });
    };

    loadMessages = async (locale) => {
        try {
            const { data } = await Axios.get(appUrl(`/i18n/messages/${locale}`));
            this.setState({ messages: data, loading: false });
        } catch (err) {
            this.setState({ loading: false, generalError: (err.response !== undefined) ? `${err.response.data.message}` : err.message });
        }
    };

    keepAlive = async () => {
        await Axios.post(appUrl('/auth/keepalive'));
        setTimeout(this.keepAlive, 30 * 1000);
    }

    render() {
        const { children } = this.props;
        const { loading, generalError } = this.state;

        return (
            <AppContext.Provider
                value={{
                    getUsername: this.getUsername,
                    getUserId: this.getUserId,
                    isBackOffice: this.isBackOffice,
                    isLogged: this.isLogged,
                    isEnabled: this.isEnabled,
                    isChangePasswordNeeded: this.isChangePasswordNeeded,
                    isUserDetailsNeeded: this.isUserDetailsNeeded,
                    loadUser: this.loadUser,
                    loadMessages: this.loadMessages,
                    translate: this.translate,
                    getLocale: this.getLocale,
                    loading,
                    generalError,
                }}
            >
                {children}
            </AppContext.Provider>
        );
    }
}

export default withRouter(AppProvider);
