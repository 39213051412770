import React, { Component } from 'react';
import queryString from 'query-string';
import { Link } from 'react-router-dom';
import ChangePasswordForm from '../../forms/ChangePasswordForm';
import Background from '../../../images/login-background.jpg';
import Logo from '../../../images/canadian-logo.png';
import Title from '../../base/elements/Title';
import Translated from '../../base/elements/Translated';

class ChangePasswordPage extends Component {
  componentDidMount() {
    const { onInit, location } = this.props;
    const { locale } = queryString.parse(location.search);

    onInit(locale || 'it-IT');
  }

  onSuccess = () => {
    window.location = '/';
  }

  render() {
    return (
      <div
        className="hero is-fullheight authentication-page"
        style={{ backgroundImage: `url(${Background})` }}
      >
        <div className="hero-body">
          <div className="container">
            <div className="column is-4 is-offset-4">
              <div className="has-background-white login-card">
                <figure className="avatar has-text-centered login-logo">
                  <img src={Logo} alt="Canadian Island" />
                </figure>
                <Title size={4} section label="pages.auth.changePassword" />
                <ChangePasswordForm onSuccess={this.onSuccess} />
                <Link to="/logout" className="button is-default is-fullwidth form-buttons">
                  <Translated k="pages.auth.backToLogin" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChangePasswordPage;
