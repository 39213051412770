import React, { Component } from 'react';
import Axios from 'axios';
import download from 'downloadjs';
import classNames from 'classnames';
import appUrl from '../../utils/remote';
import { AppContext } from '../../App';

class DownloadButton extends Component {
  state = {
    downloading: false,
  };

  beginDownload = () => {
    this.setState({ downloading: true }, this.downloadFile);
  };

  downloadFile = async () => {
    const { filePath, fileType } = this.props;
    const { notifyError } = this.props;

    try {
      const response = await Axios.get(appUrl(filePath), {
        responseType: 'blob',
      });
      const { data, headers } = response;

      download(data, headers['content-disposition'], `application/${fileType}`);
      this.setState({ downloading: false });
    } catch (err) {
      notifyError(err.message);
    }
  };

  render() {
    const { tooltipLabel, iconName } = this.props;
    const { downloading } = this.state;

    return (
      <AppContext.Consumer>
        {({ translate }) => (
          <button
            type="button"
            className={classNames('button tooltip', {
              'is-loading': downloading,
            })}
            data-tooltip={translate(tooltipLabel)}
            onClick={this.beginDownload}
            disabled={downloading}
          >
            <span className="icon">
              <i className={`fas ${iconName}`} />
            </span>
          </button>
        )}
      </AppContext.Consumer>
    );
  }
}

export default DownloadButton;
