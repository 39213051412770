import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import WithRemoteData from '../../../utils/WithRemoteData';
import InputSelect from '../../../base/form/InputSelect';
import RemoteEntityInputSelect from '../../../base/form/RemoteEntityInputSelect';
import SubmitButton from '../../../base/form/SubmitButton';

const OrderAuthorAndTypeSchema = Yup.object().shape({
  userId: Yup.string().required('general.validation.required'),
  eventTypeId: Yup.string().required('general.validation.required'),
});

const OrderAuthorForm = ({ onSubmit }) => (
  <Formik
    initialValues={{ username: '', eventTypeId: null }}
    onSubmit={onSubmit}
    validationSchema={OrderAuthorAndTypeSchema}
    render={({ handleSubmit, ...others }) => (
      <form onSubmit={handleSubmit}>
        <WithRemoteData
          url="/users?roles=CUSTOMER&roles=WEB"
          render={({ loading, data }) => (
            <InputSelect
              loading={loading}
              options={
                loading === false && data !== null
                  ? data.elements.map(e => ({
                    value: e.id,
                    label: `${e.person.description || ''}`.trim(),
                  }))
                  : []
              }
              name="userId"
              label="model.user.entityName"
              {...others}
            />
          )}
        />
        <RemoteEntityInputSelect
          name="eventTypeId"
          label="model.eventType.entityName"
          url="/eventTypes"
          {...others}
        />
        <div className="field is-grouped is-grouped-right form-buttons">
          <div className="control">
            <SubmitButton
              label="general.filters.next"
              icon="fa-arrow-right"
              primary={false}
              {...others}
            />
          </div>
        </div>
      </form>
    )}
  />
);

export default OrderAuthorForm;
