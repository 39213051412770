import React, {Fragment} from 'react';
import Axios from "axios";
import appUrl from "../../../../../utils/remote";
import ErrorNotification from "../../../../../base/elements/ErrorNotification";
import Title from "../../../../../base/elements/Title";
import Translated from "../../../../../base/elements/Translated";
import WithError from "../../../../../utils/WithError";

const removeCreditNote = async (orderId, creditNoteId, onSuccess, onCancel, showError) => {
    try {
        await Axios.delete(appUrl(`/orders/${orderId}/creditNotes/${creditNoteId}`));
        onSuccess();
        onCancel();
    } catch (err) {
        showError(err.message);
    }
};

const CreditNoteDelete = ({orderId, creditNoteId, onCancel, onSuccess}) =>
    (
        <WithError
            render={({ error, showError, clearError }) => (
                <Fragment>
                    <div className="message is-warning">
                        <div className="message-body">
                            <ErrorNotification errors={{ failed: error }} clearError={clearError} />
                            <div className="level">
                                <Title size={3} label="pages.back.orders.details.creditNotes.delete.title" />
                            </div>
                            <p style={{ marginBottom: '1.5rem' }}>
                                <Translated k="pages.back.orders.details.creditNotes.delete.message" />
                            </p>
                            <div className="field is-grouped is-grouped-right">
                                <div className="control">
                                    <button
                                        type="button"
                                        className="button is-primary"
                                        onClick={() => removeCreditNote(orderId, creditNoteId, onSuccess, onCancel, showError)}
                                    >
                                        <Translated k="general.confirm" />
                                    </button>
                                </div>
                                <div className="control">
                                    <button type="button" className="button" onClick={onCancel}>
                                        <Translated k="general.close" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            )}
        />
    );

export default CreditNoteDelete;
