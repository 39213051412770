import Axios from 'axios';
import { Formik } from 'formik';
import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import ErrorNotification from '../base/elements/ErrorNotification';
import Translated from '../base/elements/Translated';
import InputSelect from '../base/form/InputSelect';
import InputText from '../base/form/InputText';
import SubmitButton from '../base/form/SubmitButton';
import appUrl from '../utils/remote';
import WithRemoteData from '../utils/WithRemoteData';

const UserSchema = Yup.object().shape({
  username: Yup.string()
    .required('general.validation.required')
    .default(''),
  person: Yup.object().shape({
    email: Yup.string()
      .email('general.validation.email')
      .required('general.validation.required')
      .default(''),
    firstName: Yup.string().default(''),
    lastName: Yup.string().default(''),
  }),
  locale: Yup.string()
    .required('general.validation.required')
    .default(''),
});

const UserForm = ({
  onSuccess, onCancel, resourceId, initialValues = {},
}) => (
  <Formik
    initialValues={UserSchema.cast(initialValues)}
    validationSchema={UserSchema}
    onSubmit={async ({
      username, role, person, locale,
    }, actions) => {
      try {
        if (resourceId) {
          await Axios.put(appUrl(`/users/${resourceId}`), {
            id: resourceId,
            username,
            locale,
            roles: [role],
            person,
          });
        } else {
          await Axios.post(appUrl('/users/registerAdmin'), {
            username, roles: [role], locale, person,
          });
        }
        onSuccess();
      } catch (err) {
        actions.setErrors({ failed: (err.response !== undefined) ? `${err.response.data.message}` : err.message });
      }
      actions.setSubmitting(false);
    }}
    render={({
      dirty, isSubmitting, handleSubmit, setErrors, ...others
    }) => (
      <form onSubmit={handleSubmit}>
        <section className="modal-card-body">
          <ErrorNotification clearError={() => setErrors({ failed: null })} {...others} />
          <p className="title is-4 no-padding">
            <Translated k="pages.back.users.details.user" />
          </p>
          <div className="columns">
            <div className="column">
              <InputText
                name="username"
                label="model.user.username"
                disableAutoComplete
                {...others}
              />
            </div>
          </div>

          <Fragment>
            <hr />
            <p className="title is-4 no-padding">
              <Translated k="pages.back.users.details.person" />
            </p>
            <div className="columns">
              <div className="column">
                <InputText
                  name="person.firstName"
                  label="model.person.firstName"
                  disableAutoComplete
                  {...others}
                />
              </div>
              <div className="column">
                <InputText
                  name="person.lastName"
                  label="model.person.lastName"
                  disableAutoComplete
                  {...others}
                />
              </div>
              {others.values.personType === 'JURIDICAL' && (
              <div className="column">
                <InputText
                  name="person.registeredName"
                  label="model.person.registeredName"
                  disableAutoComplete
                  {...others}
                />
              </div>
              )}
            </div>
            <div className="columns">
              <div className="column">
                <InputText
                  name="person.phoneNumber"
                  label="model.person.phoneNumber"
                  disableAutoComplete
                  {...others}
                />
              </div>
              <div className="column">
                <InputText
                  name="person.phoneNumber2"
                  label="model.person.phoneNumber2"
                  disableAutoComplete
                  {...others}
                />
              </div>
              <div className="column">
                <InputText
                  name="person.phoneNumber3"
                  label="model.person.phoneNumber3"
                  disableAutoComplete
                  {...others}
                />
              </div>
              <div className="column">
                <InputText
                  name="person.phoneNumber4"
                  label="model.person.phoneNumber4"
                  disableAutoComplete
                  {...others}
                />
              </div>
              <div className="column">
                <InputText
                  name="person.email"
                  label="model.person.email"
                  disableAutoComplete
                  {...others}
                />
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <WithRemoteData
                  url="/i18n/locales"
                  render={({ loading, data }) => (
                    <InputSelect
                      loading={loading}
                      name="locale"
                      label="model.user.locale"
                      options={
                        loading === false && data
                          ? data.map(option => ({
                            value: option.code,
                            label: option.name,
                          }))
                          : []
                      }
                      {...others}
                    />
                  )}
                />
              </div>
            </div>

          </Fragment>

          <hr />
        </section>
        <footer className="modal-card-foot" style={{ justifyContent: 'flex-end' }}>
          <SubmitButton dirty={dirty} isSubmitting={isSubmitting} />
          <button type="button" className="button" onClick={onCancel}>
            <Translated k="general.close" />
          </button>
        </footer>
      </form>
    )}
  />
);

export default withRouter(UserForm);
