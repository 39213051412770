import React, { Fragment } from 'react';
import Axios from 'axios';
import ColumnOutput from '../../../../base/elements/ColumnOutput';
import ContactEdit from './ContactEdit';
import IconModal from '../../../../base/components/modal/IconModal';
import ContactDelete from './ContactDelete';
import appUrl from '../../../../utils/remote';
import WithError from '../../../../utils/WithError';
import ErrorNotification from '../../../../base/elements/ErrorNotification';

const removeContact = async (contact, order, showError, handleSuccess) => {
  try {
    const resourceId = order.id;
    const idx = order.items[0].emergencyContacts.map(sp => sp.uuid).indexOf(contact.uuid);

    const modifiedOrder = {
      ...order,
      items: [
        {
          ...order.items[0],
          emergencyContacts: [
            ...order.items[0].emergencyContacts.slice(0, idx),
            ...order.items[0].emergencyContacts.slice(idx + 1),
          ],
        },
        ...order.items.slice(1),
      ],
    };

    await Axios.put(appUrl(`/orders/${resourceId}`), modifiedOrder);
    handleSuccess();
  } catch (err) {
    showError(err.message);
  }
};

const ContactsDetails = ({
  contacts, handleSuccess, order, readOnly,
}) => (
  <WithError
    render={({ error, showError, clearError }) => (
      <Fragment>
        <ErrorNotification errors={{ failed: error }} clearError={clearError} />
        <div className="box">
          {contacts.map(c => (
            <div className="columns" key={c.uuid}>
              <ColumnOutput label="model.contact.name" render={() => c.name} />
              <ColumnOutput label="model.contact.phone" render={() => c.phone} />
              <ColumnOutput label="model.contact.notes" render={() => c.notes} />
              {!readOnly
              && (
              <div className="buttons">
                <IconModal
                  icon="fa-pen"
                  tooltipText="general.edit"
                  render={({ handleClose }) => (
                    <ContactEdit
                      handleSuccess={handleSuccess}
                      handleClose={handleClose}
                      order={order}
                      contact={c}
                    />
                  )}
                />
                <IconModal
                  icon="fa-trash-alt"
                  tooltipText="general.delete"
                  render={({ handleClose }) => (
                    <ContactDelete
                      handleConfirm={() => {
                        removeContact(c, order, showError, handleSuccess);
                      }}
                      handleClose={handleClose}
                    />
                  )}
                />
              </div>
              )}
            </div>
          ))}
        </div>
      </Fragment>
    )}
  />
);

export default ContactsDetails;
