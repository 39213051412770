import React from 'react';
import Title from '../base/elements/Title';

const NotAllowed = () => (
  <section className="hero is-info is-fullheight">
    <div className="hero-body">
      <div className="container">
        <div className="title" style={{ fontSize: '5rem' }}>
          403
        </div>
        <Title size={3} label="pages.notallowed.message" />
      </div>
    </div>
  </section>
);

export default NotAllowed;
