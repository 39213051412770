import React from 'react';
import classNames from 'classnames';
import Translated from './Translated';

const Title = ({
  label, size, centered, section, untranslated, info, labelParams
}) => (
  <div
    className={classNames(`is-size-${size}`, {
      'has-text-centered': centered,
      'section-title': section,
      'has-text-info': info,
    })}
  >
    {untranslated === true ? <span>{label}</span> : <Translated k={label} p={labelParams} />}
  </div>
);

export default Title;
