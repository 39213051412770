import React from 'react';
import ButtonModal from '../../../base/components/modal/ButtonModal';
import IconModal from '../../../base/components/modal/IconModal';
import Pagination from '../../../base/components/pagination/Pagination';
import TimestampOutput from '../../../base/elements/TimestampOutput';
import MoneyOutput from '../../../base/elements/MoneyOutput';
import OrderableColumn from '../../../base/elements/OrderableColumn';
import Title from '../../../base/elements/Title';
import Translated from '../../../base/elements/Translated';
import InputDate from '../../../base/form/InputDate';
import InputSelect from '../../../base/form/InputSelect';
import InputText from '../../../base/form/InputText';
import RemoteEntityInputSelect from '../../../base/form/RemoteEntityInputSelect';
import RemoteInputSelect from '../../../base/form/RemoteInputSelect';
import FilterForm from '../../../forms/FilterForm';
import CrudList from '../../../utils/CrudList';
import OrderAcknowledgment from './OrderAcknowledgment';
import OrderAdd from './OrderAdd';
import OrderDelete from './OrderDelete';
import OrderDetails from './OrderDetails';
import OrderResume from './OrderResume';

const OrderList = () => (
  <CrudList
    initialSorting={{ sort: 'CREATION_DATE', asc: false }}
    initialFilter={{
      fullSearch: '',
      typeId: '',
      paymentMethod: '',
      status: '',
      maxResults: 10,
    }}
    restUrl="/orders"
    render={({
      loading,
      data,
      error,
      params,
      filters,
      initialFilter,
      handleNavigation,
      updateFilters,
      refreshResults,
      changeOrdering,
    }) => (
      <div className="section main-page">
        <div className="level">
          <div className="level-left">
            <Title size={3} label="general.navbar.orders" />
          </div>
          <div className="level-right">
            <ButtonModal
              buttonClassName="is-primary"
              buttonLabel="pages.back.orders.add"
              render={({ handleClose }) => (
                <OrderAdd
                  handleClose={handleClose}
                  refreshResults={refreshResults}
                />
              )}
              large
              card
            />
          </div>
        </div>
        <div className="section-title">
          <FilterForm
            onSubmit={updateFilters}
            initialValues={initialFilter}
            render={({ ...props }) => (
              <div className="columns">
                <div className="column is-one-fifths">
                  <InputText
                    name="fullSearch"
                    label="general.filters.fulltextSearch"
                    {...props}
                    disableAutoComplete
                  />
                </div>
                <div className="column">
                  <InputDate
                    name="startDate"
                    label="general.filters.startDate"
                    {...props}
                  />
                </div>
                <div className="column">
                  <InputDate
                    name="endDate"
                    label="general.filters.endDate"
                    {...props}
                  />
                </div>
                <div className="column">
                  <RemoteEntityInputSelect
                    name="eventTypeId"
                    label="model.eventType.entityName"
                    url="/eventTypes"
                    isClearable
                    {...props}
                  />
                </div>
                <div className="column">
                  <RemoteInputSelect
                    name="orderStatus"
                    label="model.order.status"
                    url="/orders/status"
                    isClearable
                    {...props}
                  />
                </div>
                <div className="column">
                  <RemoteInputSelect
                    name="paymentStatus"
                    label="model.order.paymentStatus.entityName"
                    url="/orders/paymentStatuses"
                    isClearable
                    {...props}
                  />
                </div>
                <div className="column">
                  <InputText
                    name="invoiceNumber"
                    label="model.invoice.invoiceNumber"
                    {...props}
                    disableAutoComplete
                  />
                </div>
                <div className="column">
                  <InputSelect
                    name="maxResults"
                    label="general.filters.numOfResults"
                    options={[
                      { value: 10, label: 10 },
                      { value: 20, label: 20 },
                      { value: 50, label: 50 },
                    ]}
                    isSearchable={false}
                    {...props}
                  />
                </div>
              </div>
            )}
          />
        </div>
        {loading === false && error && (
          <div className="notification is-danger">{error}</div>
        )}
        <div className="table-container">
          {loading === true && <div className="loading" />}
          <table className="table is-hoverable is-bordered is-fullwidth">
            <thead className="has-background-white-ter">
              <tr>
                <OrderableColumn
                  name="LASTNAME"
                  handleClick={changeOrdering}
                  {...params}
                >
                  <Translated k="pages.back.orders.details.description.user" />
                </OrderableColumn>
                <OrderableColumn
                  name="REGISTERED_NAME"
                  handleClick={changeOrdering}
                  {...params}
                >
                  <Translated k="model.person.registeredName" />
                </OrderableColumn>
                <OrderableColumn
                  name="STATUS"
                  handleClick={changeOrdering}
                  {...params}
                >
                  <Translated k="model.order.status" />
                </OrderableColumn>
                <OrderableColumn
                  name="CREATION_DATE"
                  handleClick={changeOrdering}
                  {...params}
                >
                  <Translated k="model.order.creationDate" />
                </OrderableColumn>
                <th>
                  <Translated k="model.order.partecipants" />
                </th>
                <th>
                  <Translated k="model.eventType.entityName" />
                </th>
                <OrderableColumn
                  name="TOTAL_AMOUNT"
                  handleClick={changeOrdering}
                  {...params}
                >
                  <Translated k="model.order.totalAmount" />
                </OrderableColumn>
                <OrderableColumn
                  name="PAYED_AMOUNT"
                  handleClick={changeOrdering}
                  {...params}
                >
                  <Translated k="model.order.payedAmount" />
                </OrderableColumn>
                <OrderableColumn
                  name="TO_PAY"
                  handleClick={changeOrdering}
                  {...params}
                >
                  <Translated k="model.order.toPay" />
                </OrderableColumn>
                <OrderableColumn
                  name="PAYMENT_STATUS"
                  handleClick={changeOrdering}
                  {...params}
                >
                  <Translated k="model.order.paymentStatus.entityName" />
                </OrderableColumn>
                <th>
                  <Translated k="general.actions" />
                </th>
              </tr>
            </thead>
            <tbody>
              {data
                && data.elements.map(e => (
                  <tr
                    key={e.id}
                    className={!e.acknowledgment ? 'has-text-weight-bold' : ''}
                  >
                    <td>
                      {e.author.person.lastName}
                      {' '}
                      {e.author.person.firstName}
                    </td>
                    <td>{e.author.person.registeredName}</td>
                    <td>
                      <Translated
                        k={`model.order.orderStatus.${e.orderStatus}`}
                      />
                    </td>
                    <td>
                      <TimestampOutput value={e.creationDate} />
                    </td>
                    <td>{e.partecipants}</td>
                    {
                      <td>
                        {e.items
                          .filter(i => i.type === 'purchase')
                          .map(i => i.eventType.name)
                          .join(', ')}
                      </td>
                    }
                    <td>
                      <MoneyOutput money={e.totalAmount} />
                    </td>
                    <td>
                      <MoneyOutput money={e.totalPaid} />
                    </td>
                    <td>
                      <MoneyOutput money={e.toPay} />
                    </td>
                    <td>
                      {e.orderStatus === 'COMPLETED' && (
                        <Translated
                          k={`model.order.paymentStatus.${e.paymentStatus}`}
                        />
                      )}
                    </td>
                    <td>
                      <div className="buttons">
                        {e.orderStatus === 'COMPLETED' && (
                          <IconModal
                            icon="fa-search"
                            tooltipText="general.view"
                            render={({ handleClose }) => (
                              <OrderDetails
                                orderId={e.id}
                                refreshResults={refreshResults}
                                handleClose={handleClose}
                              />
                            )}
                            card
                          />
                        )}
                        {e.orderStatus === 'IN_PROGRESS' && (
                          <IconModal
                            icon="fa-play"
                            tooltipText="pages.front.order.resume"
                            render={({ handleClose }) => (
                              <OrderResume
                                orderId={e.id}
                                selectedUser={e.author.id}
                                handleClose={handleClose}
                                refreshResults={refreshResults}
                              />
                            )}
                            large
                            card
                          />
                        )}
                        <IconModal
                          icon="fa-trash-alt"
                          tooltipText="general.delete"
                          render={props => (
                            <OrderDelete
                              onCancel={props.handleClose}
                              initialValues={e}
                              orderId={e.id}
                              onSuccess={refreshResults}
                            />
                          )}
                          card
                        />
                        <IconModal
                          icon={
                            e.acknowledgment
                              ? 'fa-times-circle'
                              : 'fa-check-circle'
                          }
                          tooltipText={
                            e.acknowledgment
                              ? 'general.acknowledgmentRemove'
                              : 'general.acknowledgmentAdd'
                          }
                          render={props => (
                            <OrderAcknowledgment
                              onCancel={props.handleClose}
                              initialValues={e}
                              orderId={e.id}
                              onSuccess={refreshResults}
                            />
                          )}
                          card
                        />
                      </div>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
        <Pagination
          loading={loading}
          data={data}
          startPosition={params.startPosition}
          maxResults={filters.maxResults}
          handleNavigation={handleNavigation}
        />
      </div>
    )}
  />
);

export default OrderList;
