import React from 'react';
import Icon from './Icon';

const OrderableColumn = ({
  children, style, handleClick, name, sort, asc,
}) => (
  <th style={style} className="orderable" onClick={() => handleClick(name)}>
    {children}
    {sort === name ? (
      <Icon name={`fa-caret-${asc === true ? 'up' : 'down'}`} />
    ) : (
      <Icon name="fa-caret-up has-text-grey-lighter" />
    )}
  </th>
);

export default OrderableColumn;
