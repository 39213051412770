/* eslint react/no-danger: "off" */
import React, { Fragment } from 'react';
import { AppContext } from '../../App';

export const UnsafeTranslated = ({ k, p }) => (
  <Fragment>
    <AppContext.Consumer>
      {({ translate }) => <span dangerouslySetInnerHTML={{ __html: translate(k, p) }} />}
    </AppContext.Consumer>
  </Fragment>
);

const Translated = ({ k, p }) => (
  <Fragment>
    <AppContext.Consumer>{({ translate }) => translate(k, p)}</AppContext.Consumer>
  </Fragment>
);

export default Translated;
