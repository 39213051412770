import React from 'react';
import FilterForm from '../../../forms/FilterForm';
import InputText from '../../../base/form/InputText';
import InputSelect from '../../../base/form/InputSelect';
import ButtonModal from '../../../base/components/modal/ButtonModal';
import CustomerEdit from './CustomerEdit';
import IconModal from '../../../base/components/modal/IconModal';
import CustomerDelete from './CustomerDelete';
import Translated from '../../../base/elements/Translated';
import CustomerDetails from './CustomerDetails';
import CustomerAdd from './CustomerAdd';
import Pagination from '../../../base/components/pagination/Pagination';
import OrderableColumn from '../../../base/elements/OrderableColumn';
import CrudList from '../../../utils/CrudList';
import Title from '../../../base/elements/Title';

const CustomerList = () => (
  <CrudList
    initialSorting={{ sort: 'USERNAME', asc: true }}
    initialFilter={{ fullSearch: '', roles: ['CUSTOMER', 'WEB'], maxResults: 10 }}
    restUrl="/users"
    render={({
      loading,
      data,
      error,
      params,
      filters,
      initialFilter,
      handleNavigation,
      changeOrdering,
      updateFilters,
      refreshResults,
      removeEntity,
    }) => (
      <div className="section main-page">
        <div className="container">
          <div className="level">
            <div className="level-left">
              <Title size={3} label="model.user.customers" />
            </div>
            <div className="level-right">
              <ButtonModal
                buttonClassName="is-primary"
                buttonLabel="pages.back.customers.add"
                render={({ handleClose }) => (
                  <CustomerAdd handleClose={handleClose} handleSuccess={refreshResults} />
                )}
              />
            </div>
          </div>
          <div className="section-title">
            <FilterForm
              onSubmit={updateFilters}
              initialValues={initialFilter}
              render={({ ...props }) => (
                <div className="columns">
                  <div className="column is-two-thirds">
                    <InputText
                      name="fullSearch"
                      label="general.filters.fulltextSearch"
                      {...props}
                      disableAutoComplete
                    />
                  </div>
                  <div className="column">
                    <InputSelect
                      name="maxResults"
                      label="general.filters.numOfResults"
                      options={[
                        { value: 10, label: 10 },
                        { value: 20, label: 20 },
                        { value: 50, label: 50 },
                      ]}
                      isSearchable={false}
                      {...props}
                    />
                  </div>
                </div>
              )}
            />
          </div>
          {loading === false && error && <div className="notification is-danger">{error}</div>}
          <div className="table-container">
            {loading === true && <div className="loading" />}
            <table className="table is-hoverable is-bordered is-fullwidth">
              <thead className="has-background-white-ter">
                <tr>
                  <OrderableColumn name="LASTNAME" handleClick={changeOrdering} {...params}>
                    <Translated k="pages.back.orders.details.description.user" />
                  </OrderableColumn>
                  <OrderableColumn name="REGISTERED_NAME" handleClick={changeOrdering} {...params}>
                    <Translated k="model.person.registeredName" />
                  </OrderableColumn>
                  <OrderableColumn name="EMAIL" handleClick={changeOrdering} {...params}>
                    <Translated k="model.person.email" />
                  </OrderableColumn>
                  <OrderableColumn name="SSN_CODE" handleClick={changeOrdering} {...params}>
                    <Translated k="model.person.ssnCode" />
                  </OrderableColumn>
                  <OrderableColumn name="VAT_ID" handleClick={changeOrdering} {...params}>
                    <Translated k="model.person.vatId" />
                  </OrderableColumn>
                  <OrderableColumn name="USERNAME" handleClick={changeOrdering} {...params}>
                    <Translated k="model.user.username" />
                  </OrderableColumn>
                  <OrderableColumn name="NEWSLETTER_CONSENT" handleClick={changeOrdering} {...params}>
                    <Translated k="general.privacy.newsletterConsentShort" />
                  </OrderableColumn>
                  <th>
                    <Translated k="model.user.roles.entityName" />
                  </th>
                  <th>
                    <Translated k="general.actions" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {data
                  && data.elements.map(u => (
                    <tr key={u.id}>
                      <td>
                        {u.person.lastName}
                        {' '}
                        {u.person.firstName}
                      </td>
                      <td>
                        {u.person.registeredName}
                      </td>
                      <td>{u.person.email}</td>
                      <td>{u.person.ssnCode}</td>
                      <td>{u.person.vatId}</td>
                      <td>{u.username}</td>
                      <td>
                        {u.newsletterConsent === true && <Translated k="general.privacy.agree" /> }
                        {u.newsletterConsent === false && <Translated k="general.privacy.doNotAgree" /> }
                      </td>
                      <td>{u.roles.join(', ')}</td>
                      <td>
                        <div className="buttons">
                          <IconModal
                            icon="fa-search"
                            tooltipText="general.view"
                            render={({ handleClose }) => (
                              <CustomerDetails
                                handleClose={handleClose}
                                handleSuccess={refreshResults}
                                resourceId={u.id}
                              />
                            )}
                            card
                          />
                          <IconModal
                            icon="fa-pen"
                            tooltipText="general.edit"
                            render={({ handleClose }) => (
                              <CustomerEdit
                                handleClose={handleClose}
                                handleSuccess={refreshResults}
                                resourceId={u.id}
                              />
                            )}

                          />
                          <IconModal
                            icon="fa-trash-alt"
                            tooltipText="general.delete"
                            render={({ handleClose }) => (
                              <CustomerDelete
                                username={u.username}
                                handleConfirm={() => {
                                  removeEntity(u.id);
                                  handleClose();
                                }}
                                handleClose={handleClose}
                              />
                            )}
                            card
                          />
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <Pagination
            loading={loading}
            data={data}
            startPosition={params.startPosition}
            maxResults={filters.maxResults}
            handleNavigation={handleNavigation}
          />
        </div>
      </div>
    )}
  />
);

export default CustomerList;
