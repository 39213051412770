import React from 'react';
import ColumnOutput from '../../../../base/elements/ColumnOutput';
import MoneyOutput from '../../../../base/elements/MoneyOutput';

const InvoiceTotal = ({ invoiceResidue, invoiceTotal }) => (
  <div className="columns is-vcentered">
    <div className="column is-vcentered">
      <ColumnOutput
        label="pages.back.orders.details.billing.row.invoiceResidue"
        render={() => <MoneyOutput money={invoiceResidue} />}
      />
    </div>
    <div className="column is-vcentered">
      <ColumnOutput
        label="pages.back.orders.details.billing.row.invoiceTotal"
        render={() => <MoneyOutput money={invoiceTotal} />}
      />
    </div>
  </div>
);

export default InvoiceTotal;
