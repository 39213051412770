import React, { Fragment } from 'react';
import Translated from '../../../../base/elements/Translated';
import DateOutput from '../../../../base/elements/DateOutput';
import IconModal from '../../../../base/components/modal/IconModal';
import PaymentDelete from './PaymentDelete';
import PaymentAdd from './PaymentAdd';
import ButtonModal from '../../../../base/components/modal/ButtonModal';

const PaymentOverviewDetails = ({ data, handleSuccess, readOnly }) => (
  <Fragment>
    <table className="table is-hoverable is-bordered is-fullwidth">
      <thead className="has-background-white-ter">
        <tr>
          <th>
            <Translated k="pages.back.orders.details.payments.amount" />
          </th>
          <th>
            <Translated k="pages.back.orders.details.payments.date" />
          </th>
          <th>
            <Translated k="pages.back.orders.details.billing.notes" />
          </th>
          {!readOnly && (
          <th>
            <Translated k="general.actions" />
          </th>)}
        </tr>
      </thead>
      <tbody>
        {data
          && data.payments.map(p => (
            <tr key={p.uuid}>
              <td>{`${p.amount.value} ${p.amount.currency}`}</td>
              <td>
                <DateOutput value={p.date} />
              </td>
              <td>{p.notes}</td>
              {!readOnly && (
              <td>
                <div className="buttons">
                  <IconModal
                    icon="fa-trash-alt"
                    tooltipText="general.delete"
                    render={props => (
                      <PaymentDelete
                        onCancel={props.handleClose}
                        initialValues={p}
                        orderId={data.id}
                        paymentId={p.id}
                        onSuccess={handleSuccess}
                      />
                    )}
                    card
                  />
                </div>
              </td>
              )}
            </tr>
          ))}
      </tbody>
    </table>
    {!readOnly && data.orderStatus === 'COMPLETED' && (
      <div className="field is-grouped is-grouped-right">
        <ButtonModal
          buttonClassName="is-default"
          buttonLabel="pages.back.orders.details.payments.add"
          render={props => (
            <PaymentAdd
              handleSuccess={handleSuccess}
              handleClose={props.handleClose}
              resourceId={data.id}
            />
          )}
          card
        />
      </div>
    )}
  </Fragment>
);

export default PaymentOverviewDetails;
