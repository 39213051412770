import React, { Component, Fragment } from 'react';
import Axios from 'axios';
import appUrl from '../../../utils/remote';
import Title from '../../../base/elements/Title';
import DateOutput from '../../../base/elements/DateOutput';
import MoneyOutput from '../../../base/elements/MoneyOutput';
import ButtonIcon from '../../../base/elements/ButtonIcon';
import Translated from '../../../base/elements/Translated';
import InputQuantity from './InputQuantity';
import ErrorNotification from '../../../base/elements/ErrorNotification';

class OrderPartecipationEditForm extends Component {
  state = {
    availableEvents: null,
    selectedEvent: null,
    selectedPackages: [],
    loading: true,
  };


  async componentDidMount() {
    const { eventTypeId, isBackOffice } = this.props;

    let response;
    if (isBackOffice()) {
      response = await Axios.get(appUrl(`/fo/eventTypes/${eventTypeId}/events`));
    } else {
      response = await Axios.get(appUrl(`/fo/eventTypes/${eventTypeId}/events?bookable=true`));
    }

    const { data } = response;
    this.setState({ availableEvents: data, loading: false });

    if (data !== null && data !== undefined && data.length === 1) {
      this.selectEvent(data[0].uuid);
    }
  }

  selectEvent = (uuid) => {
    const { availableEvents } = this.state;
    const selectedEvent = availableEvents.find(e => e.uuid === uuid);

    this.setState({ selectedEvent });
  };

  resetEvent = () => {
    this.setState({
      selectedEvent: null,
      selectedPackages: [],
    });
  };

  togglePackage = async (uuid) => {
    const { partecipant, updateTotal } = this.props;
    const { selectedEvent, selectedPackages } = this.state;

    const eventPackage = selectedEvent.packages.find(p => p.uuid === uuid);

    const idx = selectedPackages.map(sp => sp.uuid).indexOf(uuid);

    let partecipations;
    if (idx > -1) {
      partecipations = [...selectedPackages.slice(0, idx), ...selectedPackages.slice(idx + 1)];
    } else {
      partecipations = [
        ...selectedPackages,
        {
          uuid,
          eventPackage,
          options: [],
          quantity: 1,
          vat: eventPackage.vat,
          packageAmount: eventPackage.totalPrice,
        },
      ];
    }
    // Chiamata al server
    const tmpPartecipant = {
      ...partecipant,
      partecipations,
    };
    updateTotal(tmpPartecipant);

    this.setState({
      selectedPackages: partecipations,
    });
  };

  changeQuantity = async (uuid, quantity) => {
    const { partecipant, updateTotal } = this.props;
    const { selectedPackages } = this.state;
    const idx = selectedPackages.map(sp => sp.uuid).indexOf(uuid);

    const partecipations = [
      ...selectedPackages.slice(0, idx),
      { ...selectedPackages[idx], quantity },
      ...selectedPackages.slice(idx + 1),
    ];

    // Chiamata al server
    const tmpPartecipant = {
      ...partecipant,
      partecipations,
    };
    updateTotal(tmpPartecipant);

    this.setState({
      selectedPackages: partecipations,
    });
  };

  toggleOption = async (packageUuid, optionUuid) => {
    const { partecipant, updateTotal } = this.props;
    const { selectedEvent, selectedPackages } = this.state;
    // cerco la eventPackageOption (non partecipation!)
    const option = selectedEvent.packages
      .find(p => p.uuid === packageUuid)
      .options.find(o => o.uuid === optionUuid);
    // cerco l'indice del packagePartecipation da modificare
    const pIdx = selectedPackages.map(p => p.uuid).indexOf(packageUuid);
    // nel packagePartecipation, cerco la options da modificare
    const { options } = selectedPackages[pIdx];
    const oIdx = options.map(o => o.uuid).indexOf(optionUuid);

    let partecipations;
    if (oIdx > -1) {
      partecipations = [
        ...selectedPackages.slice(0, pIdx),
        {
          ...selectedPackages[pIdx],
          options: [...options.slice(0, oIdx), ...options.slice(oIdx + 1)],
        },
        ...selectedPackages.slice(pIdx + 1),
      ];
    } else {
      partecipations = [
        ...selectedPackages.slice(0, pIdx),
        {
          ...selectedPackages[pIdx],
          options: [
            ...options,
            {
              uuid: optionUuid,
              name: option.name,
              option,
              onSitePayment: option.onSitePayment,
              vat: option.vat,
              packageOptionAmount: option.totalAmount,
            },
          ],
        },
        ...selectedPackages.slice(pIdx + 1),
      ];
    }

    // Chiamata al server
    const tmpPartecipant = {
      ...partecipant,
      partecipations,
    };
    updateTotal(tmpPartecipant);

    this.setState({
      selectedPackages: partecipations,
    });
  };

  isPackageSelected = (packageUuid) => {
    const { selectedPackages } = this.state;
    return selectedPackages.map(sp => sp.uuid).indexOf(packageUuid) > -1;
  };

  isOptionSelected = (packageUuid, optionUuid) => {
    const { selectedPackages } = this.state;
    const pIdx = selectedPackages.map(p => p.uuid).indexOf(packageUuid);
    if (pIdx < 0) {
      return false;
    }
    const oIdx = selectedPackages[pIdx].options.map(o => o.uuid).indexOf(optionUuid);
    return oIdx > -1;
  };

  chooseNavType = (selectedEvent, availableEvents) => {
    if (selectedEvent === null) {
      return <Title size={3} label="pages.front.home.events" />;
    }
    if (availableEvents.length === 1) {
      return (
        <nav className="level">
          <div className="level-left">
            <div className="level-item">
              <Title size={3} label={selectedEvent.name} untranslated />
            </div>
          </div>
        </nav>
      );
    }
    return (
      <nav className="level">
        <div className="level-left">
          <div className="level-item">
            <ButtonIcon handleClick={this.resetEvent} label="general.back" icon="fa-chevron-left" />
          </div>
          <div className="level-item">
            <Title size={3} label={selectedEvent.name} untranslated />
          </div>
        </div>
      </nav>
    );
  };

  render() {
    const {
      loading, availableEvents, selectedPackages, selectedEvent, error,
    } = this.state;

    const {
      handleSubmit, handleCancel, partecipant, totalAmount,
    } = this.props;

    return (
      <Fragment>
        <header className="modal-card-head has-background-primary">
          <div className="modal-card-title has-text-white">
            {this.chooseNavType(selectedEvent, availableEvents)}
          </div>
          <button type="button" className="delete" aria-label="close" onClick={handleCancel} />
        </header>
        <section className="modal-card-body">
          <Fragment>
            {loading === false && error && (
              <ErrorNotification
                clearError={() => this.setState({ error: null })}
                errors={{ failed: error }}
              />
            )}
            {loading === false && selectedEvent === null && (
              <table className="table is-bordered is-hoverable is-fullwidth">
                <tbody>
                  {availableEvents.map(e => (
                    <tr key={e.uuid} className="clickable" onClick={() => this.selectEvent(e.uuid)}>
                      <td>{e.name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
            {/* TODO: qua estrarre due componenti (dx e sx) */}
            <div className="columns">
              <div className="column is-two-thirds">
                {loading === false
                  && selectedEvent !== null
                  && selectedEvent.packages.map(p => (
                    <div className="box" key={p.uuid}>
                      <div className="columns is-vcentered">
                        <div className="column is-one-quarter has-text-centered">
                          <ButtonIcon
                            handleClick={() => this.togglePackage(p.uuid)}
                            iconPackage="far"
                            icon={this.isPackageSelected(p.uuid) ? 'fa-check-square' : 'fa-square'}
                            label={
                              this.isPackageSelected(p.uuid) ? 'general.remove' : 'general.add'
                            }
                            medium
                          />
                          {p.multiPurchase === true && (
                            <InputQuantity
                              disabled={!this.isPackageSelected(p.uuid)}
                              handleChange={q => this.changeQuantity(p.uuid, q)}
                            />
                          )}
                        </div>
                        <div className="column right-column">
                          <div className="columns is-vcentered">
                            <div className="column">
                              <div>
                                <Title size={4} label={p.name} untranslated />
                                <DateOutput value={p.startDate} />
                                {' - '}
                                <DateOutput value={p.endDate} />
                              </div>
                            </div>
                            <div className="column">
                              <MoneyOutput money={p.totalPrice} />
                            </div>
                          </div>
                          {p.options.map(o => (
                            <div className="columns is-vcentered compact" key={o.uuid}>
                              <div className="column">
                                <label className="checkbox">
                                  <input
                                    type="checkbox"
                                    uuid={p.uuid}
                                    checked={this.isOptionSelected(p.uuid, o.uuid)}
                                    onChange={() => this.toggleOption(p.uuid, o.uuid)}
                                    disabled={loading || !this.isPackageSelected(p.uuid)}
                                  />
                                  <span className="is-unselectable">{o.name}</span>
                                </label>
                              </div>
                              <div className="column">
                                <MoneyOutput money={o.totalAmount} />
                              </div>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              <div className="column">
                <div className="box has-background-light sticky">
                  {selectedPackages.map(sp => (
                    <div key={sp.uuid}>
                      <div className="columns compact">
                        <div className="column">
                          <Title size={6} label={sp.eventPackage.name} untranslated />
                        </div>
                        <div className="column">
                          <MoneyOutput
                            money={sp.eventPackage.totalPrice}
                            multiplier={sp.quantity}
                          />
                          {sp.quantity > 1 && (
                            <div className="is-size-7">
                              {`(${sp.quantity} x `}
                              <MoneyOutput money={sp.eventPackage.totalPrice} />
                              {')'}
                            </div>
                          )}
                        </div>
                      </div>
                      {sp.options.map(o => (
                        <div key={o.uuid} className="columns compact is-vcentered">
                          <div className="column is-size-7">
                            <div className="padded-left">{o.name}</div>
                          </div>
                          <div className="column">
                            <MoneyOutput money={o.option.totalAmount} />
                            {o.onSitePayment && (
                              <div className="is-size-7 has-text-weight-bold">
                                <Translated k="model.eventPackageOption.onSitePayment" />
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                      <hr className="compact has-background-grey-lighter" />
                    </div>
                  ))}
                  {selectedPackages.length > 0 && (
                    <div className="columns compact has-text-weight-semibold">
                      <div className="column">
                        <Translated k="general.total" />
                      </div>
                      <div className="column">
                        <MoneyOutput money={totalAmount} />
                      </div>
                    </div>
                  )}
                  {selectedPackages.length > 0 && (
                    <button
                      type="button"
                      className="button is-primary is-fullwidth"
                      disabled={loading}
                      onClick={() => {
                        handleSubmit(selectedPackages, partecipant, handleCancel);
                      }}
                    >
                      <Translated k="pages.shared.orders.partecipation.confirm" />
                    </button>
                  )}
                </div>
              </div>
            </div>
          </Fragment>
        </section>
        <footer className="modal-card-foot" style={{ justifyContent: 'flex-end' }}>
          <button type="button" className="button" disabled={loading} onClick={handleCancel}>
            <Translated k="general.cancel" />
          </button>
        </footer>
      </Fragment>
    );
  }
}

export default OrderPartecipationEditForm;
