import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import CrudList from '../../utils/CrudList';
import Title from '../../base/elements/Title';
import OrderableColumn from '../../base/elements/OrderableColumn';
import Translated from '../../base/elements/Translated';
import IconModal from '../../base/components/modal/IconModal';
import Pagination from '../../base/components/pagination/Pagination';
import DateOutput from '../../base/elements/DateOutput';
import OrderDetails from './OrderDetails';
import OrderDelete from '../back/orders/OrderDelete';
import MoneyOutput from '../../base/elements/MoneyOutput';

const OrderListPage = ({ history }) => (
  <CrudList
    initialSorting={{ sort: 'CREATION_DATE', asc: false }}
    initialFilter={{
      fullSearch: '',
      typeId: '',
      paymentMethod: '',
      status: '',
      maxResults: 10,
    }}
    restUrl="/fo/orders"
    render={({
      loading, data, error, params, filters, handleNavigation, changeOrdering, refreshResults,
    }) => (
      <div className="section main-page">
        <div className="container">
          <div className="level">
            <div className="level-left">
              <Title size={3} label="pages.front.order.ordersList" />
            </div>
          </div>
          {loading === false && error && <div className="notification is-danger">{error}</div>}
          <div className="table-container">
            {loading === true && <div className="loading" />}
            <table className="table is-hoverable is-bordered is-fullwidth">
              <thead className="has-background-white-ter">
                <tr>
                  <OrderableColumn name="CREATION_DATE" handleClick={changeOrdering} {...params}>
                    <Translated k="model.order.creationDate" />
                  </OrderableColumn>
                  <th>
                    <Translated k="model.eventType.entityName" />
                  </th>
                  <th>
                    <Translated k="model.order.paymentMethod.entityName" />
                  </th>
                  <th>
                    <Translated k="model.order.totalAmount" />
                  </th>
                  <th>
                    <Translated k="model.order.payedAmount" />
                  </th>
                  <th>
                    <Translated k="model.order.toPay" />
                  </th>
                  <th>
                    <Translated k="model.order.paymentStatus.entityName" />
                  </th>
                  <th>
                    <Translated k="model.order.status" />
                  </th>
                  <th>
                    <Translated k="general.actions" />
                  </th>
                </tr>
              </thead>
              <tbody>
                {data && data.elements
                  && data.elements.map(e => (
                    <tr key={e.id}>
                      <td>
                        <DateOutput value={e.creationDate} />
                      </td>
                      {
                        <td>
                          {e.items
                            .filter(i => i.type === 'purchase')
                            .map(i => i.eventType.name)
                            .join(', ')}
                        </td>
                      }
                      <td>
                        <Translated k={`model.order.paymentMethodTitle.${e.paymentMethod}`} />
                      </td>
                      <td><MoneyOutput money={e.totalAmount} /></td>
                      <td><MoneyOutput money={e.totalPaid} /></td>
                      <td><MoneyOutput money={e.toPay} /></td>
                      <td>
                        {e.orderStatus === 'COMPLETED'
                        && <Translated k={`model.order.paymentStatus.${e.paymentStatus}`} />}
                      </td>
                      <td>
                        <Translated k={`model.order.orderStatus.${e.orderStatus}`} />
                      </td>
                      <td>
                        <div className="buttons">
                          {e.orderStatus === 'COMPLETED' && (
                          <IconModal
                            icon="fa-search"
                            tooltipText="general.view"
                            render={({ handleClose }) => (
                              <OrderDetails
                                url={`/fo/orders/${e.id}`}
                                handleClose={handleClose}
                                refreshResults={refreshResults}
                                readOnly
                              />
                            )}
                            card
                          />
                          )}
                          {e.orderStatus === 'IN_PROGRESS' && (
                            <Fragment>
                              <IconModal
                                icon="fa-play"
                                tooltipText="pages.front.order.resume"
                                render={() => history.push(`/orders/${e.id}`)}
                                card
                              />
                              <IconModal
                                icon="fa-trash-alt"
                                tooltipText="general.delete"
                                render={props => (
                                  <OrderDelete
                                    onCancel={props.handleClose}
                                    initialValues={e}
                                    orderId={e.id}
                                    onSuccess={refreshResults}
                                    logical
                                  />
                                )}
                                card
                              />
                            </Fragment>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <Pagination
            loading={loading}
            data={data}
            startPosition={params.startPosition}
            maxResults={filters.maxResults}
            handleNavigation={handleNavigation}
          />
        </div>
      </div>
    )}
  />
);

export default withRouter(OrderListPage);
