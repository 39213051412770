import React from 'react';
import Axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import classNames from 'classnames';
import InputText from '../base/form/InputText';
import ErrorNotification from '../base/elements/ErrorNotification';
import appUrl from '../utils/remote';

const RecoveryPasswordSchema = Yup.object().shape({
  username: Yup.string()
    .required('general.validation.required')
    .default(''),
});

const RecoveryPasswordForm = ({ onSuccess }) => (
  <Formik
    initialValues={RecoveryPasswordSchema.cast({})}
    validationSchema={RecoveryPasswordSchema}
    onSubmit={async (data, actions) => {
      try {
        await Axios.post(appUrl(`${'/fo/users/requestPasswordReset?username='}${data.username}`));
        onSuccess();
      } catch (err) {
        actions.setErrors({
          failed:
            err.response !== undefined
              ? `${err.response.data.message}`
              : err.message,
        });
      }
      actions.setSubmitting(false);
    }}
    render={({
      dirty, isSubmitting, handleSubmit, setErrors, ...others
    }) => (
      <form onSubmit={handleSubmit}>
        <ErrorNotification clearError={() => setErrors({ failed: null })} {...others} />
        <InputText name="username" autofocus label="model.user.username" {...others} />
        <button
          type="submit"
          className={classNames('button is-primary is-fullwidth', {
            'is-loading': isSubmitting,
          })}
          disabled={!dirty || isSubmitting}
        >
          Submit
        </button>
      </form>
    )}
  />
);

export default RecoveryPasswordForm;
