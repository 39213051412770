import React, { Component, Fragment } from 'react';
import Axios from 'axios';
import OrderPartecipant from './OrderPartecipant';
import OrderStepsNavigation from '../OrderStepsNavigation';
import Title from '../../../base/elements/Title';
import appUrl from '../../../utils/remote';
import ErrorNotification from '../../../base/elements/ErrorNotification';
import OrderPricing from '../OrderPricing';
import { UnsafeTranslated } from '../../../base/elements/Translated';

class OrderPartecipationsForm extends Component {
  constructor(props) {
    super(props);

    const { initialValues } = props;

    this.state = {
      order: initialValues,
    };
  }

  addPartecipations = (newPartecipations, partecipant, callback) => {
    const { order } = this.state;
    const { partecipants } = order.items[0];
    const partecipantIdx = partecipants.indexOf(partecipant);
    const { partecipations } = partecipants[partecipantIdx];

    const modifiedOrder = {
      ...order,
      items: [
        {
          ...order.items[0],
          partecipants: [
            ...partecipants.slice(0, partecipantIdx),
            {
              ...partecipants[partecipantIdx],
              partecipations: [...partecipations, ...newPartecipations],
            },
            ...partecipants.slice(partecipantIdx + 1),
          ],
        },
        ...order.items.slice(1),
      ],
    };

    this.updateOrder(modifiedOrder);

    callback();
  };

  removePartecipations = (eventPartecipations, partecipant) => {
    const { order } = this.state;
    const { partecipants } = order.items[0];
    const toRemove = [].concat(eventPartecipations || []).map(pa => pa.uuid);
    const partecipantIdx = partecipants.indexOf(partecipant);
    const { partecipations } = partecipants[partecipantIdx];

    const modifiedOrder = {
      ...order,
      items: [
        {
          ...order.items[0],
          partecipants: [
            ...partecipants.slice(0, partecipantIdx),
            {
              ...partecipants[partecipantIdx],
              partecipations: partecipations.filter(pa => toRemove.indexOf(pa.uuid) < 0),
            },
            ...partecipants.slice(partecipantIdx + 1),
          ],
        },
        ...order.items.slice(1),
      ],
    };

    this.updateOrder(modifiedOrder);
  };

  submitAndNext = () => {
    const { requestOptions, onSuccess } = this.props;
    const { order } = this.state;

    this.setState({ loading: true }, async () => {
      try {
        const result = await Axios.put(appUrl(`/fo/orders/${order.id}`), order, requestOptions);
        onSuccess(result.data);
      } catch (err) {
        this.setState({
          loading: false,
          error:
            err.response !== undefined
              ? `${err.response.data.message}`
              : err.message,
        });
      }
    });
  };

  updateOrder = async order => {
    const { requestOptions } = this.props;
    try {
      const response = await Axios.put(appUrl(`/fo/orders/${order.id}`), order, requestOptions);
      this.setState({
        order: response.data,
      });
    } catch (err) {
      this.setState({
        loading: false,
        error:
          err.response !== undefined
            ? `${err.response.data.message}`
            : err.message,
      });
    }
  };

  render() {
    const {
      order, error, loading,
    } = this.state;
    const { onCancel, onSuccess, navigation } = this.props;

    return (
      <Fragment>
        {navigation && (
          <OrderStepsNavigation
            renderCenter={() => <Title info size={4} centered label="pages.shared.orders.steps.2" />}
            onClickLeft={onCancel}
            onClickRight={this.submitAndNext}
            loading={loading}
            submitting={loading}
            showLeft
            showRight
            disabledRight={order.items[0].partecipants.map( p => p.partecipations.length).reduce( (a,b) => a + b ) <= 0}
          />
        )}
        <div className="notification is-info is-light media">
          <i className="media-left fas fa-info-circle" />
          <div className="media-content">
            <UnsafeTranslated k="pages.shared.orders.steps.description.2" />
          </div>
        </div>

        {loading === false && error && (
          <ErrorNotification
            clearError={() => this.setState({ error: null })}
            errors={{ failed: error }}
          />
        )}
        <div className="columns">
          <div className="column">
            {order.items[0].partecipants.map(p => (
              <OrderPartecipant
                key={p.uuid}
                partecipant={p}
                order={order}
                handleRemovePartecipations={this.removePartecipations}
                handleAddPartecipations={this.addPartecipations}
              />
            ))}
          </div>
          <div className="column is-one-third">
            <OrderPricing order={order} />
          </div>
        </div>
        {navigation && (
          <OrderStepsNavigation
            onClickLeft={onCancel}
            onClickRight={() => onSuccess(order)}
            showLeft
            showRight
            disabledRight={order.items[0].partecipants.map( p => p.partecipations.length).reduce( (a,b) => a + b ) <= 0}
          />
        )}
      </Fragment>
    );
  }
}

export default OrderPartecipationsForm;
