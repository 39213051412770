import React from 'react';
import classNames from 'classnames';
import Translated from './Translated';

const ButtonIcon = ({
  label, iconPackage = 'fas', icon, handleClick, medium, small, primary,
}) => (
  <button
    type="button"
    className={classNames('button', {
      'is-medium': medium,
      'is-small': small,
      'is-primary': primary,
    })}
    onClick={handleClick}
  >
    <span className="icon">
      <i className={`${iconPackage} ${icon}`} />
    </span>
    <span>
      <Translated k={label} />
    </span>
  </button>
);

export default ButtonIcon;
