import React, { Fragment } from 'react';
import { Formik } from 'formik';
import SubmitOnChange from './utils/SubmitOnChange';

const FilterForm = ({ initialValues, onSubmit, render }) => (
  <div className="filters">
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      render={({ handleSubmit, ...others }) => (
        <Fragment>
          <form onSubmit={handleSubmit}>{render({ ...others })}</form>
          <SubmitOnChange handleSubmit={handleSubmit} values={others.values} />
        </Fragment>
      )}
    />
  </div>
);

export default FilterForm;
