import React, { Fragment } from 'react';
import { AppContext } from '../../App';

const OutputNumber = ({ value, precision = 2, symbol }) => (
  <AppContext.Consumer>
    {({ translate }) => (
      <Fragment>
        {`${`${value.toFixed(precision).replace(/\./g, translate('general.separator'))}`
      + ' '}${symbol}`}
      </Fragment>
    )}
  </AppContext.Consumer>
);

export default OutputNumber;
