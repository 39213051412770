import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import Tabs from './Tabs';

class TabPanel extends Component {
  state = {
    activeIndex: 0,
  };

  changeTab = (activeIndex) => {
    this.setState({ activeIndex });
  };

  render() {
    const { children, titles } = this.props;
    const { activeIndex } = this.state;

    return (
      <Fragment>
        <Tabs titles={titles} activeIndex={activeIndex} handleClick={this.changeTab} />
        <div className="tab-panel-body">
          {React.Children.map(children, (child, i) => (
            <div className={classNames({ 'is-invisible is-removed': i !== activeIndex })}>{child}</div>
          ))}
        </div>
      </Fragment>
    );
  }
}

export default TabPanel;
