import React from 'react';
import Title from '../../../base/elements/Title';
import ColumnOutput from '../../../base/elements/ColumnOutput';
import DateOutput from '../../../base/elements/DateOutput';
import Translated from '../../../base/elements/Translated';

const PartecipantOverviewDetails = ({ data }) => (
  <div className="columns">
    <div className="column">
      {data.items[0].partecipants.map(partecipant => (
        <div className="columns" key={partecipant.uuid}>
          <div className="column">
            <Title
              label={`${partecipant.person.firstName} ${partecipant.person.lastName}`}
              section
              size={4}
              untranslated
            />

            <div className="box">
              <div className="columns">
                <ColumnOutput
                  label="model.partecipant.relationship"
                  render={() => partecipant.relationship}
                />
              </div>
              <div className="columns">
                <ColumnOutput
                  label="model.person.dob"
                  render={() => <DateOutput value={partecipant.person.dateOfBirth} />}
                />
                <ColumnOutput
                  label="model.person.ssnCode"
                  render={() => partecipant.person.ssnCode}
                />
                <ColumnOutput
                  label="model.person.gender"
                  render={() => partecipant.person.personGender}
                />
              </div>
              <div className="columns">
                <ColumnOutput
                  label="model.person.allergies"
                  render={() => partecipant.person.allergies.join(', ')}
                />
                <ColumnOutput
                  label="model.person.medicalCares"
                  render={() => partecipant.person.medicalCares.join(', ')}
                />
                <ColumnOutput
                  label="model.person.englishLevel.entityName"
                  render={() => (
                    <Translated
                      k={`model.person.englishLevel.${partecipant.person.englishLevel}`}
                    />
                  )}
                />
              </div>
              <div className="columns">
                <ColumnOutput
                  label="model.person.additionalInfo"
                  render={() => partecipant.person.additionalInfo}
                />
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
);

export default PartecipantOverviewDetails;
