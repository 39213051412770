import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import queryString from 'query-string';
import LoginForm from '../../forms/LoginForm';
import Logo from '../../../images/canadian-logo.png';
import { AppContext } from '../../App';
import Translated, { UnsafeTranslated } from '../../base/elements/Translated';
import LanguageSelector from './LanguageSelector';
import Title from '../../base/elements/Title';

const LoginPage = ({ location }) => (
  <AppContext.Consumer>
    {({ loadUser }) => (
      <div className="has-background-white login-card column is-4 is-offset-4">
        <figure className="avatar has-text-centered login-logo">
          <img src={Logo} alt="Canadian Island" />
        </figure>
        <Title size={4} section label="pages.auth.title" centered />
        <LoginForm onSuccess={loadUser} />
        <hr />
        <p className="has-text-centered" style={{ fontWeight: 700 }}>
          <Link to={`/signup?locale=${queryString.parse(location.search).locale || 'it-IT'}`} className="has-text-primary">
            <Translated k="pages.auth.signUp" />
          </Link>
          &nbsp;·&nbsp;
          <Link to={`/recovery?locale=${queryString.parse(location.search).locale || 'it-IT'}`} className="has-text-grey">
            <Translated k="pages.auth.forgotPassword" />
          </Link>
        </p>
        <div className="columns is-centered columns-content">
          <div className="column is-half">
            <LanguageSelector />
          </div>
        </div>
        <div className="columns is-centered columns-content">
          <div className="column is-full has-text-centered">
            <UnsafeTranslated k="general.help" />
          </div>
        </div>
      </div>
    )}
  </AppContext.Consumer>
);

export default withRouter(LoginPage);
