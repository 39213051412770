import React, { Component } from 'react';
import classNames from 'classnames';
import Translated from './Translated';

class SubmitPostButton extends Component {
  state = {
    submitting: false,
  };

  submit = () => {
    const { handleSubmit } = this.props;

    this.setState({ submitting: true }, handleSubmit);
  };

  render() {
    const { label } = this.props;
    const { submitting } = this.state;

    return (
      <button
        type="button"
        disabled={submitting}
        className={classNames('button is-primary', {
          'is-loading': submitting,
        })}
        onClick={this.submit}
      >
        <Translated k={label} />
      </button>
    );
  }
}

export default SubmitPostButton;
