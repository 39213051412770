import React, {Component, Fragment} from 'react';
import ButtonModal from "../../../../base/components/modal/ButtonModal";
import InvoiceAdd from "./InvoiceAdd";
import Translated from "../../../../base/elements/Translated";
import MoneyInput from "../../../../base/elements/MoneyInput";

class InvoiceDepositAdd extends Component {
  constructor(props) {
    super(props);
    const {totalDeposit, invoiceResidue} = this.props.order;

    this.state = {
      depositAmount: Math.min(totalDeposit.value, invoiceResidue.value),
      errors: {
        depositAmount: ''
      },
      touched: {
        depositAmount: false
      }
    }
  }

  handleChange = value => {
    const {invoiceResidue} = this.props.order;

    if (value < 0 || value > invoiceResidue.value) {
      this.setState({
        errors: {
          depositAmount: 'pages.back.orders.details.billing.invoice.invalidStartingAmount'
        }
      });
      return;
    }
    this.setState({
      depositAmount: value,
      errors: {
        depositAmount: ''
      }
    });
  }

  handleBlur = () => {
    this.setState({touched: {depositAmount: true}});
  }

  render() {
    const {depositAmount} = this.state;
    const {order, handleSuccess, handleClose} = this.props;

    return <Fragment>
      <header className="modal-card-head">
        <p className="modal-card-title">
          <Translated k="pages.back.orders.details.billing.createDepositInvoice"/>
        </p>
        <button type="button" className="delete" aria-label="close" onClick={handleClose}/>
      </header>
      <section className="modal-card-body">
        <div className="column is-half is-offset-one-quarter">
          <MoneyInput
            name="depositAmount"
            label="pages.back.orders.details.billing.invoice.startingAmount"
            disableAutoComplete
            leftLabel="€"
            values={this.state}
            errors={this.state.errors}
            touched={this.state.touched}
            setFieldValue={(_, value) => this.handleChange(value)}
            handleBlur={this.handleBlur}
          />
          <div className="field is-grouped is-grouped-right">
            <button type="button" className="button" onClick={handleClose}>
              <Translated k="general.close" />
            </button>
            <ButtonModal
              buttonClassName="is-info"
              buttonLabel="pages.back.orders.details.billing.createDepositInvoice"
              disabled={this.state.errors.depositAmount}
              render={innerProps => (
                <InvoiceAdd
                  depositAmount={depositAmount}
                  handleSuccess={handleSuccess}
                  handleClose={() => {
                    handleClose();
                    innerProps.handleClose();
                  }}
                  billingHolder={order.billingHolder}
                  order={order}
                />
              )}
            />
          </div>
        </div>
      </section>
    </Fragment>;
  }
}

export default InvoiceDepositAdd;