import React, { Component } from 'react';
import classNames from 'classnames';
import CurrencyInput from 'react-currency-input';
import Get from 'lodash.get';
import Translated from './Translated';
import { AppContext } from '../../App';

class MoneyInput extends Component {
  constructor(props) {
    super(props);

    const { values, name } = this.props;

    this.state = {
      masked: Get(values, name) !== undefined ? Get(values, name) : '0.00',
    };
  }

  handleChange(event, maskedvalue, floatvalue, setFieldValue, name) {
    this.setState({ masked: maskedvalue });

    setFieldValue(name, floatvalue);
  }

  render() {
    const {
      name,
      label,
      errors,
      touched,
      handleBlur,
      autoFocus,
      rightLabel,
      leftLabel,
      small,
      moreThan,
      disableAutoComplete,
      setFieldValue,
      precision = 2,
    } = this.props;

    const { masked } = this.state;

    return (
      <AppContext.Consumer>
        {({ translate }) => (
          <div className="field">
            <div
              className={classNames('label', {
                'is-small': small,
              })}
            >
              {label && (
                <label>
                  <Translated k={label} />
                </label>
              )}
            </div>
            <div className="field-body">
              <div className="field is-expanded">
                <div className="field has-addons">
                  {rightLabel && (
                    <p className="control">
                      <span
                        className={classNames('button is-static', {
                          'is-small': small,
                        })}
                      >
                        {rightLabel}
                      </span>
                    </p>
                  )}
                  <p className="control is-expanded">
                    <CurrencyInput
                      name={name}
                      className={classNames('input', {
                        'is-danger': Get(errors, name) && Get(touched, name),
                        'is-small': small,
                      })}
                      decimalSeparator={translate('general.separator')}
                      thousandSeparator=""
                      precision={precision}
                      onChangeEvent={(event, maskedvalue, floatvalue) => this.handleChange(
                        event, maskedvalue, floatvalue, setFieldValue, name,
                      )
                      }
                      onBlur={handleBlur}
                      value={masked}
                      autoFocus={autoFocus}
                      autoComplete={disableAutoComplete && 'off'}
                      allowNegative
                    />
                  </p>
                  {leftLabel && (
                    <p className="control">
                      <span
                        className={classNames('button is-static', {
                          'is-small': small,
                        })}
                      >
                        {leftLabel}
                      </span>
                    </p>
                  )}
                </div>
                {Get(errors, name) && Get(touched, name) && (
                  <p className="help is-danger">
                    <Translated k={Get(errors, name)} p={moreThan} />
                  </p>
                )}
              </div>
            </div>
          </div>
        )}
      </AppContext.Consumer>
    );
  }
}

export default MoneyInput;
