import React, {Component} from 'react';
import {withRouter} from 'react-router-dom';
import classNames from 'classnames';
import queryString from 'query-string';
import WithRemoteData from '../../utils/WithRemoteData';

class LanguageSelector extends Component {
  reload = (e) => {
    const { location } = this.props;
    console.log(location);
    if (location.search) {
        const queryParams = new URLSearchParams(this.props.location.search);
        let path = `${location.pathname}`;
        if (queryParams.has('eventTypeId')) {
            path = path + `?eventTypeId=${queryParams.get('eventTypeId')}&locale=${e.target.value}`;
        } else {
            path = path + `?locale=${e.target.value}`
        }
        window.location = path;
    } else {
      window.location = `${location.pathname}?locale=${e.target.value}`
    }

  };

  render() {
    const { location } = this.props;
    const { locale } = queryString.parse(location.search);

    return (
      <WithRemoteData
        url="/i18n/locales"
        render={({ loading, data }) => (
          <div className="control is-expanded">
            <div
              className={classNames('select is-small', {
                'is-loading': loading,
              })}
            >
              <select onChange={this.reload} value={locale || 'it-IT'}>
                {loading === false
                  && data
                  && data.map(d => (
                    <option key={d.code} value={d.code}>
                      {d.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        )}
      />
    );
  }
}

export default withRouter(LanguageSelector);
