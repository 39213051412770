import React from 'react';
import Translated from './Translated';

const ColumnOutput = ({ label, render }) => (
  <div className="column">
    <strong>
      <Translated k={label} />
    </strong>
    <br />
    {render()}
  </div>
);

export default ColumnOutput;
