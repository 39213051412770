/* eslint
    jsx-a11y/anchor-is-valid: "off",
    jsx-a11y/interactive-supports-focus: "off",
    jsx-a11y/click-events-have-key-events: "off"
*/
import React from 'react';
import classNames from 'classnames';

const PaginationLink = ({ index, currentPage, handleNavigation }) => (
  <li>
    <a
      role="button"
      className={classNames('pagination-link', {
        'is-current': index === currentPage,
      })}
      aria-label="Goto page 1"
      onClick={handleNavigation}
    >
      {index}
    </a>
  </li>
);

export default PaginationLink;
