import React, {Component, Fragment} from 'react';
import Axios from 'axios';
import OrderStepsNavigation from '../OrderStepsNavigation';
import ButtonModal from '../../../base/components/modal/ButtonModal';
import OrderPartecipantEditForm from './OrderPartecipantEditForm';
import appUrl from '../../../utils/remote';
import ErrorNotification from '../../../base/elements/ErrorNotification';
import Title from '../../../base/elements/Title';
import Translated, {UnsafeTranslated} from '../../../base/elements/Translated';
import OrderPartecipantChooseForm from "./OrderPartecipantChooseForm";

class OrderPartecipantsForm extends Component {
   constructor(props) {
      super(props);

      const {initialValues} = this.props;
      this.state = {
         order: initialValues,
         loading: false,
         error: null,
      };
   }

   getRequestOptions = () => {
      const {impersonatedUser} = this.props;

      return impersonatedUser
         ? {
            headers: {
               'x-impersonate-user': impersonatedUser,
            },
         }
         : {};
   };


   addPartecipant = (p, callback) => {
      const {order} = this.state;

      this.setState(
         {
            order: {
               ...order,
               items: [
                  {
                     ...order.items[0],
                     partecipants: [...order.items[0].partecipants, p],
                  },
               ],
            },
         },
         callback,
      );
   };

   removePartecipant = (p) => {
      const {order} = this.state;
      const index = order.items[0].partecipants.indexOf(p);
      order.items[0].partecipants.splice(index, 1);
      this.setState(order);
   };

   submitAndNext = () => {
      const {
         requestOptions, onSuccess, resourceId,
      } = this.props;
      const {order} = this.state;

      this.setState({loading: true}, async () => {
         try {
            const result = await Axios.put(appUrl(`/fo/orders/${resourceId}`), order, requestOptions);
            onSuccess(result.data);
         } catch (err) {
            this.setState({
               loading: false,
               error: err.response !== undefined ? `${err.response.data.message}` : err.message,
            });
         }
      });
   };

   render() {
      const {
         order, error, loading,
      } = this.state;

      return (
         <div>
            <OrderStepsNavigation
               renderCenter={() => <Title size={4} centered label="pages.shared.orders.steps.1" info/>}
               showRight
               onClickRight={this.submitAndNext}
               loading={loading}
               submitting={loading}
               disabledRight={order.items[0].partecipants.length <= 0}
            />
            <div className="notification is-info is-light media">
               <i className="media-left fas fa-info-circle"/>
               <div className="media-content">
                  <UnsafeTranslated k="pages.shared.orders.steps.description.1"/>
               </div>
            </div>
            {loading === false && error && (
               <ErrorNotification
                  clearError={() => this.setState({error: null})}
                  errors={{failed: error}}
               />
            )}
            {order.items[0].partecipants.map(p => (
               <div className="box">
                  <div key={p.uuid} className="columns">
                     <div className="column is-size-5">
                        {`${p.person.firstName} ${p.person.lastName}`}
                     </div>
                     <div className="column">
                        {`${p.relationship}`}
                     </div>
                     <div className="column">
                        <button
                           type="button"
                           onClick={() => {
                              this.removePartecipant(p);
                           }}
                           className="button is-small is-danger"
                        >
                           <Translated k="general.delete"/>
                        </button>
                     </div>
                  </div>
               </div>
            ))}
            <div className="level">
               <div className="level-left">
                  <div className="level-item">
                     <ButtonModal
                        buttonClassName="is-default form-wizard-buttons is-primary"
                        buttonLabel="pages.shared.orders.partecipants.add"
                        render={({handleClose}) => (
                           <Fragment>
                              <header className="modal-card-head">
                                 <p className="modal-card-title">
                                    <Translated k="pages.shared.orders.partecipants.add"/>
                                 </p>
                                 <button type="button" className="delete" aria-label="close" onClick={handleClose}/>
                              </header>
                              <OrderPartecipantEditForm
                                 onSuccess={(p) => {
                                    this.addPartecipant(p, handleClose);
                                 }}
                                 onCancel={handleClose}
                              />
                           </Fragment>
                        )}
                     />
                  </div>
                  <div className="level-item">
                     <ButtonModal
                        buttonClassName="is-default form-wizard-buttons is-primary"
                        buttonLabel="pages.shared.orders.partecipants.addExistent"
                        render={({handleClose, impersonatedUser}) => (
                           <Fragment>
                              <header className="modal-card-head">
                                 <p className="modal-card-title">
                                    <Translated k="pages.shared.orders.partecipants.addExistent"/>
                                 </p>
                                 <button type="button" className="delete" aria-label="close" onClick={handleClose}/>
                              </header>
                              <OrderPartecipantChooseForm
                                 onSuccess={(p) => {
                                    this.addPartecipant(p, handleClose);
                                 }}
                                 onCancel={handleClose}
                                 impersonateUser={this.props.impersonatedUser}
                              />
                           </Fragment>
                        )}
                     />
                  </div>
               </div>
            </div>
            <OrderStepsNavigation
               showRight
               onClickRight={this.submitAndNext}
               disabledRight={order.items[0].partecipants.length <= 0}
               loading={loading}
               submitting={loading}
            />
         </div>
      );
   }
}

export default OrderPartecipantsForm;
