import React from 'react';
import Axios from 'axios';
import { Formik } from 'formik';
import OrderStepsNavigation from '../OrderStepsNavigation';
import Title from '../../../base/elements/Title';
import ErrorNotification from '../../../base/elements/ErrorNotification';
import appUrl from '../../../utils/remote';
import { PeopleFormFields, MandatoryPeopleSchema } from '../../PeopleForm';
import { UnsafeTranslated } from '../../../base/elements/Translated';

const OrderBillingDetailsForm = ({
  initialValues = {}, onSuccess, requestOptions, onCancel, order,
}) => (
  <Formik
    initialValues={MandatoryPeopleSchema.cast(initialValues)}
    validationSchema={MandatoryPeopleSchema}
    onSubmit={async (data, actions) => {
      try {
        const request = {
          ...order,
          billingHolder: data,
        };
        const result = await Axios.put(appUrl(`/fo/orders/${request.id}`), request, requestOptions);
        onSuccess(result.data);
      } catch (err) {
        actions.setErrors({ failed: (err.response !== undefined) ? `${err.response.data.message}` : err.message });
      }
      actions.setSubmitting(false);
    }}
    render={({
      dirty, isSubmitting, handleSubmit, setErrors, ...others
    }) => (
      <form onSubmit={handleSubmit}>
        <OrderStepsNavigation
          renderCenter={() => <Title info size={4} centered label="pages.shared.orders.steps.5" />}
          onClickLeft={onCancel}
          showLeft
          showRight
        />
        <div className="notification is-info is-light media">
          <i className="media-left fas fa-info-circle" />
          <div className="media-content">
            <UnsafeTranslated k="pages.shared.orders.steps.description.5" />
          </div>
        </div>
        <ErrorNotification clearError={() => setErrors({ failed: null })} {...others} />
        <PeopleFormFields mandatoryPeopleFields {...others} />

        <OrderStepsNavigation
          onClickLeft={onCancel}
          showLeft
          showRight
        />
      </form>
    )}
  />
);

export default OrderBillingDetailsForm;
