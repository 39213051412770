import React, {Fragment} from 'react';
import * as Yup from 'yup';
import {FieldArray} from 'formik';
import uuid from 'uuid';
import Translated from '../base/elements/Translated';
import InputText from '../base/form/InputText';
import InputBoolean from '../base/form/InputBoolean';
import Icon from '../base/elements/Icon';
import priceSchema from './utils/priceSchema';
import MoneyInput from '../base/elements/MoneyInput';
import RemoteInputSelect from "../base/form/RemoteInputSelect";

export const EventPackageOptionSchema = Yup.object().shape({
  name: Yup.string()
    .required('general.validation.required')
    .default(''),
  totalAmount: priceSchema,
  vat: Yup.object().shape({
    value: Yup.number()
      .typeError('general.validation.number')
      .required('general.validation.required')
      .default(0),
    description: Yup.string().default(''),
  }),
  onSitePayment: Yup.boolean()
    .default(false),
});

const EventPackageOptionSubForm = ({ index, values, ...others }) => (
  <FieldArray
    name={`packages.${index}.options`}
    render={arrayHelpers => (
      <div>
        {values.packages[index].options.map((p, i) => (
          <Fragment key={p.uuid}>
            <div>
              <div className="columns compact is-vcentered">
                <div className="column">
                  <InputText
                    values={values}
                    name={`packages.${index}.options.${i}.name`}
                    label="model.eventPackageOption.name"
                    disableAutoComplete
                    small
                    {...others}
                  />
                </div>
                <div className="column">
                  <MoneyInput
                    values={values}
                    name={`packages.${index}.options.${i}.totalAmount.value`}
                    label="model.eventPackageOption.price"
                    leftLabel="€"
                    disableAutoComplete
                    small
                    {...others}
                  />
                </div>
                <div className="column">
                  <InputBoolean
                    values={values}
                    name={`packages.${index}.options.${i}.onSitePayment`}
                    label="model.eventPackageOption.onSitePayment"
                    small
                    {...others}
                  />
                </div>
                <div className="column">
                  <button
                    type="button"
                    className="button is-small is-danger"
                    onClick={() => arrayHelpers.remove(i)}
                  >
                    <Icon name="fa-trash" />
                  </button>
                </div>
              </div>
            </div>
            <div className="columns compact">
              <div className="column is-one-third">
                <MoneyInput
                  values={values}
                  name={`packages.${index}.options.${i}.vat.value`}
                  label="model.vat.entityName"
                  leftLabel="%"
                  disableAutoComplete
                  small
                  {...others}
                />
              </div>
              <div className="column is-one-third">
                <RemoteInputSelect
                    values={values}
                    name={`packages.${index}.options.${i}.vat.description`}
                    label="model.vat.description"
                    url="/events/vatExemptions"
                    small
                    {...others}
                />
              </div>
            </div>
            <hr className="compact" />
          </Fragment>
        ))}
        <button
          type="button"
          className="button is-small"
          onClick={() => arrayHelpers.push(EventPackageOptionSchema.cast({ uuid: uuid() }))}
        >
          <Translated k="pages.back.eventPackageOptions.add" />
        </button>
      </div>
    )}
  />
);

export default EventPackageOptionSubForm;
