import React, { Fragment } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import uuid from 'uuid';
import ErrorNotification from '../../../base/elements/ErrorNotification';
import InputText from '../../../base/form/InputText';
import InputTextarea from '../../../base/form/InputTextarea';
import Translated from '../../../base/elements/Translated';
import SubmitButton from '../../../base/form/SubmitButton';

const OrderContactSchema = Yup.object().shape({
  name: Yup.string()
    .required('general.validation.required')
    .default(''),
  phone: Yup.string()
    .required('general.validation.required')
    .default(''),
  notes: Yup.string().default(''),
});

const OrderContactEditForm = ({ onSuccess, onCancel }) => (
  <Formik
    initialValues={OrderContactSchema.cast({ uuid: uuid() })}
    validationSchema={OrderContactSchema}
    onSubmit={(data, actions) => {
      onSuccess(data);
      actions.setSubmitting(false);
    }}
    render={({
      dirty, isSubmitting, handleSubmit, setErrors, ...others
    }) => (
      <Fragment>
        <header className="modal-card-head">
          <p className="modal-card-title">
            <Translated k="pages.back.emergency.title" />
          </p>
          <button type="button" className="delete" aria-label="close" onClick={onCancel} />
        </header>
        <form onSubmit={handleSubmit}>
          <section className="modal-card-body">
            <ErrorNotification clearError={() => setErrors({ failed: null })} {...others} />
            <div className="columns compact">
              <div className="column">
                <InputText name="name" label="model.contact.name" disableAutoComplete {...others} />
              </div>
              <div className="column">
                <InputText
                  name="phone"
                  label="model.contact.phone"
                  disableAutoComplete
                  {...others}
                />
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <InputTextarea
                  name="notes"
                  label="model.contact.notes"
                  disableAutoComplete
                  {...others}
                />
              </div>
            </div>
          </section>
          <footer className="modal-card-foot" style={{ justifyContent: 'flex-end' }}>
            <SubmitButton dirty={dirty} isSubmitting={isSubmitting} />
            <button type="button" className="button" onClick={onCancel}>
              <Translated k="general.close" />
            </button>
          </footer>
        </form>
      </Fragment>
    )}
  />
);

export default OrderContactEditForm;
