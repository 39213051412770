import React, { Fragment } from 'react';
import WithRemoteData from '../../../utils/WithRemoteData';
import Translated from '../../../base/elements/Translated';
import ColumnOutput from '../../../base/elements/ColumnOutput';
import ButtonModal from '../../../base/components/modal/ButtonModal';
import EventEdit from './EventEdit';
import EventPackageDetails from './EventPackageDetails';
import Title from '../../../base/elements/Title';
import BooleanOutput from '../../../base/elements/BooleanOutput';
import MoneyOutput from '../../../base/elements/MoneyOutput';

const EventDetails = ({ resourceId, handleClose }) => (
  <WithRemoteData
    url={`/events/${resourceId}`}
    render={({ loading, data, refresh }) => loading === false
      && data !== null && (

        <Fragment>
          <header className="modal-card-head">
            <p className="modal-card-title">
              <Translated k="pages.back.events.details" />
            </p>
            <button type="button" className="delete" aria-label="close" onClick={handleClose} />
          </header>
          <section className="modal-card-body">
            <div className="columns is-vcentered">
              <ColumnOutput label="model.event.name" render={() => data.name} />
              <ColumnOutput label="model.eventType.entityName" render={() => data.type.name} />
            </div>
            <div className="columns is-vcentered">
              <ColumnOutput label="model.event.multiplePackageDiscount" render={() => <MoneyOutput money={data.multiplePackageDiscount} />} />
              <ColumnOutput label="model.event.bookable" render={() => <BooleanOutput value={data.bookable} />} />
            </div>
            <hr />
            <div className="level">
              <Title size={5} label="model.eventPackage.entityName" />
            </div>
            {data.packages.map(p => <EventPackageDetails key={p.id} data={p} />)}
          </section>
          <footer className="modal-card-foot" style={{ justifyContent: 'flex-end' }}>
            <ButtonModal
              buttonClassName="is-primary"
              buttonLabel="general.edit"
              render={innerModalData => (
                <EventEdit
                  resourceId={resourceId}
                  handleClose={innerModalData.handleClose}
                  handleSuccess={refresh}
                />
              )}
            />
            <button type="button" className="button" onClick={handleClose}>
              <Translated k="general.close" />
            </button>
          </footer>
        </Fragment>
    )
    }
  />
);

export default EventDetails;
