import React from 'react';
import moment from 'moment';
import Translated from '../../../base/elements/Translated';
import FilterForm from '../../../forms/FilterForm';
import InputText from '../../../base/form/InputText';
import InputSelect from '../../../base/form/InputSelect';
import IconModal from '../../../base/components/modal/IconModal';
import ButtonModal from '../../../base/components/modal/ButtonModal';
import Pagination from '../../../base/components/pagination/Pagination';
import EventAdd from './EventAdd';
import EventDetails from './EventDetails';
import EventEdit from './EventEdit';
import EventDelete from './EventDelete';
import RemoteEntityInputSelect from '../../../base/form/RemoteEntityInputSelect';
import OrderableColumn from '../../../base/elements/OrderableColumn';
import CrudList from '../../../utils/CrudList';
import Title from '../../../base/elements/Title';
import EventDuplicate from './EventDuplicate';

const EventList = () => (
    <CrudList
        initialSorting={{sort: 'NAME', asc: true}}
        initialFilter={{fullSearch: '', typeId: null, maxResults: 10}}
        restUrl="/events"
        render={({
                     loading,
                     data,
                     error,
                     params,
                     filters,
                     initialFilter,
                     handleNavigation,
                     changeOrdering,
                     updateFilters,
                     refreshResults,
                     removeEntity,
                 }) => (
            <div className="section main-page">
                <div className="container">
                    <div className="level">
                        <div className="level-left">
                            <Title size={3} label="model.event.entityNamePlural"/>
                        </div>
                        <div className="level-right">
                            <ButtonModal
                                buttonClassName="is-primary"
                                buttonLabel="pages.back.events.add"
                                render={({handleClose}) => (
                                    <EventAdd handleClose={handleClose} handleSuccess={refreshResults}/>
                                )}
                            />
                        </div>
                    </div>
                    <div className="section-title">
                        <FilterForm
                            onSubmit={updateFilters}
                            initialValues={initialFilter}
                            render={({...props}) => (
                                <div className="columns">
                                    <div className="column is-two-fifths">
                                        <InputText
                                            name="fullSearch"
                                            label="general.filters.fulltextSearch"
                                            {...props}
                                            disableAutoComplete
                                        />
                                    </div>
                                    <div className="column">
                                        <InputText
                                            name="year"
                                            label="general.filters.year"
                                            {...props}
                                            disableAutoComplete
                                        />
                                    </div>
                                    <div className="column">
                                        <RemoteEntityInputSelect
                                            name="typeId"
                                            label="model.eventType.entityName"
                                            url="/eventTypes"
                                            isClearable
                                            {...props}
                                        />
                                    </div>
                                    <div className="column">
                                        <InputSelect
                                            name="maxResults"
                                            label="general.filters.numOfResults"
                                            options={[
                                                {value: 10, label: 10},
                                                {value: 20, label: 20},
                                                {value: 50, label: 50},
                                            ]}
                                            isSearchable={false}
                                            {...props}
                                        />
                                    </div>
                                </div>
                            )}
                        />
                    </div>
                    {loading === false && error && <div className="notification is-danger">{error}</div>}
                    <div className="table-container">
                        {loading === true && <div className="loading"/>}
                        <table className="table is-hoverable is-bordered is-fullwidth">
                            <thead className="has-background-white-ter">
                            <tr>
                                <OrderableColumn name="NAME" handleClick={changeOrdering} {...params}>
                                    <Translated k="model.event.name"/>
                                </OrderableColumn>
                                <OrderableColumn name="TYPE" handleClick={changeOrdering} {...params}>
                                    <Translated k="model.eventType.entityName"/>
                                </OrderableColumn>
                                <th>
                                    <Translated k="model.event.validity"/>
                                </th>
                                <th>
                                    <Translated k="model.event.sortIndex"/>
                                </th>
                                <th>
                                    <Translated k="general.actions"/>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {data
                            && data.elements.map(e => (
                                <tr key={e.id}>
                                    <td>{e.name}</td>
                                    <td>{e.type.name}</td>
                                    <td>
                                        {moment(e.startDate).format('YYYY')}
                                        {'-'}
                                        {moment(e.endDate).format('YYYY')}
                                    </td>
                                    <td>{e.sortIndex}</td>
                                    <td>
                                        <div className="buttons">
                                            <IconModal
                                                icon="fa-search"
                                                tooltipText="general.view"
                                                render={({handleClose}) => (
                                                    <EventDetails
                                                        handleClose={handleClose}
                                                        handleSuccess={refreshResults}
                                                        resourceId={e.id}
                                                    />
                                                )}
                                            />
                                            <IconModal
                                                icon="fa-pen"
                                                tooltipText="general.edit"
                                                render={({handleClose}) => (
                                                    <EventEdit
                                                        handleClose={handleClose}
                                                        handleSuccess={refreshResults}
                                                        resourceId={e.id}
                                                    />
                                                )}
                                            />
                                            <IconModal
                                                icon="fa-trash-alt"
                                                tooltipText="general.delete"
                                                render={({handleClose}) => (
                                                    <EventDelete
                                                        name={e.name}
                                                        handleConfirm={() => {
                                                            removeEntity(e.id);
                                                            handleClose();
                                                        }}
                                                        handleClose={handleClose}
                                                    />
                                                )}
                                            />
                                            <IconModal
                                                icon="fa-copy"
                                                tooltipText="general.duplicate"
                                                render={({handleClose}) => (
                                                    <EventDuplicate
                                                        handleClose={handleClose}
                                                        handleSuccess={refreshResults}
                                                        resourceId={e.id}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                    <Pagination
                        loading={loading}
                        data={data}
                        startPosition={params.startPosition}
                        maxResults={filters.maxResults}
                        handleNavigation={handleNavigation}
                    />
                </div>
            </div>
        )}
    />
);

export default EventList;
