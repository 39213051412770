import React, { Component } from 'react';
import Axios from 'axios';
import { Formik } from 'formik';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import classNames from 'classnames';
import queryString from 'query-string';
import ReCAPTCHA from 'react-google-recaptcha';
import InputText from '../base/form/InputText';
import ErrorNotification from '../base/elements/ErrorNotification';
import appUrl from '../utils/remote';
import InputPassword from '../base/form/InputPassword';
import constants from '../utils/constants';
import Translated, { UnsafeTranslated } from '../base/elements/Translated';
import InputRadio from '../base/form/InputRadio';

const SignupSchema = Yup.object().shape({
  firstName: Yup.string().required('general.validation.required'),
  lastName: Yup.string().required('general.validation.required'),
  email: Yup.string()
    .email('general.validation.email')
    .required('general.validation.required')
    .default(''),
  newPassword: Yup.string()
    .min(constants.passwordLength, 'general.validation.password.length')
    .required('general.validation.required')
    .default(''),
  newPasswordRepeat: Yup.string()
    .min(constants.passwordLength, 'general.validation.password.length')
    .oneOf([Yup.ref('newPassword')], 'general.validation.password.match')
    .required('general.validation.required')
    .default(''),
  personalDataTreatment: Yup.string()
    .required('general.validation.required')
    .oneOf(['true'], 'general.privacy.error'),
  sensitiveDataTreatment: Yup.string()
    .required('general.validation.required')
    .oneOf(['true'], 'general.privacy.error'),
  newsletterConsent: Yup.string()
    .required('general.validation.required'),
});


class SignupForm extends Component {
  state = {
    token: null,
  };


  onChange = (gRecaptchaResponse) => {
    this.setState({ token: gRecaptchaResponse });
  };

  render() {
    const { location, onSuccess, recaptchaKey } = this.props;
    const { token } = this.state;

    return (
      <Formik
        initialValues={SignupSchema.cast({})}
        validationSchema={SignupSchema}
        onSubmit={async (data, actions) => {
          const { locale } = queryString.parse(location.search);
          const {
            email, firstName, lastName, newPassword, newsletterConsent,
          } = data;

          const lcl = locale !== undefined ? locale : 'it-IT';

          try {
            const user = {
              firstName,
              lastName,
              locale: lcl,
              email,
              newsletterConsent,
              password: newPassword,
            };

            await Axios.post(appUrl(`/fo/users/registration?token=${token}`), user);
            onSuccess();
          } catch (err) {
            actions.setErrors({
              failed:
                err.response !== undefined
                  ? `${err.response.data.message}`
                  : err.message,
            });
          }
          actions.setSubmitting(false);
        }}
        render={({
          dirty, isSubmitting, handleSubmit, setErrors, ...others
        }) => (
          <form onSubmit={handleSubmit}>
            <ErrorNotification clearError={() => setErrors({ failed: null })} {...others} />
            <div className="columns">
              <div className="column">
                <InputText name="firstName" autofocus label="model.person.firstName" {...others} />
              </div>
              <div className="column">
                <InputText name="lastName" autofocus label="model.person.lastName" {...others} />
              </div>
              <div className="column">
                <InputText name="email" autofocus label="model.person.email" {...others} />
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <InputPassword name="newPassword" label="pages.auth.newPassword" {...others} />
              </div>
              <div className="column">
                <InputPassword
                  name="newPasswordRepeat"
                  label="pages.auth.passwordRepeat"
                  {...others}
                />
              </div>
            </div>
            <div className="columns">
              <div className="column is-full">
                <UnsafeTranslated k="general.privacy.text" />
              </div>
            </div>
            <div className="columns">
              <label className="column label">
                <Translated k="general.privacy.personalDataTreatment" />
              </label>
              <div className="column">
                <InputRadio
                  name="personalDataTreatment"
                  options={[{ label: 'general.privacy.agree', value: 'true' }, { label: 'general.privacy.doNotAgree', value: 'false' }]}
                  {...others}
                />
              </div>
            </div>
            <div className="columns">
              <label className="column label">
                <Translated k="general.privacy.sensitiveDataTreatment" />
              </label>
              <div className="column">
                <InputRadio
                  name="sensitiveDataTreatment"
                  options={[{ label: 'general.privacy.agree', value: 'true' }, { label: 'general.privacy.doNotAgree', value: 'false' }]}
                  {...others}
                />
              </div>
            </div>
            <div className="columns">
              <label className="column label">
                <Translated k="general.privacy.newsletterConsent" />
              </label>
              <div className="column">
                <InputRadio
                  name="newsletterConsent"
                  options={[{ label: 'general.privacy.agree', value: 'true' }, { label: 'general.privacy.doNotAgree', value: 'false' }]}
                  {...others}
                />
              </div>
            </div>

            <div className="columns is-centered">
              <ReCAPTCHA
                // sitekey="6Lfj8JIUAAAAAA5XEuVE-d16HRo9Xljr7uQDGGG4"
                sitekey={recaptchaKey}
                onChange={this.onChange}
                hl={queryString.parse(location.search).locale || 'it-IT'}
              />
            </div>

            <section className="section">
              <div className="columns is-centered">
                <button
                  type="submit"
                  className={classNames('button is-primary ', {
                    'is-loading': isSubmitting,
                  })}
                  disabled={!dirty || isSubmitting || token === null}
                >
                  <Translated k="pages.auth.signUp" />
                </button>
              </div>
            </section>
          </form>
        )}
      />
    );
  }
}

export default withRouter(SignupForm);
