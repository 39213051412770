import React, { Component } from 'react';
import Axios from 'axios';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';
import Steps from '../../../base/components/steps/Steps';
import Translated, { UnsafeTranslated } from '../../../base/elements/Translated';
import OrderPartecipantsForm from '../../../forms/orders/partecipants/OrderPartecipantsForm';
import OrderPeopleForm from '../../../forms/orders/people/OrderPeopleForm';
import OrderPartecipationsForm from '../../../forms/orders/partecipations/OrderPartecipationsForm';
import OrderContactsForm from '../../../forms/orders/contacts/OrderContactsForm';
import OrderBillingDetailsForm from '../../../forms/orders/billing/OrderBillingDetailsForm';
import appUrl from '../../../utils/remote';
import ErrorNotification from '../../../base/elements/ErrorNotification';
import OrderPaymentMethodsForm from '../../../forms/orders/paymentMethods/OrderPaymentMethodsForm';

const steps = [
  { id: 1, iconName: 'fa-users' },
  { id: 2, iconName: 'fa-scroll' },
  { id: 3, iconName: 'fa-user-tag' },
  { id: 4, iconName: 'fa-phone' },
  { id: 5, iconName: 'fa-file-invoice' },
  { id: 6, iconName: 'fa-euro-sign' },
];

class OrderEditSteps extends Component {
  state = {
    step: 1,
    maxStep: 1,
    totalSteps: 6,
    data: null,
    loading: true,
    error: null,
  };

  componentDidMount() {
    this.load();
  }

  getRequestOptions = () => {
    const { impersonatedUser } = this.props;

    return impersonatedUser
      ? {
        headers: {
          'x-impersonate-user': impersonatedUser,
        },
      }
      : {};
  };

  load = async () => {
    const { id } = this.props;

    try {
      const { data } = await Axios.get(appUrl(`/fo/orders/${id}`), this.getRequestOptions());
      this.setState({
        data,
        loading: false,
      });
    } catch (err) {
      this.setState({
        error: err.response !== undefined ? `${err.response.data.message}` : err.message,
        loading: false,
      });
    }
  };

  onStepSuccess = (data) => {
    this.setState({ data }, this.goToNext);
  };

  goToPrevious = () => {
    const { step } = this.state;

    this.changeStep(step - 1);
  };

  goToNext = () => {
    const { step } = this.state;

    this.changeStep(step + 1);
  };

  changeStep = (id) => {
    const { maxStep, totalSteps } = this.state;

    if (id > totalSteps || id < 1) {
      return;
    }
    if (id > maxStep + 1) {
      return;
    }

    this.setState({ step: id, maxStep: id });
  };

  render() {
    const { isInsideModal, history, impersonatedUser } = this.props;
    const {
      step, maxStep, totalSteps, data, loading, error,
    } = this.state;

    const options = this.getRequestOptions();

    return (
      <div
        className={classNames({
          'section main-page': isInsideModal !== true,
        })}
      >
        {loading === false && error && <ErrorNotification errors={{ failed: error }} />}
        {loading === false && data && data.orderStatus === 'IN_PROGRESS' ? (
          <div className="container">
            <div className="is-size-3 section-title">
              <UnsafeTranslated k="pages.shared.orders.title" p={data.items[0].eventType.name} />
            </div>
            <Steps
              steps={steps}
              activeId={step}
              reachableId={maxStep}
              handleClick={this.changeStep}
              totalSteps={totalSteps}
            />
            <div>
              {step === 1 && (
                <OrderPartecipantsForm
                  initialValues={data}
                  resourceId={data.id}
                  onSuccess={this.onStepSuccess}
                  onCancel={this.goToPrevious}
                  requestOptions={options}
                  impersonatedUser={impersonatedUser}
                />
              )}
              {step === 2 && (
                <OrderPartecipationsForm
                  initialValues={data}
                  resourceId={data.id}
                  onSuccess={this.onStepSuccess}
                  onCancel={this.goToPrevious}
                  requestOptions={options}
                  navigation
                />
              )}
              {step === 3 && (
                <OrderPeopleForm
                  initialValues={data}
                  resourceId={data.id}
                  onSuccess={this.onStepSuccess}
                  onCancel={this.goToPrevious}
                  requestOptions={options}
                />
              )}
              {step === 4 && (
                <OrderContactsForm
                  initialValues={data}
                  resourceId={data.id}
                  onSuccess={this.onStepSuccess}
                  onCancel={this.goToPrevious}
                  requestOptions={options}
                />
              )}
              {step === 5 && (
                <OrderBillingDetailsForm
                  initialValues={
                    data.billingHolder === undefined ? data.author.person : data.billingHolder
                  }
                  order={data}
                  onSuccess={this.onStepSuccess}
                  onCancel={this.goToPrevious}
                  requestOptions={options}
                />
              )}
              {step === 6 && (
                <OrderPaymentMethodsForm
                  initialValues={data}
                  resourceId={data.id}
                  onSuccess={() => history.push('/complete')}
                  onCancel={this.goToPrevious}
                  requestOptions={options}
                  getRequestOptions={this.getRequestOptions}
                />
              )}
            </div>
          </div>
        ) : (
          <div>
            {data && (data.orderStatus === 'CANCELED' || data.orderStatus === 'COMPLETED') && (
              <div className="notification is-danger has-text-centered">
                <Translated k="pages.shared.orders.statusError" />
              </div>
            )}
          </div>
        )}
      </div>
    );
  }
}

export default withRouter(OrderEditSteps);
