/* eslint
    jsx-a11y/anchor-is-valid: "off",
    jsx-a11y/click-events-have-key-events: "off",
    react/no-array-index-key: "off"
*/

import React, {Component, Fragment} from 'react';
import {NavLink} from 'react-router-dom';
import {AppContext} from '../../../App';
import ChangePasswordFormBack from '../../../forms/ChangePasswordFormBack';
import Translated from '../../elements/Translated';
import ModalStateless from './ModalStateless';
import LinkModal from '../modal/LinkModal';
import ModifyUserData from '../../../pages/front/ModifyUserData';
import Icon from '../../elements/Icon';

class Account extends Component {
  state = {
    visible: false,
  };

  toggleModal = () => {
    const { visible } = this.state;
    this.setState({ visible: !visible });
  };

  render() {
    const { visible } = this.state;

    return (
      <Fragment>
        <AppContext.Consumer>
          {({ getUsername, isBackOffice }) => (
            <Fragment>

              <a href="#" className="navbar-item" onClick={this.toggleModal}>
                <Translated k="model.user.passwordChange" />
              </a>
              { !isBackOffice() && (
              <LinkModal
                linkLabel="pages.front.account.changeData"
                linkClassName="navbar-item"
                render={({ handleClose }) => (
                  <ModifyUserData
                    handleClose={handleClose}
                  />
                )}
              />
              )}
              <NavLink to="/logout" className="navbar-item">
                  Logout
              </NavLink>
              { isBackOffice() && (
              <div className="navbar-item has-dropdown is-hoverable">
                <a className="navbar-link">
                  <Translated k="general.navbar.addOptions"/>
                </a>

                <div className="navbar-dropdown">
                  <a href="/bankAccounts" className="navbar-item">
                    <Translated k="general.navbar.bankAccounts"/>
                  </a>
                </div>
              </div>
              ) }
              <span className="navbar-item is-size-6 has-text-black-bis">
                <Icon name="fa-user-circle" />
                <span className="is-size-7">{getUsername()}</span>
              </span>
              <ModalStateless
                visible={visible}
                handleClose={this.toggleModal}
                render={({ handleClose }) => (
                  <ChangePasswordFormBack handleClose={handleClose} onSuccess={handleClose} />
                )}
                card
              />
            </Fragment>
          )}
        </AppContext.Consumer>
      </Fragment>
    );
  }
}

export default Account;
