import * as Yup from 'yup';
import moment from 'moment';

class MomentSchema extends Yup.mixed {
  constructor() {
    super({ type: 'momentschema' });
    this.transforms.push((value) => {
      if (value === null || value === undefined || value === '') {
        return value;
      }

      if (this.isType(value)) {
        return value;
      }

      const casted = moment(value);
      return casted.isValid() ? casted : moment.invalid();
    });
  }

  _typeCheck = value => moment.isMoment(value) && value.isValid();
}

const momentDate = () => new MomentSchema().nullable();

export default momentDate;
