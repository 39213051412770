import React from 'react';
import Axios from 'axios';
import { Formik } from 'formik';
import * as Yup from 'yup';
import appUrl from '../utils/remote';
import ErrorNotification from '../base/elements/ErrorNotification';
import InputText from '../base/form/InputText';
import Translated from '../base/elements/Translated';
import RemoteEntityInputSelect from '../base/form/RemoteEntityInputSelect';
import EventPackageSubForm, { EventPackageSchema } from './EventPackageSubForm';
import Title from '../base/elements/Title';
import SubmitButton from '../base/form/SubmitButton';
import InputBoolean from '../base/form/InputBoolean';
import MoneyInput from '../base/elements/MoneyInput';
import priceSchema from './utils/priceSchema';
import InputNumber from "../base/form/InputNumber";

const EventSchema = Yup.object().shape({
  name: Yup.string()
    .required('general.validation.required')
    .default(''),
  type: Yup.object().shape({
    id: Yup.number().required('general.validation.required'),
  }),
  packages: Yup.array()
    .of(EventPackageSchema)
    .default([]),
  bookable: Yup.boolean().default(true),
  multiplePackageDiscount: priceSchema,
});

const validateDateRange = (values) => {
  const errors = {};

  values.packages.forEach((p, i) => {
    if (p.startDate && p.endDate && p.startDate.isSameOrAfter(p.endDate)) {
      if (!errors.packages) {
        errors.packages = [];
      }
      if (!errors.packages[i]) {
        errors.packages[i] = {};
      }
      errors.packages[i].startDate = 'general.validation.dateStart';
      errors.packages[i].endDate = 'general.validation.dateEnd';
    }
  });

  return errors;
};

const EventTypeForm = ({
  onSuccess, onCancel, resourceId, initialValues = {},
}) => (
  <Formik
    initialValues={EventSchema.cast(initialValues)}
    validationSchema={EventSchema}
    validate={validateDateRange}
    onSubmit={async (data, actions) => {
      try {
        if (resourceId) {
          await Axios.put(appUrl(`/events/${resourceId}`), { ...data });
        } else {
          await Axios.post(appUrl('/events'), { ...data });
        }
        onSuccess();
      } catch (err) {
        actions.setErrors({ failed: (err.response !== undefined) ? `${err.response.data.message}` : err.message });
      }
      actions.setSubmitting(false);
    }}
    render={({
      dirty, isSubmitting, handleSubmit, setErrors, ...others
    }) => (
      <form onSubmit={handleSubmit}>
        <section className="modal-card-body">
          <ErrorNotification clearError={() => setErrors({ failed: null })} {...others} />
          <div className="columns">
            <div className="column">
              <InputText name="name" label="model.event.name" disableAutoComplete {...others} />
            </div>
            <div className="column">
              <RemoteEntityInputSelect
                name="type.id"
                label="model.eventType.entityName"
                url="/eventTypes"
                {...others}
              />
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <MoneyInput
                name="multiplePackageDiscount.value"
                label="model.event.multiplePackageDiscount"
                leftLabel="€"
                disableAutoComplete
                {...others}
              />
            </div>
            <div className="column">
              <InputNumber
                  name="sortIndex"
                  label="model.event.sortIndex"
                  disableAutoComplete
                  {...others}
              />
            </div>
            <div className="column">
              <InputBoolean
                name="bookable"
                label="model.event.bookable"
                {...others}
              />
            </div>
          </div>
          <hr />
          <div className="level">
            <Title size={5} label="model.eventPackage.entityName" />
          </div>
          <EventPackageSubForm {...others} />
        </section>
        <footer className="modal-card-foot" style={{ justifyContent: 'flex-end' }}>
          <SubmitButton isSubmitting={isSubmitting} />
          <button type="button" className="button" onClick={onCancel}>
            <Translated k="general.close" />
          </button>
        </footer>
      </form>
    )}
  />
);

export default EventTypeForm;
