import React from 'react';
import Modal from './Modal';
import { AppContext } from '../../../App';

const IconModal = ({ icon, tooltipText, ...others }) => (
  <AppContext.Consumer>
    {({ translate }) => (
      <Modal
        renderTrigger={({ handleOpen }) => (
          <button
            type="button"
            className="button tooltip"
            data-tooltip={translate(tooltipText)}
            onClick={handleOpen}
          >
            <span className="icon">
              <i className={`fas ${icon}`} />
            </span>
          </button>
        )}
        {...others}
      />
    )}
  </AppContext.Consumer>
);

export default IconModal;
