import React, {Component, Fragment} from 'react';
import Axios from 'axios';
import appUrl from '../../../../utils/remote';
import InvoiceForm from '../../../../forms/orders/invoices/InvoiceForm';
import ErrorNotification from '../../../../base/elements/ErrorNotification';

class InvoiceAdd extends Component {
  state = {
    data: null,
    error: null,
  };

  componentDidMount() {
    this.load();
  }

  load = async () => {
    const { order, depositAmount } = this.props;

    try {
      let url = `/orders/${order.id}/invoices/defaultInvoice`;
      if (depositAmount) {
        url += `?depositAmount=${depositAmount}`
      }

      const { data } = await Axios.get(appUrl(url));
      this.setState({
        data,
      });
    } catch (err) {
      this.setState({
        error: err.response !== undefined ? `${err.response.data.message}` : err.message,
      });
    }
  };

  updateTotal = async (updatedData) => {
    this.setState({
      data: updatedData,
    });
  };

  render() {
    const { handleSuccess, handleClose, order } = this.props;

    const { data, error } = this.state;

    if (data !== null) {
      const { invoiceResidue, invoiceTotal, ...others } = data;

      return (
        <Fragment>
          {error && (
            <ErrorNotification
              clearError={() => this.setState({ error: null })}
              errors={{ failed: error }}
            />
          )}
          <InvoiceForm
            onCancel={handleClose}
            onSuccess={() => {
              handleSuccess();
              handleClose();
            }}
            initialValues={{ ...others }}
            resourceId={order.id}
            handleUpdateTotal={this.updateTotal}
            invoiceResidue={order.invoiceResidue}
            invoiceTotal={invoiceTotal}
            handleClose={handleClose}
          />
        </Fragment>
      );
    }
    return <div />;
  }
}

export default InvoiceAdd;
