/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/interactive-supports-focus */
import React from 'react';
import Modal from './Modal';
import Translated from '../../elements/Translated';

const LinkModal = ({ linkClassName, linkLabel, ...others }) => (
  <Modal
    renderTrigger={({ handleOpen }) => (
      <a role="button" className={`${linkClassName}`} onClick={handleOpen}>
        <Translated k={linkLabel} />
      </a>
    )}
    {...others}
  />
);

export default LinkModal;
