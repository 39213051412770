import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import uuid from 'uuid';
import ErrorNotification from '../../../base/elements/ErrorNotification';
import InputText from '../../../base/form/InputText';
import Translated from '../../../base/elements/Translated';
import SubmitButton from '../../../base/form/SubmitButton';

const PartecipantSchema = Yup.object().shape({
  relationship: Yup.string()
    .required('general.validation.required')
    .default(''),
  person: Yup.object().shape({
    firstName: Yup.string()
      .required('general.validation.required')
      .default(''),
    lastName: Yup.string()
      .required('general.validation.required')
      .default(''),
    personType: Yup.string().default('PHYSICAL'),
  }),
});

const OrderPartecipantEditForm = ({ onSuccess, onCancel }) => (
  <Formik
    initialValues={PartecipantSchema.cast({ uuid: uuid() })}
    validationSchema={PartecipantSchema}
    onSubmit={(data, actions) => {
      onSuccess(data);
      actions.setSubmitting(false);
    }}
    render={({
      dirty, isSubmitting, handleSubmit, setErrors, ...others
    }) => (
      <form onSubmit={handleSubmit}>
        <section className="modal-card-body">
          <ErrorNotification clearError={() => setErrors({ failed: null })} {...others} />
          <div className="columns">
            <div className="column">
              <InputText
                name="person.firstName"
                label="model.person.firstName"
                disableAutoComplete
                {...others}
              />
            </div>
            <div className="column">
              <InputText
                name="person.lastName"
                label="model.person.lastName"
                disableAutoComplete
                {...others}
              />
            </div>
            <div className="column">
              <InputText
                name="relationship"
                label="model.partecipant.relationship"
                disableAutoComplete
                {...others}
              />
            </div>
          </div>
        </section>
        <footer className="modal-card-foot" style={{ justifyContent: 'flex-end' }}>
          <SubmitButton dirty={dirty} isSubmitting={isSubmitting} />
          <button type="button" className="button" disabled={isSubmitting} onClick={onCancel}>
            <Translated k="general.close" />
          </button>
        </footer>
      </form>
    )}
  />
);

export default OrderPartecipantEditForm;
