import React, { Fragment } from 'react';
import InputText from '../../../base/form/InputText';
import InputDate from '../../../base/form/InputDate';
import InputSelect from '../../../base/form/InputSelect';
import InputTag from '../../../base/form/InputTag';
import RemoteInputSelect from '../../../base/form/RemoteInputSelect';
import InputTextarea from '../../../base/form/InputTextarea';
import Translated from '../../../base/elements/Translated';
import InputRadio from '../../../base/form/InputRadio';

const PartecipantFormFields = ({
  baseName = '', setFieldValue, idx, ...others
}) => (
  <div>
    <div className="columns">
      <div className="column">
        <InputText
          name={`${baseName}firstName`}
          label="model.person.firstName"
          disableAutoComplete
          setFieldValue={setFieldValue}
          required
          {...others}
        />
      </div>
      <div className="column">
        <InputText
          name={`${baseName}lastName`}
          label="model.person.lastName"
          disableAutoComplete
          setFieldValue={setFieldValue}
          required
          {...others}
        />
      </div>
    </div>
    <div className="columns">
      <div className="column">
        <InputDate
          name={`${baseName}dateOfBirth`}
          label="model.person.dob"
          setFieldValue={setFieldValue}
          required
          {...others}
        />
      </div>
      <div className="column">
        <InputSelect
          name={`${baseName}personGender`}
          label="model.person.gender"
          options={[{ value: 'M', label: 'M' }, { value: 'F', label: 'F' }]}
          isSearchable={false}
          setFieldValue={setFieldValue}
          required
          {...others}
        />
      </div>
      <div className="column">
        <InputText
          name={`${baseName}ssnCode`}
          label="model.person.ssnCode"
          disableAutoComplete
          setFieldValue={setFieldValue}
          {...others}
        />
      </div>
    </div>
    <div className="columns">
      <label className="column label">
        <Translated k="general.privacy.imagesConsent" />
      </label>
      <div className="column">
        <InputRadio
          name={`${baseName}imagesConsent`}
          options={[{ label: 'general.privacy.agree', value: 'true' }, { label: 'general.privacy.doNotAgree', value: 'false' }]}
          setFieldValue={setFieldValue}
          required
          {...others}
        />
      </div>
    </div>
    <Fragment>
      <div className="columns">
        <div className="column">
          <InputTag
            name={`${baseName}allergies`}
            label="model.person.allergies"
            url="/fo/partecipants/attributes?type=ALLERGY"
            setFieldValue={setFieldValue}
            {...others}
          />
        </div>
        <div className="column">
          <InputTag
            name={`${baseName}medicalCares`}
            label="model.person.medicalCares"
            url="/fo/partecipants/attributes?type=MEDICAL_CARE"
            setFieldValue={setFieldValue}
            {...others}
          />
        </div>
      </div>
      <div className="columns">
        <div className="column">
          <RemoteInputSelect
            name={`${baseName}englishLevel`}
            label="model.person.englishLevel.entityName"
            url="/fo/partecipants/englishLevels"
            setFieldValue={setFieldValue}
            {...others}
          />

        </div>
      </div>
      <div className="columns">
        <div className="column">
          <InputTextarea
            name={`${baseName}additionalInfo`}
            label="model.person.additionalInfo"
            setFieldValue={setFieldValue}
            {...others}
          />
        </div>
      </div>
    </Fragment>
  </div>
);

export default PartecipantFormFields;
