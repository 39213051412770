import React, { Fragment } from 'react';
import CustomerForm from '../../../forms/CustomerForm';
import Translated from '../../../base/elements/Translated';

const CustomerAdd = ({ handleSuccess, handleClose }) => (
  <Fragment>
    <header className="modal-card-head">
      <p className="modal-card-title">
        <Translated k="pages.back.customers.add" />
      </p>
      <button type="button" className="delete" aria-label="close" onClick={handleClose} />
    </header>
    <CustomerForm
      showNewsletterConsent
      onCancel={handleClose}
      onSuccess={() => {
        handleSuccess();
        handleClose();
      }}
    />
  </Fragment>
);

export default CustomerAdd;
