import React, {Component, Fragment} from 'react';
import Axios from 'axios';
import appUrl from '../../../utils/remote';
import Translated from '../../../base/elements/Translated';
import ErrorNotification from '../../../base/elements/ErrorNotification';

class OrderPartecipantChooseForm extends Component {
   state = {
      availablePartecipants: null,
      selectedPartecipant: null,
      loading: true,
   };

   async componentDidMount() {
      let response = await Axios.get(appUrl(`/fo/partecipants/existingPartecipants`),
         {
            headers: {
            'x-impersonate-user': this.props.impersonateUser,
         }
      });

      const {data} = response;
      this.setState({availablePartecipants: data, loading: false});

   }

   selectPartecipant = (uuid) => {
      const {availablePartecipants} = this.state;
      const selectedPartecipant = availablePartecipants.find(e => e.uuid === uuid);

      this.setState({selectedPartecipant});
      this.props.onSuccess(selectedPartecipant);
   };

   render() {
      const {
         loading, availablePartecipants, selectedPartecipant, error,
      } = this.state;

      return (
         <Fragment>
            <section className="modal-card-body">
               <Fragment>
                  {loading === false && error && (
                     <ErrorNotification
                        clearError={() => this.setState({error: null})}
                        errors={{failed: error}}
                     />
                  )}
                  {loading === false && selectedPartecipant === null && (
                     <table className="table is-bordered is-hoverable is-fullwidth">
                        <tbody>
                        {availablePartecipants.map(e => (
                           <tr key={e.uuid} className="clickable" onClick={() => this.selectPartecipant(e.uuid)}>
                              <td>{e.person.lastName}</td>
                              <td>{e.person.firstName}</td>
                              <td>{e.relationship}</td>
                           </tr>
                        ))}
                        </tbody>
                     </table>
                  )}
               </Fragment>
            </section>
            <footer className="modal-card-foot" style={{justifyContent: 'flex-end'}}>
               <button type="button" className="button" disabled={loading} onClick={this.props.onCancel}>
                  <Translated k="general.cancel"/>
               </button>
            </footer>
         </Fragment>
      );
   }
}

export default OrderPartecipantChooseForm;
