import React, {Fragment} from 'react';
import ColumnOutput from '../../../../base/elements/ColumnOutput';
import DateOutput from '../../../../base/elements/DateOutput';
import Translated from '../../../../base/elements/Translated';
import WithRemoteData from '../../../../utils/WithRemoteData';
import MoneyOutput from '../../../../base/elements/MoneyOutput';
import OutputNumber from '../../../../base/elements/OutputNumber';

const InvoiceDetails = ({ resourceId, invoiceId, handleClose }) => (
  <WithRemoteData
    url={`/orders/${resourceId}/invoices/${invoiceId}`}
    render={({ loading, data }) => loading === false
      && data !== null && (
        <Fragment>
          <header className="modal-card-head">
            <p className="modal-card-title">
              <Translated k="pages.back.events.details" />
            </p>
            <button type="button" className="delete" aria-label="close" onClick={handleClose} />
          </header>
          <section className="modal-card-body">
            <div className="box">
              <div className="columns">
                <ColumnOutput
                  label="pages.back.orders.details.billing.holder"
                  render={() => data.billingHolder.description}
                />
              </div>
              <div className="columns">
                <ColumnOutput
                  label="pages.back.orders.details.billing.number"
                  render={() => data.invoiceNumber}
                />
                <ColumnOutput
                  label="pages.back.orders.details.billing.suffix"
                  render={() => data.invoiceSuffix}
                />
                <ColumnOutput
                  label="pages.back.orders.details.billing.date"
                  render={() => <DateOutput value={data.date} />}
                />
                <ColumnOutput
                  label="pages.back.orders.details.billing.amount"
                  render={() => <MoneyOutput money={data.invoiceTotal} />}
                />
              </div>
              <div className="columns">
                <ColumnOutput
                    label="pages.back.orders.details.billing.stampType"
                    render={() => <Translated k={`model.invoice.stampType.${data.stampType}`} />}
                />
                <ColumnOutput
                    label="pages.back.orders.details.billing.stampAmount"
                    render={() => <MoneyOutput money={data.stampAmount} />}
                />
              </div>
              <div className="columns">
                <ColumnOutput
                  label="pages.back.orders.details.billing.description"
                  render={() => data.invoiceDescription}
                />
              </div>
              <div className="columns">
                <div className="column"><strong><Translated k="pages.back.orders.details.billing.row.description" /></strong></div>
                <div className="column"><strong><Translated k="pages.back.orders.details.billing.row.quantity" /></strong></div>
                <div className="column"><strong><Translated k="pages.back.orders.details.billing.row.amountWithVat" /></strong></div>
                <div className="column"><strong><Translated k="pages.back.orders.details.billing.row.amountWithoutVat" /></strong></div>
                <div className="column"><strong><Translated k="pages.back.orders.details.billing.row.vat" /></strong></div>
                <div className="column"><strong><Translated k="pages.back.orders.details.billing.row.vatDescription" /></strong></div>
              </div>
              {data && data.rows.map(i => (
                <div className="columns">
                  <div className="column">{i.description}</div>
                  <div className="column">{i.quantity}</div>
                  <div className="column"><MoneyOutput money={i.totalAmount} /></div>
                  <div className="column"><MoneyOutput money={i.amount} /></div>
                  <div className="column"><OutputNumber value={i.vat.value} symbol="%" /></div>
                  <div className="column">{i.vat.description && <Translated k={`model.vatExemption.${i.vat.description}`}/>} </div>
                </div>
              ))}
              <div className="columns">
                <ColumnOutput
                    label="pages.back.orders.details.bankAccount"
                    render={() => data.bankAccount.name}
                />
                <ColumnOutput
                    label="pages.back.orders.details.billing.paymentMethod"
                    render={() => <Translated k={`model.order.paymentMethod.${data.paymentMethod}`} />}
                />
                <ColumnOutput
                    label="pages.back.orders.details.billing.expiration.date"
                    render={() => <DateOutput value={data.expirationDate} />}
                />
              </div>
            </div>
          </section>
          <footer className="modal-card-foot" style={{ justifyContent: 'flex-end' }}>
            <button type="button" className="button" onClick={handleClose}>
              <Translated k="general.close" />
            </button>
          </footer>
        </Fragment>
    )
    }
  />
);

export default InvoiceDetails;
