import moment from 'moment';

const convert = (filters, key) => {
  const value = filters[key];

  if (moment.isMoment(value)) {
    return value.format('YYYYMMDD');
  }

  return String(value);
};

const buildUrl = (base, filters) => {
  if (!filters || !Object.keys(filters).length) {
    return base;
  }

  const params = Object.keys(filters)
    .filter(
      k => filters[k] !== undefined && filters[k] !== null && String(filters[k]).trim() !== '',
    )
    .map((k) => {
      const value = filters[k];

      if (Array.isArray(value)) {
        return value.map(v => `${k}=${String(v)}`).join('&');
      }

      return `${k}=${convert(filters, k)}`;
    })
    .join('&');

  if (!params) {
    return base;
  }

  return `${base}${base.indexOf('?') > -1 ? '&' : '?'}${params}`;
};

export default buildUrl;
