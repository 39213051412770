/* eslint
    jsx-a11y/anchor-is-valid: "off",
    jsx-a11y/click-events-have-key-events: "off",
    react/no-array-index-key: "off"
*/
import React, { Component } from 'react';
import classNames from 'classnames';
import PaginationLink from './PaginationLink';
import Translated from '../../elements/Translated';

class Pagination extends Component {
  goToPage = (page, totalPages) => {
    const {handleNavigation} = this.props;
    if (page < 1 || page > totalPages) {
      return;
    }

    handleNavigation(page);
  };

  getPages = (current, last) => {
    const delta = 2;
    const left = current - delta;
    const right = current + delta + 1;
    const range = [];
    const rangeWithDots = [];
    for (let i = 1; i <= last; i += 1) {
      if (i === 1 || i === last || (i >= left && i < right)) {
        range.push(i);
      }
    }

    let l;
    range.forEach((i) => {
      if (l) {
        if (i - l === 2) {
          rangeWithDots.push(l + 1);
        } else if (i - l !== 1) {
          rangeWithDots.push(`...${i}`);
        }
      }
      rangeWithDots.push(i);
      l = i;
    });

    return rangeWithDots;
  };

  render() {
    const {
      loading, startPosition, maxResults, data,
    } = this.props;

    const count = data !== null ? data.count : 0;
    const totalPages = parseInt((count - 1) / maxResults, 10) + 1;
    const currentPage = parseInt(startPosition / maxResults, 10) + 1;
    return (
      <nav className="pagination is-centered" role="navigation" aria-label="pagination">
        {loading === true && <div className="loading"/>}
        <a
          role="button"
          className={classNames('pagination-previous', {'is-loading': loading})}
          disabled={loading === true || currentPage <= 1}
          onClick={() => this.goToPage(currentPage - 1, totalPages)}
        >
          <Translated k="general.filters.previous"/>
        </a>
        <a
          role="button"
          className={classNames('pagination-next', {'is-loading': loading})}
          disabled={loading === true || currentPage >= totalPages}
          onClick={() => this.goToPage(currentPage + 1, totalPages)}
        >
          <Translated k="general.filters.next"/>
        </a>
        {data !== null && data.count > 0 && (
          <ul className="pagination-list">
            {this.getPages(currentPage, totalPages).map(e => (typeof e === 'string' ? (
              <li key={e}>
                <span className="pagination-ellipsis">&hellip;</span>
              </li>
            ) : (
              <PaginationLink
                key={e}
                index={e}
                currentPage={currentPage}
                handleNavigation={() => this.goToPage(e, totalPages)}
              />
            )))}
            <div> - <Translated k="general.filters.count"/>: <strong>{data.count}</strong></div>
          </ul>
        )}
      </nav>
    );
  }
}

export default Pagination;
