import React from 'react';
import Get from 'lodash.get';
import classNames from 'classnames';
import Translated from '../elements/Translated';
import Required from '../elements/Required';

const InputTextarea = ({
  name,
  label,
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  small,
  required,
}) => (
  <div className="field">
    {label && (
      <label
        className={classNames('label', {
          'is-small': small,
        })}
      >
        <Translated k={label} />
        <Required required={required} />
      </label>
    )}
    <div className="control">
      <textarea
        name={name}
        className={classNames('textarea', {
          'is-danger': Get(errors, name) && Get(touched, name),
          'is-small': small,
        })}
        onChange={handleChange}
        onBlur={handleBlur}
        value={Get(values, name)}
        autoComplete="off"
      />
    </div>
    {Get(errors, name) && Get(touched, name) && (
      <p className="help is-danger">
        <Translated k={Get(errors, name)} />
      </p>
    )}
  </div>
);

export default InputTextarea;
