import Axios from 'axios';
import { Formik } from 'formik';
import React, { Fragment } from 'react';
import { withRouter } from 'react-router-dom';
import * as Yup from 'yup';
import ErrorNotification from '../base/elements/ErrorNotification';
import Translated from '../base/elements/Translated';
import InputSelect from '../base/form/InputSelect';
import SubmitButton from '../base/form/SubmitButton';
import appUrl from '../utils/remote';
import WithRemoteData from '../utils/WithRemoteData';
import { PeopleFormFields, PeopleSchema, MandatoryPeopleSchema } from './PeopleForm';
import InputRadio from '../base/form/InputRadio';
import Required from '../base/elements/Required';

const UserSchema = Yup.object().shape({
  person: PeopleSchema,
  locale: Yup.string()
    .required('general.validation.required')
    .default('it-IT'),
  newsletterConsent: Yup.string(),
});

export const MandatoryUserSchema = Yup.object().shape({
  person: MandatoryPeopleSchema,
  locale: Yup.string()
    .required('general.validation.required')
    .default('it-IT'),
  newsletterConsent: Yup.string()
    .required('general.validation.required'),

});

const defaultSubmit = async ({
  resourceId, username, role, person, locale, newsletterConsent,
}) => {
  if (resourceId) {
    await Axios.put(appUrl(`/users/${resourceId}`), {
      id: resourceId,
      username,
      roles: [role],
      person,
      locale,
      newsletterConsent,
    });
  } else {
    await Axios.post(appUrl('/users/registerCustomer'), {
      username,
      roles: [role],
      person,
      locale,
      newsletterConsent,
    });
  }
};

const CustomerForm = ({
  onSuccess,
  onSubmit = defaultSubmit,
  onCancel,
  resourceId,
  initialValues = {},
  showLocale = false,
  showNewsletterConsent = false,
  mandatoryPeopleFields = false,
  hideCloseButton = false,
}) => (
  <Formik
    initialValues={
      mandatoryPeopleFields
        ? MandatoryUserSchema.cast(initialValues) : UserSchema.cast(initialValues)
    }
    validationSchema={mandatoryPeopleFields ? MandatoryUserSchema : UserSchema}
    onSubmit={async ({
      username, role, locale, newsletterConsent, person,
    }, actions) => {
      try {
        await onSubmit({
          resourceId, username, role, person, locale, newsletterConsent,
        });
        onSuccess();
      } catch (err) {
        actions.setErrors({
          failed: err.response !== undefined ? `${err.response.data.message}` : err.message,
        });
      }
      actions.setSubmitting(false);
    }}
    render={({
      dirty, isSubmitting, handleSubmit, setErrors, setFieldValue, ...others
    }) => (
      <form onSubmit={handleSubmit}>
        <section className="modal-card-body">
          <ErrorNotification clearError={() => setErrors({ failed: null })} {...others} />
          <Fragment>

            <PeopleFormFields
              baseName="person."
              setFieldValue={setFieldValue}
              {...others}
              mandatoryPeopleFields={mandatoryPeopleFields}
            />

            <div className="columns">

              {showLocale && (
              <div className="column">
                <WithRemoteData
                  url="/i18n/locales"
                  render={({ loading, data }) => (
                    <InputSelect
                      loading={loading}
                      setFieldValue={setFieldValue}
                      name="locale"
                      label="model.user.locale"
                      required
                      options={
                      loading === false && data
                        ? data.map(option => ({
                          value: option.code,
                          label: option.name,
                        }))
                        : []
                    }
                      {...others}
                    />
                  )}
                />
              </div>
              )}

              {showNewsletterConsent && (
              <div className="column">
                <label className="label">
                  <Translated k="general.privacy.newsletterConsentShort" />
                  <Required required />
                </label>
                <div>
                  <InputRadio
                    name="newsletterConsent"
                    options={[{ label: 'general.privacy.agree', value: 'true' }, { label: 'general.privacy.doNotAgree', value: 'false' }]}
                    {...others}
                  />
                </div>
              </div>
              )}

            </div>


          </Fragment>

          <hr />
        </section>
        <footer className="modal-card-foot" style={{ justifyContent: 'flex-end' }}>
          <SubmitButton dirty={dirty} isSubmitting={isSubmitting} />
          { !hideCloseButton && (
          <button type="button" className="button" onClick={onCancel}>
            <Translated k="general.close" />
          </button>
          )}
        </footer>
      </form>
    )}
  />
);

export default withRouter(CustomerForm);
