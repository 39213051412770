import React, {Fragment} from 'react';
import WithError from "../../../../../utils/WithError";
import ErrorNotification from "../../../../../base/elements/ErrorNotification";
import Translated from "../../../../../base/elements/Translated";
import DateOutput from "../../../../../base/elements/DateOutput";
import MoneyOutput from "../../../../../base/elements/MoneyOutput";
import IconModal from "../../../../../base/components/modal/IconModal";
import CreditNoteDelete from "./CreditNoteDelete";
import CreditNoteAdd from "./CreditNoteAdd";
import ButtonModal from "../../../../../base/components/modal/ButtonModal";

const CreditNoteOverviewDetails = ({data, handleSuccess, readOnly}) => (
  <WithError
    render={({error, clearError}) => (
      <Fragment>
        <ErrorNotification errors={{failed: error}} clearError={clearError}/>
        <table className="table is-hoverable is-bordered is-fullwidth">
          <thead className="has-background-white-ter">
          <tr>
            <th>
              <Translated k="pages.back.orders.details.creditNotes.invoice"/>
            </th>
            <th>
              <Translated k="pages.back.orders.details.billing.number"/>
            </th>
            <th>
              <Translated k="pages.back.orders.details.billing.suffix"/>
            </th>
            <th>
              <Translated k="pages.back.orders.details.billing.date"/>
            </th>
            <th>
              <Translated k="pages.back.orders.details.billing.amount"/>
            </th>
            <th></th>
          </tr>
          </thead>
          <tbody>
          {data && data.creditNotes && data.creditNotes.map(c => (
            <tr key={c.uuid}>
              <td>{c.invoiceName}</td>
              <td>{c.creditNoteNumber}</td>
              <td>{c.creditNoteSuffix}</td>
              <td>
                <DateOutput value={c.date}/>
              </td>
              <td>
                <MoneyOutput money={c.creditNoteAmount}/>
              </td>
              <td>
                <div className="buttons">
                  {!readOnly && (
                    <IconModal
                      icon="fa-trash-alt"
                      tooltipText="general.delete"
                      render={props => (
                        <CreditNoteDelete
                          onCancel={props.handleClose}
                          onSuccess={handleSuccess}
                          orderId={data.id}
                          creditNoteId={c.id}
                        />
                      )}
                      card
                    />
                  )}
                </div>
              </td>
            </tr>
          ))}
          </tbody>
        </table>

        {!readOnly && data.orderStatus === 'COMPLETED' && (
          <div className="field is-grouped is-grouped-right">
            <ButtonModal
              buttonClassName="is-default"
              buttonLabel="pages.back.orders.details.billing.createCreditNote"
              render={props => (
                <CreditNoteAdd
                  handleSuccess={handleSuccess}
                  handleClose={props.handleClose}
                  billingHolder={data.billingHolder}
                  order={data}
                />
              )}
            />
          </div>
        )}
      </Fragment>
    )}
  />
);

export default CreditNoteOverviewDetails;
