import React from 'react';
import * as Yup from 'yup';
import {FieldArray} from 'formik';
import uuid from 'uuid';
import InputText from '../base/form/InputText';
import Translated from '../base/elements/Translated';
import InputDate from '../base/form/InputDate';
import EventPackageOptionSubForm, {EventPackageOptionSchema} from './EventPackageOptionSubForm';
import momentDate from './utils/momentSchema';
import priceSchema from './utils/priceSchema';
import InputBoolean from '../base/form/InputBoolean';
import Title from '../base/elements/Title';
import MoneyInput from '../base/elements/MoneyInput';
import RemoteInputSelect from "../base/form/RemoteInputSelect";

export const EventPackageSchema = Yup.object().shape({
  name: Yup.string()
    .required('general.validation.required')
    .default(''),
  startDate: momentDate()
    .default(undefined)
    .required('general.validation.required'),
  endDate: momentDate()
    .default(undefined)
    .required('general.validation.required'),
  totalPrice: priceSchema,
  deposit: priceSchema,
  active: Yup.boolean().default(true),
  multiPurchase: Yup.boolean().default(false),
  vat: Yup.object().shape({
    value: Yup.number()
      .typeError('general.validation.number')
      .required('general.validation.required')
      .default(0),
    description: Yup.string().default(''),
  }),
  maxPartecipants: Yup.number().typeError('general.validation.number'),
  options: Yup.array()
    .of(EventPackageOptionSchema)
    .default([]),
});

const EventPackageSubForm = ({ values, ...others }) => (
  <FieldArray
    name="packages"
    render={arrayHelpers => (
      <div>
        {values.packages.map((p, i) => (
          <div key={p.uuid} className="box">
            <nav className="level compact">
              <div className="level-left" />
              <div className="level-right">
                <p className="level-item">
                  <button
                    type="button"
                    onClick={() => arrayHelpers.remove(i)}
                    className="button is-small is-danger"
                  >
                    <Translated k="general.delete" />
                  </button>
                </p>
              </div>
            </nav>
            <div className="columns">
              <div className="column is-two-fifths">
                <div className="columns compact">
                  <div className="column">
                    <InputText
                      values={values}
                      name={`packages.${i}.name`}
                      label="model.eventType.name"
                      disableAutoComplete
                      small
                      {...others}
                    />
                  </div>
                </div>
                <div className="columns compact">
                  <div className="column">
                    <InputDate
                      values={values}
                      name={`packages.${i}.startDate`}
                      label="model.eventPackage.startDate"
                      small
                      {...others}
                    />
                  </div>
                  <div className="column">
                    <InputDate
                      values={values}
                      name={`packages.${i}.endDate`}
                      label="model.eventPackage.endDate"
                      small
                      {...others}
                    />
                  </div>
                </div>
                <div className="columns compact">
                  <div className="column">
                    <MoneyInput
                      values={values}
                      name={`packages.${i}.totalPrice.value`}
                      label="model.eventPackage.price"
                      leftLabel="€"
                      disableAutoComplete
                      small
                      {...others}
                    />
                  </div>
                  <div className="column">
                    <MoneyInput
                      values={values}
                      name={`packages.${i}.deposit.value`}
                      label="model.eventPackage.deposit"
                      leftLabel="€"
                      disableAutoComplete
                      small
                      {...others}
                    />
                  </div>
                </div>
                <div className="columns compact">
                  <div className="column is-one-third">
                    <MoneyInput
                      values={values}
                      name={`packages.${i}.vat.value`}
                      label="model.vat.entityName"
                      leftLabel="%"
                      disableAutoComplete
                      small
                      {...others}
                    />
                  </div>
                  <div className="column">
                    <RemoteInputSelect
                      values={values}
                      name={`packages.${i}.vat.description`}
                      label="model.vat.description"
                      url="/events/vatExemptions"
                      small
                      {...others}
                  />
                  </div>
                </div>
                <div className="columns compact">
                  <div className="column">
                    <InputText
                      values={values}
                      name={`packages.${i}.maxPartecipants`}
                      label="model.eventPackage.maxPartecipants"
                      disableAutoComplete
                      small
                      {...others}
                    />
                  </div>
                  <div className="column">
                    <InputBoolean
                      values={values}
                      name={`packages.${i}.active`}
                      label="model.eventPackage.active"
                      small
                      {...others}
                    />
                  </div>
                  <div className="column">
                    <InputBoolean
                      values={values}
                      name={`packages.${i}.multiPurchase`}
                      label="model.eventPackage.multiPurchase"
                      small
                      {...others}
                    />
                  </div>
                </div>
              </div>
              <div className="column right-column">
                <div className="level">
                  <Title size={6} label="model.eventPackageOption.entityName" />
                </div>
                <EventPackageOptionSubForm index={i} values={values} {...others} />
              </div>
            </div>
          </div>
        ))}
        <button
          type="button"
          className="button"
          onClick={() => arrayHelpers.push(EventPackageSchema.cast({ uuid: uuid() }))}
        >
          <Translated k="pages.back.eventPackages.add" />
        </button>
      </div>
    )}
  />
);

export default EventPackageSubForm;
