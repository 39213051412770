import React from 'react';
import BaseRouter from './routers/BaseRouter';
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import PurchaseLoginPage from "./pages/auth/PurchaseLoginPage";
import AppProvider from "./AppProvider";

export const AppContext = React.createContext();

const App = () => (
    <Router>
    <AppProvider>
      <Switch>
        <Route path="/purchase" component={PurchaseLoginPage}>
      </Route>
        <Route component={BaseRouter} />
      </Switch>
  </AppProvider>
    </Router>
);

export default App;
