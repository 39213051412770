import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom';
import queryString from 'query-string';
import Background from '../../../images/login-background.jpg';
import SignupPage from './SignupPage';
import LoginPage from './LoginPage';
import RecoveryPasswordPage from './RecoveryPasswordPage';
import RegistrationResultPage from './RegistrationResultPage';

class AuthenticationPage extends Component {
  componentDidMount() {
    const { onInit, location } = this.props;
    const { locale } = queryString.parse(location.search);

    onInit(locale || 'it-IT');
  }

  render() {
    return (
      <div
        className="hero is-fullheight authentication-page"
        style={{ backgroundImage: `url(${Background})` }}
      >
        <div className="hero-body">
          <div className="container">
            <Switch>
              <Route path="/signup" exact component={SignupPage} />
              <Route path="/recovery" exact component={RecoveryPasswordPage} />
              <Route path="/signup/result" exact component={RegistrationResultPage} />
              <Route component={LoginPage} />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default AuthenticationPage;
