import React from 'react';
import WithRemoteData from '../../utils/WithRemoteData';
import InputRadio from './InputRadio';

const RemoteInputRadio = ({ url, ...others }) => (
  <WithRemoteData
    url={url}
    render={({ loading, data }) => loading === false && data !== null
      && <InputRadio options={data} {...others} />}
  />
);

export default RemoteInputRadio;
