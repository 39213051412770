import React, {Fragment} from 'react';
import Translated from "../../../../../base/elements/Translated";
import CreditNoteForm from "../../../../../forms/orders/creditNotes/CreditNoteForm";

const CreditNoteAdd = ({ handleSuccess, handleClose, order }) => (
    <Fragment>
        <header className="modal-card-head">
            <p className="modal-card-title">
                <Translated k="pages.back.orders.details.creditNotes.add" />
            </p>
            <button type="button" className="delete" aria-label="close" onClick={handleClose} />
        </header>
        <CreditNoteForm
            onCancel={handleClose}
            onSuccess={() => {
                handleSuccess();
                handleClose();
            }}
            resourceId={order.id}
            invoices={order.invoices}
        />
    </Fragment>
);

export default CreditNoteAdd;
