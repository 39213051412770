import React, { Fragment } from 'react';
import Axios from 'axios';
import appUrl from '../../../utils/remote';
import Translated from '../../../base/elements/Translated';
import Title from '../../../base/elements/Title';
import WithError from '../../../utils/WithError';
import ErrorNotification from '../../../base/elements/ErrorNotification';

const acknowOrder = async (orderId, onSuccess, onCancel, initialValues, showError) => {
  try {
    await Axios.put(appUrl(`/orders/${orderId}/acknowledgment`), initialValues);
    onSuccess();
    onCancel();
  } catch (err) {
    showError(err.message);
  }
};

const OrderAcknowledgment = ({
  orderId, onCancel, onSuccess, initialValues,
}) => (
  <WithError
    render={({ error, showError, clearError }) => (
      <Fragment>
        <div className="message is-warning">
          <div className="message-body">
            <ErrorNotification errors={{ failed: error }} clearError={clearError} />
            <div className="level">
              <Title
                size={3}
                label={
                  initialValues.acknowledgment
                    ? 'pages.back.orders.acknowledgment.titleRemove'
                    : 'pages.back.orders.acknowledgment.titleAdd'
                }
              />
            </div>
            <p style={{ marginBottom: '1.5rem' }}>
              <Translated
                k={
                  initialValues.acknowledgment
                    ? 'pages.back.orders.acknowledgment.messageRemove'
                    : 'pages.back.orders.acknowledgment.messageAdd'
                }
              />
            </p>
            <div className="field is-grouped is-grouped-right">
              <div className="control">
                <button
                  type="button"
                  className="button is-primary"
                  onClick={() => acknowOrder(orderId, onSuccess, onCancel, initialValues, showError)
                  }
                >
                  <Translated k="general.confirm" />
                </button>
              </div>
              <div className="control">
                <button type="button" className="button" onClick={onCancel}>
                  <Translated k="general.close" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )}
  />
);

export default OrderAcknowledgment;
