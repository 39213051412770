import Axios from 'axios';
import React, { Fragment } from 'react';
import Translated from '../../base/elements/Translated';
import CustomerForm from '../../forms/CustomerForm';
import appUrl from '../../utils/remote';
import WithRemoteData from '../../utils/WithRemoteData';

const MandatoryUserDataPage = ({ resourceId }) => (
  <WithRemoteData
    url="/fo/users"
    render={({ loading, data }) => loading === false
      && data !== null && (
        <Fragment>
          <div className="section main-page has-text-black">
            <header className="modal-card-head">
              <p className="modal-card-title">
                <Translated k="model.user.setMandatoryFields" />
              </p>
            </header>
            <CustomerForm
              mandatoryPeopleFields
              hideCloseButton
              onCancel={() => {
                window.location = '/logout';
              }}
              onSuccess={() => {
                window.location = '/';
              }}
              onSubmit={async ({ person, locale, newsletterConsent }) => Axios.put(appUrl('/fo/users'), {
                person,
                locale,
                newsletterConsent,
              })}
              initialValues={{
                ...data,
                role: data.roles[0],
              }}
              resourceId={resourceId}
              showLocale
              showNewsletterConsent
            />
          </div>
        </Fragment>
    )
    }
  />
);

export default MandatoryUserDataPage;
