import React from 'react';
import { Formik } from 'formik';
import uuid from 'uuid';
import { PeopleFormFields, PeopleSchema } from '../../PeopleForm';
import Translated from '../../../base/elements/Translated';
import SubmitButton from '../../../base/form/SubmitButton';
import ErrorNotification from '../../../base/elements/ErrorNotification';

const OrderBillingDetailsEditForm = ({
  initialValues = { uuid: uuid() }, onSuccess, onCancel, expanded,
}) => (
  <Formik
    initialValues={PeopleSchema.cast(initialValues)}
    validationSchema={PeopleSchema}
    onSubmit={(data, actions) => {
      onSuccess(data);
      actions.setSubmitting(false);
    }}
    render={({
      dirty, isSubmitting, handleSubmit, setErrors, ...others
    }) => (
      <form onSubmit={handleSubmit}>
        <section className="modal-card-body">
          <ErrorNotification clearError={() => setErrors({ failed: null })} {...others} />
          <PeopleFormFields {...others} />
        </section>
        {(expanded !== true)
        && (
        <footer className="modal-card-foot" style={{ justifyContent: 'flex-end' }}>
          <SubmitButton dirty={dirty} isSubmitting={isSubmitting} />
          <button type="button" className="button" disabled={isSubmitting} onClick={onCancel}>
            <Translated k="general.close" />
          </button>
        </footer>
        )}
      </form>
    )}
  />
);

export default OrderBillingDetailsEditForm;
