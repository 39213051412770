import React, { Fragment } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { AppContext } from '../App';
import Navbar from '../base/components/navbar/Navbar';
import AuthenticationPage from '../pages/auth/AuthenticationPage';
import ChangePasswordPage from '../pages/auth/ChangePasswordPage';
import LogoutPage from '../pages/auth/LogoutPage';
import MandatoryUserDataPage from '../pages/front/MandatoryUserDataPage';
import NotAllowed from '../pages/NotAllowed';
import CookieDisclaimer from '../utils/CookieDisclaimer';
import BackofficeRouter from './BackofficeRouter';
import FrontofficeRouter from './FrontofficeRouter';
import MinimalNavbar from '../base/components/navbar/MinimalNavBar';
import Privacy from '../pages/Privacy';

const BaseRouter = () => (
  <Fragment>
    <AppContext.Consumer>
      {({
        isLogged, isEnabled, isChangePasswordNeeded, isUserDetailsNeeded, loading, isBackOffice, loadMessages,
      }) => (
        <Fragment>
          {!isLogged()
            && !loading && (
              <Router>
                <Switch>
                  <Route path="/privacy" component={Privacy} />
                  <Route
                    path="/"
                    render={({ location }) => (
                      <AuthenticationPage onInit={loadMessages} location={location} />
                    )}
                  />
                </Switch>
              </Router>
          )}

          {isLogged() && !isEnabled() && <NotAllowed />}

          {isLogged()
            && isEnabled()
            && isChangePasswordNeeded() && (
              <Router>
                <Fragment>
                  <Route path="/logout" component={LogoutPage} />
                  <Route
                    render={({ location }) => (
                      <ChangePasswordPage onInit={loadMessages} location={location} />
                    )}
                  />
                </Fragment>
              </Router>
          )}

          {isLogged()
            && isEnabled()
            && !isChangePasswordNeeded()
            && isUserDetailsNeeded() && (
              <Router>
                <div>
                  <MinimalNavbar />
                  <Route path="/logout" component={LogoutPage} />
                  <Route
                    render={() => (
                      <MandatoryUserDataPage />
                    )}
                  />
                </div>
              </Router>
          )}


          {isLogged()
            && isEnabled()
            && !isChangePasswordNeeded()
            && !isUserDetailsNeeded() && (
              <Router>
                <div>
                  <Navbar />
                  <Switch>
                    <Route path="/logout" component={LogoutPage} />
                    {isBackOffice() ? (
                      <Route component={BackofficeRouter} />
                    ) : (
                      <Route component={FrontofficeRouter} />
                    )}
                  </Switch>
                </div>
              </Router>
          )}
          <CookieDisclaimer />
        </Fragment>
      )}
    </AppContext.Consumer>
  </Fragment>
);

export default BaseRouter;
