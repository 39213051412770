import React from 'react';
import Title from '../base/elements/Title';

const NotFound = () => (
  <section className="hero is-primary is-fullheight">
    <div className="hero-body">
      <div className="container">
        <div className="title" style={{ fontSize: '5rem' }}>
          404
        </div>
        <Title size={3} label="pages.notfound.message" />
      </div>
    </div>
  </section>
);

export default NotFound;
