import React from 'react';
import ColumnOutput from '../../../base/elements/ColumnOutput';
import EventPackageOptionsDetails from './EventPackageOptionDetails';
import DateOutput from '../../../base/elements/DateOutput';
import MoneyOutput from '../../../base/elements/MoneyOutput';
import Title from '../../../base/elements/Title';
import BooleanOutput from '../../../base/elements/BooleanOutput';
import OutputNumber from '../../../base/elements/OutputNumber';
import Translated from "../../../base/elements/Translated";

const EventPackageDetails = ({ data }) => (
  <div className="box">
    <div className="columns">
      <div className="column is-two-fifths">
        <div className="columns compact">
          <ColumnOutput label="model.eventType.name" render={() => data.name} />
        </div>
        <div className="columns compact">
          <ColumnOutput
            label="model.eventPackage.startDate"
            render={() => <DateOutput value={data.startDate} />}
          />
          <ColumnOutput
            label="model.eventPackage.endDate"
            render={() => <DateOutput value={data.endDate} />}
          />
        </div>
        <div className="columns compact">
          <ColumnOutput
            label="model.eventPackage.price"
            render={() => <MoneyOutput money={data.totalPrice} />}
          />
          <ColumnOutput
            label="model.eventPackage.deposit"
            render={() => <MoneyOutput money={data.deposit} />}
          />
        </div>
        <div className="columns compact">
          <ColumnOutput label="model.vat.entityName" render={() => <OutputNumber value={data.vat.value} symbol="%" />} />
          <ColumnOutput label="model.vat.description" render={() => data.vat.description && <Translated k={`model.vatExemption.${data.vat.description}`}/>} />
        </div>
        <div className="columns compact">
          <ColumnOutput
            label="model.eventPackage.maxPartecipants"
            render={() => data.maxPartecipants}
          />
          {data.active && (
          <ColumnOutput
            label="model.eventPackage.active"
            render={() => <BooleanOutput value={data.active} />}
          />
          )}
        </div>
        {data.multiPurchase && (
        <div className="columns compact">
          <ColumnOutput
            label="model.eventPackage.multiPurchase"
            render={() => <BooleanOutput value={data.multiPurchase} />}
          />
        </div>
        )}
      </div>
      <div className="column right-column">
        <div className="level">
          <Title size={6} label="model.eventPackageOption.entityName" />
        </div>
        {data.options.map(o => (
          <EventPackageOptionsDetails key={o.id} data={o} />
        ))}
      </div>
    </div>
  </div>
);

export default EventPackageDetails;
