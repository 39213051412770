import React from 'react';
import { NavLink } from 'react-router-dom';
import Translated from '../../base/elements/Translated';

const HomeTile = ({ linkTo, label, color }) => (
  <NavLink to={linkTo} className={`tile is-child notification is-${color} has-text-centered`}>
    <article>
      <p className="subtitle">
        <Translated k={label} />
      </p>
    </article>
  </NavLink>
);

const HomePage = () => (
  <div className="section main-page">
    <div className="container">
      <div className="columns">
        <div className="column is-three-fifths is-offset-one-fifth">
          <div className="tile is-ancestor">
            <div className="tile is-parent">
              <HomeTile linkTo="/users" label="model.user.entityNamePlural" color="danger" />
            </div>
            <div className="tile is-parent">
              <HomeTile
                linkTo="/eventTypes"
                label="model.eventType.entityNamePlural"
                color="primary"
              />
            </div>
          </div>
          <div className="tile is-ancestor">
            <div className="tile is-parent">
              <HomeTile linkTo="/events" label="model.event.entityNamePlural" color="warning" />
            </div>
            <div className="tile is-parent">
              <HomeTile
                linkTo="/partecipants"
                label="model.partecipant.entityNamePlural"
                color="link"
              />
            </div>
            <div className="tile is-parent">
              <HomeTile linkTo="/orders" label="general.navbar.orders" color="success" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default HomePage;
