import React from 'react';
import ReactDOM from 'react-dom';
import App from './main/App';
import 'bulma/css/bulma.css';
import 'bulma-tooltip/dist/css/bulma-tooltip.min.css';
import 'bulma-o-steps/bulma-steps.min.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-datepicker/dist/react-datepicker.css';
import './index.css';

ReactDOM.render(React.createElement(App), document.getElementById('root'));
