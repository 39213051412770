import React, { Component } from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
import moment from 'moment';
import appUrl from '../utils/remote';
import ErrorNotification from '../base/elements/ErrorNotification';
import InputText from '../base/form/InputText';
import InputTextarea from '../base/form/InputTextarea';
import Translated from '../base/elements/Translated';
import SubmitButton from '../base/form/SubmitButton';
import InputUpload from './InputUpload';
import ColumnOutput from '../base/elements/ColumnOutput';
import { AppContext } from '../App';
import InputBoolean from '../base/form/InputBoolean';

const EventTypeSchema = Yup.object().shape({
  name: Yup.string()
    .required('general.validation.required')
    .default(''),
  description: Yup.string()
    .required('general.validation.required')
    .default(''),
  defaultInvoiceSuffix: Yup.string().default(''),
  onlineBuyable: Yup.boolean().default(true),
});

class EventTypeForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      accept: 'image/*',
      error: null,
    };
  }

  uploadFile = async (acceptedFiles, rejectedFiles, setFieldValue) => {
    if (rejectedFiles !== 'undefined' && rejectedFiles.length > 0) {
      this.setState({ error: 'model.eventType.imageFormatError' });
    }

    if (acceptedFiles !== 'undefined' && acceptedFiles.length > 0) {
      const formData = new FormData();
      formData.append('uploadedFile', acceptedFiles[0]);

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
        },
      };

      this.setState({ isLoading: true });
      const response = await Axios.post(appUrl('/attachments'), formData, config);

      const file = {
        attachmentName: acceptedFiles[0].name,
        attachmentTimestamp: moment(acceptedFiles[0].lastModifiedDate).format('YYYY-MM-DD HH:mm'),
        attachmentUuid: response.data.value,
      };

      setFieldValue('uploadedImage', file);

      this.setState({ isLoading: false, error: null });
    }
  };

  render() {
    const {
      onSuccess, onCancel, resourceId, initialValues = {},
    } = this.props;

    const { accept, error, isLoading } = this.state;

    return (
      <Formik
        initialValues={EventTypeSchema.cast(initialValues)}
        validationSchema={EventTypeSchema}
        onSubmit={async (data, actions) => {
          try {
            const response = {
              ...data,
            };
            if (resourceId) {
              await Axios.put(appUrl(`/eventTypes/${resourceId}`), response);
            } else {
              await Axios.post(appUrl('/eventTypes'), response);
            }
            onSuccess();
          } catch (err) {
            actions.setErrors({
              failed: err.response !== undefined ? `${err.response.data.message}` : err.message,
            });
          }
          actions.setSubmitting(false);
        }}
        render={({
          dirty, isSubmitting, handleSubmit, setErrors, setFieldValue, ...others
        }) => (
          <form onSubmit={handleSubmit}>
            <section className="modal-card-body">
              <ErrorNotification clearError={() => setErrors({ failed: null })} {...others} />
              <ErrorNotification
                clearError={() => this.setState({ error: null })}
                errors={{ failed: error }}
              />
              <InputText name="name" label="model.eventType.name" disableAutoComplete {...others} />
              <InputTextarea name="description" label="model.eventType.description" {...others} />
              <InputText name="defaultInvoiceSuffix" label="model.eventType.defaultInvoiceSuffix" {...others} />
              <InputBoolean name="onlineBuyable" label="model.eventType.onlineBuyable" setFieldValue={setFieldValue} {...others} />
              <div className="field">
                <strong>
                  <Translated k="model.event.attachment" />
                </strong>
              </div>
              <AppContext.Consumer>
                {({ translate }) => (
                  <InputUpload
                    name="uploadedImage"
                    accept={accept}
                    upload={(acceptedFiles, rejectedFiles) => this.uploadFile(
                      acceptedFiles, rejectedFiles, setFieldValue,
                    )}
                    text={translate('model.event.addAttachment')}
                    isLoading={isLoading}
                  />
                )}
              </AppContext.Consumer>
              {others.values.uploadedImage && (
              <ColumnOutput render={() => others.values.uploadedImage.attachmentName} />
              )}
            </section>
            <footer className="modal-card-foot" style={{ justifyContent: 'flex-end' }}>
              <SubmitButton dirty={dirty} isSubmitting={isSubmitting} isLoading={isLoading} />
              <button type="button" className="button" onClick={onCancel}>
                <Translated k="general.close" />
              </button>
            </footer>
          </form>
        )}
      />
    );
  }
}

export default EventTypeForm;
