import React, { Component } from 'react';
import Get from 'lodash.get';
import CreatableSelect from 'react-select/lib/Creatable';
import classNames from 'classnames';
import Translated from '../elements/Translated';
import WithRemoteData from '../../utils/WithRemoteData';
import Required from '../elements/Required';

class InputTag extends Component {
  handleChange = (selected) => {
    const { name, setFieldValue } = this.props;
    setFieldValue(name, selected.map(s => s.value));
  };

  handleBlur = () => {
    const { name, setFieldTouched } = this.props;
    setFieldTouched(name, true);
  };

  render() {
    const {
      name,
      label,
      values,
      errors,
      touched,
      url,
      isSearchable = true,
      isClearable = false,
      required,
    } = this.props;

    const selectedValue = Get(values, name, []).map(v => ({ label: v, value: v }));

    return (
      <WithRemoteData
        url={url}
        render={({ loading, data }) => (
          <div className="field">
            {label && (
              <label className="label">
                <Translated k={label} />
                <Required required={required} />
              </label>
            )}
            <div className="control is-expanded">
              <div
                className={classNames('is-fullwidth', {
                  'is-loading': loading,
                  'is-danger': Get(errors, name) && Get(touched, name),
                })}
              >
                <CreatableSelect
                  options={data}
                  name={name}
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  value={selectedValue}
                  isClearable={isClearable}
                  isSearchable={isSearchable}
                  isLoading={loading}
                  placeholder=""
                  isMulti
                />
              </div>
            </div>
            {Get(errors, name) && Get(touched, name) && (
              <p className="help is-danger">
                <Translated k={Get(errors, name)} />
              </p>
            )}
          </div>
        )}
      />
    );
  }
}

export default InputTag;
