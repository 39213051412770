import React, { Fragment } from 'react';

const ErrorNotification = ({ errors, clearError }) => (
  <Fragment>
    {errors.failed && (
    <div className="notification is-danger">
      <button type="button" className="delete" onClick={clearError} />
      {errors.failed}
    </div>
    )}
  </Fragment>
);

export default ErrorNotification;
