import React, { Fragment } from 'react';
import WithRemoteData from '../../../utils/WithRemoteData';
import EventForm from '../../../forms/EventForm';
import Translated from '../../../base/elements/Translated';

const EventDuplicate = ({ handleSuccess, handleClose, resourceId }) => (
  <WithRemoteData
    url={`/events/${resourceId}/copy`}
    render={({ loading, data }) => loading === false
      && data !== null && (
        <Fragment>
          <header className="modal-card-head">
            <p className="modal-card-title">
              <Translated k="pages.back.events.duplicate" />
            </p>
            <button type="button" className="delete" aria-label="close" onClick={handleClose} />
          </header>
          <EventForm
            onCancel={handleClose}
            onSuccess={() => {
              handleSuccess();
              handleClose();
            }}
            initialValues={data}
          />
        </Fragment>
    )
    }
  />
);

export default EventDuplicate;
