import React from 'react';
import {Route, Switch} from 'react-router-dom';
import HomePage from '../pages/back/HomePage';
import NotFound from '../pages/NotFound';
import UserList from '../pages/back/users/UserList';
import CustomerList from '../pages/back/customers/CustomerList';
import EventTypeList from '../pages/back/eventTypes/EventTypeList';
import EventList from '../pages/back/events/EventList';
import OrderList from '../pages/back/orders/OrderList';
import PartecipantList from '../pages/back/partecipants/PartecipantList';
import OrderCompletePage from '../pages/front/OrderCompletePage';
import BankAccountList from "../pages/back/bankAccounts/BankAccountList";

const BackofficeRouter = () => (
  <Switch>
    <Route path="/" exact component={HomePage} />
    <Route path="/users" exact component={UserList} />
    <Route path="/customers" exact component={CustomerList} />
    <Route path="/eventTypes" exact component={EventTypeList} />
    <Route path="/events" exact component={EventList} />
    <Route path="/partecipants" exact component={PartecipantList} />
    <Route path="/orders" exact component={OrderList} />
    <Route path="/complete" exact component={OrderCompletePage} />
    <Route path="/bankAccounts" exact component={BankAccountList} />
    <Route component={NotFound} />
  </Switch>
);

export default BackofficeRouter;
