import React, { Component, Fragment } from 'react';
import CartOrderPartecipantFo from './CartOrderPartecipantFo';
import OrderPricing from '../../forms/orders/OrderPricing';

class CartOverviewDetailsFo extends Component {
  constructor(props) {
    super(props);

    const { initialValues } = props;

    this.state = {
      order: initialValues,
    };
  }

  render() {
    const {
      order,
    } = this.state;

    return (
      <Fragment>
        <div className="columns">
          <div className="column">
            {order.items[0].partecipants.map(p => (
              <CartOrderPartecipantFo
                key={p.uuid}
                partecipant={p}
                order={order}
                handleRemovePartecipations={this.removePartecipations}
                handleAddPartecipations={this.addPartecipations}
              />
            ))}
          </div>
          <div className="column is-one-third">
            <OrderPricing order={order} />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default CartOverviewDetailsFo;
