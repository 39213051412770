import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import MoneyOutput from '../../../base/elements/MoneyOutput';
import Translated from '../../../base/elements/Translated';
import Icon from '../../../base/elements/Icon';
import Title from '../../../base/elements/Title';

class OrderPartecipation extends Component {
  render() {
    const {
      partecipations, partecipant, handleRemove, readOnly, small = false,
    } = this.props;

    return (
      partecipations.length > 0 && (
        <div className="columns compact is-vcentered order-partecipation-column">
          <div className="column">
            <div className="columns compact">
              <div className="column">
                <Title size={5} label={partecipations[0].eventPackage.eventName} untranslated />
              </div>
            </div>
            {partecipations.map(p => (
              <Fragment key={p.uuid}>
                <div className="columns compact">
                  <div
                    className={classNames('column', {
                      'is-two-thirds': small,
                    })}
                  >
                    <span>{p.eventPackage.name}</span>
                    {p.quantity > 1 && (
                      <span className="padded-left-less">{`(x ${p.quantity})`}</span>
                    )}
                  </div>
                  <div className="column">
                    <MoneyOutput money={p.packageAmount} multiplier={p.quantity} />
                    {p.quantity > 1 && (
                      <div className="is-size-7">
                        {'('}
                        <span className="has-text-weight-bold">
                          <MoneyOutput money={p.packageAmount} />
                        </span>
                        <span className="padded-left-less">
                          <Translated k="general.each" />
                        </span>
                        {')'}
                      </div>
                    )}
                  </div>
                </div>
                {p.options.map(o => (
                  <div className="columns" key={o.uuid}>
                    <div
                      className={classNames('column', {
                        'is-two-thirds': small,
                      })}
                    >
                      <ul>
                        <li className="padded-left">{o.name}</li>
                      </ul>
                    </div>
                    <div className="column">
                      <MoneyOutput money={o.packageOptionAmount} />
                      {o.onSitePayment && (
                        <div className="is-size-7 has-text-weight-bold">
                          <Translated k="model.eventPackageOption.onSitePayment" />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </Fragment>
            ))}
          </div>
          <div className="column is-one-third">
            {readOnly !== true && (
              <button
                type="button"
                className="button is-danger is-medium"
                onClick={() => {
                  handleRemove(partecipations, partecipant);
                }}
              >
                <span className="icon">
                  <Icon name="fa-trash" />
                </span>
                <span>
                  <Translated k="pages.shared.orders.partecipation.remove" />
                </span>
              </button>
            )}
          </div>
        </div>
      )
    );
  }
}

export default OrderPartecipation;
