import React, { Fragment, Component } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Axios from 'axios';
import queryString from 'query-string';
import classNames from 'classnames';
import Logo from '../../../images/canadian-logo.png';
import Translated from '../../base/elements/Translated';
import appUrl from '../../utils/remote';
import ErrorNotification from '../../base/elements/ErrorNotification';

class RegistrationResultPage extends Component {
  state = {
    registration: false,
    error: false,
    failed: null,
    loading: true,
  };

  componentDidMount() {
    this.register();
  }

  register = async () => {
    try {
      const { location } = this.props;
      const { user } = queryString.parse(location.search);

      await Axios.get(appUrl(`/fo/users/confirm/?user=${user}`));

      this.setState({
        registration: true,
        loading: false,
      });
    } catch (err) {
      this.setState({
        error: true,
        loading: false,
        failed:
        err.response !== undefined
          ? `${err.response.data.message}`
          : err.message,
      });
    }
  }


  render = () => {
    const {
      registration, error, failed, loading,
    } = this.state;

    const { location } = this.props;

    return (
      <div className="has-background-white login-card column is-4 is-offset-4">
        <figure className="avatar has-text-centered login-logo">
          <img src={Logo} alt="Canadian Island" />
        </figure>
        {registration === true && (
        <p className="has-text-centered">
          <strong>
            <Translated k="pages.auth.registrationCompleted" />
          </strong>
        </p>
        )}
        {error && (
          <Fragment>
            <p className="has-text-centered login-logo">
              <strong>
                <Translated k="pages.auth.registrationError" />
              </strong>
            </p>
            <ErrorNotification
              clearError={() => this.setState({ failed: null })}
              errors={{ failed }}
            />
          </Fragment>
        )}
        <hr />
        <Link
          to={`/?locale=${queryString.parse(location.search).locale || 'it-IT'}`}
          className={classNames('button is-default is-fullwidth form-buttons', {
            'is-loading': loading,
          })}
        >
          <Translated k="pages.auth.login" />
        </Link>
      </div>
    );
  };
}

export default withRouter(RegistrationResultPage);
