/* eslint no-undef: "off" */
import React, {Fragment} from 'react';
import ReactDOM from 'react-dom';
import Axios from 'axios';
import scriptLoader from 'react-async-script-loader';
import appUrl from '../../../utils/remote';

const createOrder = (billingHolder, completeTotal, notes) => (_, actions) =>
  actions.order.create({
    intent: 'CAPTURE',
    payer: {
      name: {
        given_name: billingHolder.firstName,
        surname: billingHolder.lastName
      },
      email_address: billingHolder.email
    },
    purchase_units: [
      {
        amount: {
          value: `${completeTotal.value.toFixed(completeTotal.fractionDigits)}`
        },
        description: notes
      }
    ]
  });

const onApprove = (onSuccess) => (data, actions) =>
  actions.order.capture().then(() => onSuccess(data.orderID));

const PayPalButton = ({
  billingHolder,
  completeTotal,
  notes,
  onSuccess,
  isScriptLoaded,
  isScriptLoadSucceed,
  disabled = false,
}) => {
  const showButton = isScriptLoaded && isScriptLoadSucceed;

  return (
    <Fragment>
      {showButton &&
        disabled === false &&
        React.createElement(
          paypal.Buttons.driver('react', { React, ReactDOM }),
          {
            createOrder: createOrder(billingHolder, completeTotal, notes),
            onApprove: onApprove(onSuccess)
          }
        )}
    </Fragment>
  );
};

class SelfLoadingPayPalButton extends React.Component {
  state = {
    loading: true,
    MyComponent: null,
  };

  componentDidMount() {
    this.load();
  }

  load = async () => {
    const data = await Axios.get(appUrl('/paypal/clientId'));
    this.setState({
      loading: false,
      MyComponent: scriptLoader(
        `https://www.paypal.com/sdk/js?client-id=${data.data}&currency=EUR`
      )(PayPalButton),
    });
  };

  render() {
    const { loading, MyComponent } = this.state;
    return !loading && <MyComponent {...this.props} />;
  }
}

export default SelfLoadingPayPalButton;
