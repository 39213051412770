import classNames from 'classnames';
import React from 'react';
import Brand from './Brand';
import MinimalAccount from './MinimalAccount';

const MinimalNavbar = () => (
  <nav className="navbar is-fixed-top is-primary" role="navigation" aria-label="main navigation">
    <div className="container">
      <div className="navbar-brand">
        <Brand />

      </div>

      <div
        className={classNames('navbar-menu', 'is-active')}
      >

        <div className="navbar-end">
          <MinimalAccount />
        </div>
      </div>
    </div>
  </nav>
);

export default MinimalNavbar;
