import React, { Component, Fragment } from 'react';
import Translated from '../../../base/elements/Translated';
import Icon from '../../../base/elements/Icon';

// TODO forse meglio come controlled component?
// usa lo stato interno solo quando sono !valid, altrimenti usa il valore esterno
// in questo modo volendo la potrei riusare
class InputQuantity extends Component {
  constructor(props) {
    super(props);
    const { initialValue } = this.props;

    this.state = {
      numberValue: initialValue || 1,
      lastValidValue: initialValue || 1,
      valid: true,
    };
  }

  canStepUp = () => {
    const { valid, numberValue } = this.state;
    const { maxValue = 100 } = this.props;

    return valid && numberValue <= maxValue;
  };

  canStepDown = () => {
    const { valid, numberValue } = this.state;
    const { minValue = 1 } = this.props;

    return valid && numberValue > minValue;
  };

  stepUp = () => {
    const { numberValue } = this.state;

    if (this.canStepUp()) {
      this.setState({ numberValue: numberValue + 1 }, this.updateChanges);
    }
  };

  stepDown = () => {
    const { numberValue } = this.state;

    if (this.canStepDown()) {
      this.setState({ numberValue: numberValue - 1 }, this.updateChanges);
    }
  };

  handleChangeInternal = (e) => {
    const { numberValue } = this.state;
    const { value } = e.target;
    const { minValue = 1, maxValue = 100 } = this.props;

    if (value === '') {
      this.setState({ numberValue: value, lastValidValue: numberValue, valid: false });
    } else {
      const parsed = parseInt(value, 10);

      if (Number.isInteger(parsed) && parsed > minValue && parsed <= maxValue) {
        this.setState({ numberValue: parsed, valid: true }, this.updateChanges);
      }
    }
  };

  handleBlurInternal = () => {
    const { lastValidValue, valid } = this.state;

    if (!valid) {
      this.setState({ numberValue: lastValidValue, valid: true }, this.updateChanges);
    }
  };

  updateChanges = () => {
    const { handleChange } = this.props;
    const { numberValue } = this.state;

    handleChange(numberValue);
  };

  render() {
    const { disabled } = this.props;
    const { numberValue } = this.state;

    return (
      <Fragment>
        <label className="label is-small">
          <Translated k="model.eventPackagePartecipation.quantity" />
        </label>
        <div className="field has-addons has-addons-centered">
          <p className="control">
            <button
              type="button"
              className="button is-info is-small"
              disabled={disabled || !this.canStepDown()}
              onClick={this.stepDown}
            >
              <Icon name="fa-minus" />
            </button>
          </p>
          <p className="control">
            <input
              className="input is-small"
              type="text"
              value={numberValue}
              onChange={this.handleChangeInternal}
              onBlur={this.handleBlurInternal}
              disabled={disabled}
            />
          </p>
          <p className="control">
            <button
              type="button"
              className="button is-info is-small"
              disabled={disabled || !this.canStepUp()}
              onClick={this.stepUp}
            >
              <Icon name="fa-plus" />
            </button>
          </p>
        </div>
      </Fragment>
    );
  }
}

export default InputQuantity;
