import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import InputText from '../../../../base/form/InputText';
import ErrorNotification from '../../../../base/elements/ErrorNotification';
import Translated from '../../../../base/elements/Translated';
import SubmitButton from '../../../../base/form/SubmitButton';
import priceSchema from '../../../../forms/utils/priceSchema';
import MoneyInput from '../../../../base/elements/MoneyInput';

const CustomItemSchema = Yup.object().shape({
  description: Yup.string()
    .required('general.validation.required')
    .default(''),
  totalAmount: priceSchema,
  vat: Yup.object().shape({
    value: Yup.number()
      .typeError('general.validation.number')
      .required('general.validation.required')
      .default(0),
  }),
  type: Yup.string()
    .default('custom'),
});

const CustomItemForm = ({
  initialValues = {}, onConfirm, onCancel,
}) => (
  <Formik
    initialValues={CustomItemSchema.cast(initialValues)}
    validationSchema={CustomItemSchema}
    onSubmit={data => onConfirm(data)}
    render={({
      dirty, isSubmitting, handleSubmit, setErrors, ...others
    }) => (
      <form onSubmit={handleSubmit}>
        <section className="modal-card-body">
          <ErrorNotification clearError={() => setErrors({ failed: null })} {...others} />
          <div className="columns">
            <div className="column">
              <InputText
                name="description"
                label="pages.back.orders.details.cart.description"
                disableAutoComplete
                {...others}
              />
            </div>
            <div className="column">
              <MoneyInput
                name="totalAmount.value"
                label="pages.back.orders.details.cart.amount"
                disableAutoComplete
                leftLabel="€"
                {...others}
              />
            </div>
            <div className="column">
              <MoneyInput
                name="vat.value"
                label="pages.back.orders.details.cart.vat"
                leftLabel="%"
                disableAutoComplete
                {...others}
              />
            </div>
          </div>
        </section>
        <footer className="modal-card-foot" style={{ justifyContent: 'flex-end' }}>
          <SubmitButton dirty={dirty} isSubmitting={isSubmitting} />
          <button type="button" className="button" disabled={isSubmitting} onClick={onCancel}>
            <Translated k="general.close" />
          </button>
        </footer>
      </form>
    )}
  />
);

export default CustomItemForm;
