import React from 'react';
import { AppContext } from '../../App';

const IconTooltip = ({ icon, tooltipText, untranslated }) => (
  <AppContext.Consumer>
    {({ translate }) => (
      <span
        className="icon tooltip"
        data-tooltip={untranslated === true ? tooltipText : translate(tooltipText)}
      >
        <i className={`fas ${icon}`} />
      </span>
    )}
  </AppContext.Consumer>
);

export default IconTooltip;
