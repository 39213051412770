import React, { Component, Fragment } from 'react';
import SharedOrderEdit from '../../shared/orders/OrderEditSteps';
import Translated from '../../../base/elements/Translated';

class OrderResume extends Component {
  constructor(props) {
    super(props);
    const { orderId, selectedUser } = props;


    this.state = {
      selectedUser,
      orderId,
    };
  }

  render() {
    const { handleClose, refreshResults } = this.props;
    const { selectedUser, orderId } = this.state;

    return (
      <Fragment>
        <header className="modal-card-head">
          <p className="modal-card-title">
            <Translated k="pages.back.orders.add" />
          </p>
          <button type="button" className="delete" aria-label="close" onClick={handleClose} />
        </header>
        <section className="modal-card-body">
          <SharedOrderEdit id={orderId} impersonatedUser={selectedUser} isInsideModal />
        </section>
        <footer className="modal-card-foot" style={{ justifyContent: 'flex-end' }}>
          <button type="button" className="button" onClick={() => { handleClose(); refreshResults(); }}>
            <Translated k="general.close" />
          </button>
        </footer>
      </Fragment>
    );
  }
}

export default OrderResume;
