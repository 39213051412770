import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import Translated from '../elements/Translated';
import constants from '../../utils/constants';
import Required from '../elements/Required';

class InputPassword extends Component {
  state = {
    show: false,
  };

  toggle = () => {
    const { show } = this.state;
    this.setState({ show: !show });
  };

  render() {
    const {
      name,
      label,
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      required,
    } = this.props;

    const { show } = this.state;

    return (
      <Fragment>
        {label && (
          <label className="label">
            <Translated k={label} />
            <Required required={required} />
          </label>
        )}
        <div className="field has-addons">
          <div className="control is-expanded">
            <input
              className={classNames('input', {
                'is-danger': errors[name] && touched[name],
              })}
              onChange={handleChange}
              onBlur={handleBlur}
              value={values[name]}
              name={name}
              type={show === false ? 'password' : 'text'}
            />
          </div>
          <div className="control">
            <button type="button" className="button" onClick={this.toggle} tabIndex={-1}>
              <span className="icon">
                {show === false ? <i className="fas fa-eye" /> : <i className="fas fa-eye-slash" />}
              </span>
            </button>
          </div>
        </div>
        {errors[name]
          && touched[name] && (
            <div className="input-password-error">
              <p className="help is-danger">
                <Translated k={errors[name]} p={constants.passwordLength} />
              </p>
            </div>
        )}
      </Fragment>
    );
  }
}

export default InputPassword;
