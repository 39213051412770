import * as Yup from 'yup';

const priceSchema = Yup.object().shape({
  currency: Yup.string()
    .required('general.validation.required')
    .default('EUR'),
  fractionDigits: Yup.number()
    .typeError('general.validation.number')
    .required('general.validation.required')
    .default(2),
  value: Yup.number()
    .typeError('general.validation.number')
    .required('general.validation.required')
    .default(0),
});

export default priceSchema;
