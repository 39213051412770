import React, {Fragment} from 'react';
import ButtonModal from '../../../base/components/modal/ButtonModal';
import BooleanOutput from '../../../base/elements/BooleanOutput';
import ColumnOutput from '../../../base/elements/ColumnOutput';
import DateOutput from '../../../base/elements/DateOutput';
import MoneyOutput from '../../../base/elements/MoneyOutput';
import Title from '../../../base/elements/Title';
import Translated from '../../../base/elements/Translated';
import ContactEdit from './contacts/ContactEdit';
import ContactsDetails from './contacts/ContactsDetails';

const OrderDetailsOverview = ({ data, handleSuccess, readOnly }) => (
  <Fragment>

    <Title label="pages.back.orders.details.description.order" size={4} section />
    <div className="box">
      <div className="columns">
        <ColumnOutput
          label="general.acknowledgment"
          render={() => <BooleanOutput value={data.acknowledgment} />}
        />
      </div>
      <div className="columns">
        <div className="column">
          <div className="columns">
            <ColumnOutput
              label="model.order.status"
              render={() => <Translated k={`model.order.orderStatus.${data.orderStatus}`} />}
            />
            <ColumnOutput
              label="model.order.creationDate"
              render={() => <DateOutput value={data.creationDate} />}
            />
            <ColumnOutput
              label="model.eventType.entityName"
              render={() => data.items
                .filter(i => i.type === 'purchase')
                .map(i => i.eventType.name)
                .join(', ')
                }
            />
          </div>
          <div className="columns">
            <ColumnOutput
              label="model.order.paymentStatus.entityName"
              render={() => <Translated k={`model.order.paymentStatus.${data.paymentStatus}`} />}
            />
            <ColumnOutput
              label="model.order.paymentMethod.entityName"
              render={() => (
                <div>
                  <Translated k={`model.order.paymentMethodTitle.${data.paymentMethod}`} />
                  {/*<ButtonModal*/}
                  {/*  buttonClassName="fas fa-pen"*/}
                  {/*  render={props => (*/}
                  {/*    <PaymentMethodEdit*/}
                  {/*      handleSuccess={handleSuccess}*/}
                  {/*      handleClose={props.handleClose}*/}
                  {/*      order={data}*/}
                  {/*    />*/}
                  {/*  )}*/}
                  {/*  card*/}
                  {/*  large*/}
                  {/*/>*/}
                </div>
              )}
            />
            <ColumnOutput
              label="model.eventPackage.deposit"
              render={() => <MoneyOutput money={data.totalDeposit} />}
            />
          </div>
          <div className="columns">
            <ColumnOutput
              label="model.order.totalAmount"
              render={() => <MoneyOutput money={data.totalAmount} />}
            />
            <ColumnOutput
              label="model.order.payedAmount"
              render={() => <MoneyOutput money={data.totalPaid} />}
            />
            <ColumnOutput
              label="model.order.toPay"
              render={() => <MoneyOutput money={data.toPay} />}
            />
          </div>
        </div>
      </div>
    </div>

    <Title label="model.contact.title" size={4} section />
    <ContactsDetails
      contacts={data.items[0].emergencyContacts}
      order={data}
      handleSuccess={handleSuccess}
      readOnly={readOnly}
    />
    <div className="field is-grouped is-grouped-right">
      <ButtonModal
        buttonClassName="is-default"
        buttonLabel="pages.back.contacts.add"
        render={props => (
          <ContactEdit
            handleSuccess={handleSuccess}
            handleClose={props.handleClose}
            order={data}
          />
        )}
        card
      />
    </div>

  </Fragment>
);

export default OrderDetailsOverview;
