import React, { Fragment } from 'react';
import classNames from 'classnames';
import Icon from '../../base/elements/Icon';
import Translated from '../../base/elements/Translated';

const OrderStepsNavigation = ({
  showLeft,
  showRight,
  onClickLeft,
  onClickRight,
  renderCenter,
  disabledLeft = false,
  disabledRight = false,
  loading,
  last,
}) => (
  <Fragment>
    {renderCenter === undefined && <hr className="compact" />}
    <nav className="level order-steps-navigation">
      <div className="level-left">
        <div className="level-item">
          {showLeft && (
            <button
              type="button"
              className={classNames('button', {
                'is-loading': loading,
              })}
              onClick={onClickLeft}
              disabled={disabledLeft}
            >
              <Icon name="fa-arrow-left" />
              <span>
                <Translated k="general.filters.previous" />
              </span>
            </button>
          )}
        </div>
      </div>
      {renderCenter && <div className="level-item">{renderCenter()}</div>}
      <div className="level-right">
        <p className="level-item">
          {showRight
            && (onClickRight ? (
              <button
                type="button"
                className={classNames('button', {
                  'is-info': last !== true,
                  'is-primary': last === true,
                  'is-loading': loading,
                })}
                onClick={onClickRight}
                disabled={disabledRight}
              >
                {last !== true && <Icon name="fa-arrow-right" />}
                <span>
                  <Translated k={last === true ? 'general.complete' : 'general.filters.next'} />
                </span>
              </button>
            ) : (
              <button
                type="submit"
                className={classNames('button', {
                  'is-info': last !== true,
                  'is-primary': last === true,
                  'is-loading': loading,
                })}
                disabled={disabledRight}
              >
                {last !== true && <Icon name="fa-arrow-right" />}
                <span>
                  <Translated k={last === true ? 'general.complete' : 'general.filters.next'} />
                </span>
              </button>
            ))}
        </p>
      </div>
    </nav>
    {renderCenter !== undefined && <hr />}
  </Fragment>
);

export default OrderStepsNavigation;
