import React, {Fragment} from 'react';
import Translated from '../../../base/elements/Translated';
import ColumnOutput from '../../../base/elements/ColumnOutput';
import DateOutput from '../../../base/elements/DateOutput';

const PersonDetails = ({ data }) => (
  <Fragment>
    <div>
      <div className="columns">
        <ColumnOutput label="model.person.firstName" render={() => data.firstName} />
        <ColumnOutput label="model.person.lastName" render={() => data.lastName} />
        {data.personType === 'JURIDICAL' && (
        <ColumnOutput
          label="model.person.registeredName"
          render={() => data.registeredName}
        />
        )}
        <ColumnOutput
          label="model.person.personType.entityName"
          render={() => <Translated k={`model.person.personType.${data.personType}`} />}
        />
      </div>
      <div className="columns">
        <ColumnOutput label="model.person.phoneNumber" render={() => data.phoneNumber} />
        <ColumnOutput label="model.person.phoneNumber2" render={() => data.phoneNumber2} />
        <ColumnOutput label="model.person.phoneNumber3" render={() => data.phoneNumber3} />
        <ColumnOutput label="model.person.phoneNumber4" render={() => data.phoneNumber4} />
      </div>
      <div className="columns">
        <ColumnOutput label="model.person.email" render={() => data.email} />
        <ColumnOutput
          label="model.person.dob"
          render={() => <DateOutput value={data.dateOfBirth} />}
        />
        <ColumnOutput label="model.person.gender" render={() => data.personGender} />
        <ColumnOutput label="model.person.ssnCode" render={() => data.ssnCode} />
        {data.personType === 'JURIDICAL' && (
        <ColumnOutput label="model.person.vatId" render={() => data.vatId} />
        )}
      </div>
      <div className="columns">
        <ColumnOutput
          label="model.person.address.street"
          render={() => (data.address !== undefined ? data.address.street : '')}
        />
        <ColumnOutput
          label="model.person.address.number"
          render={() => (data.address !== undefined ? data.address.number : '')}
        />
        <ColumnOutput
          label="model.person.address.district"
          render={() => (data.address !== undefined ? data.address.district : '')}
        />
      </div>
      <div className="columns">
        <ColumnOutput
          label="model.person.address.code"
          render={() => (data.address !== undefined ? data.address.code : '')}
        />
        <ColumnOutput
          label="model.person.address.city"
          render={() => (data.address !== undefined ? data.address.city : '')}
        />
        <ColumnOutput
          label="model.person.address.country"
          render={() => (data.address !== undefined ? <Translated k={`model.country.${data.address.country}`} /> : '')}
        />
      </div>
    </div>
  </Fragment>
);

export default PersonDetails;
