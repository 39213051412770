import Get from 'lodash.get';

const translate = (messages, key, ...params) => {
  let result = Get(messages, key, '');
  params.forEach((a, i) => {
    result = result.replace(`{${i}}`, a !== null && a !== undefined ? a : '');
  });
  return result;
};

export default translate;
