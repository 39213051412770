import React from 'react';
import WithRemoteData from '../../utils/WithRemoteData';
import Card from '../../base/components/Card';
import Sample1 from '../../../images/samples/event-sample-1.jpeg';
import EventTypeDetails from './EventTypeDetails';
import Title from '../../base/elements/Title';
import appUrl from '../../utils/remote';

const getImage = (eventType) => {
  if (eventType.uploadedImage !== null && eventType.uploadedImage !== undefined) {
    return appUrl(`/attachments/${eventType.uploadedImage.attachmentUuid}`);
  }
  return Sample1;
};

const HomePage = () => (
  <div className="section main-page">
    <div className="container">
      <Title size={3} label="pages.front.home.events" section />
      <WithRemoteData
        url="/fo/eventTypes"
        render={({ loading, data }) => loading === false
          && data && (
            <div className="row is-multiline">
              {data.map(d => (
                <div className="box" key={d.id}>
                  <Card
                    imgUrl={getImage(d)}
                    title={d.name}
                    description={d.description}
                    render={({ handleClose }) => (
                      <EventTypeDetails data={d} handleClose={handleClose} />
                    )}
                  />
                </div>
              ))}
            </div>
        )
        }
      />
    </div>
  </div>
);

export default HomePage;
