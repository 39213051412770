import React, { Component, Fragment } from 'react';
import classNames from 'classnames';

class Modal extends Component {
  state = {
    visible: false,
  };

  open = () => this.setState({ visible: true });

  close = () => this.setState({ visible: false });

  toggle = () => {
    const { visible } = this.state;
    this.setState({ visible: !visible });
  };

  render() {
    const {
      render, renderTrigger, card = false, large = false,
    } = this.props;
    const { visible } = this.state;
    const className = classNames('modal', {
      'is-active': visible,
      'is-large': large,
    });

    return (
      <Fragment>
        <div className={className}>
          <div className="modal-background" />
          {card === true ? (
            <div className="modal-card">{visible && render({ handleClose: this.close })}</div>
          ) : (
            <Fragment>
              <div className="modal-content">{visible && render({ handleClose: this.close })}</div>
              <button
                type="button"
                className="modal-close is-large"
                aria-label="close"
                onClick={this.close}
              />
            </Fragment>
          )}
        </div>
        {renderTrigger({ handleOpen: this.open })}
      </Fragment>
    );
  }
}

export default Modal;
