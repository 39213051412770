import React, { Fragment } from 'react';
import classNames from 'classnames';
import Translated from '../elements/Translated';
import Icon from '../elements/Icon';

const SubmitButton = ({
  isSubmitting, isLoading, primary = true, icon, label = 'general.confirm',
}) => (
  <button
    type="submit"
    className={classNames('button', {
      'is-primary': primary,
      'is-info': !primary,
      'is-loading': isSubmitting || isLoading,
    })}
    disabled={isSubmitting}
  >
    {icon ? (
      <Fragment>
        <Icon name={icon} />
        <span>
          <Translated k={label} />
        </span>
      </Fragment>
    ) : (
      <Translated k={label} />
    )}
  </button>
);

export default SubmitButton;
