import React, { Component, Fragment } from 'react';
import Axios from 'axios';
import Translated from '../../../base/elements/Translated';
import appUrl from '../../../utils/remote';
import ErrorNotification from '../../../base/elements/ErrorNotification';
import CustomItemAdd from '../../../pages/back/orders/cart/CustomItemAdd';
import CustomOrderItem from '../../../pages/back/orders/cart/CustomOrderItem';
import CartOrderPartecipant from '../../../pages/back/orders/cart/CartOrderPartecipant';
import OrderPartecipantEditForm from '../partecipants/OrderPartecipantEditForm';
import Modal from '../../../base/components/modal/Modal';
import ButtonIcon from '../../../base/elements/ButtonIcon';
import OrderPricing from '../OrderPricing';

class CartForm extends Component {
  constructor(props) {
    super(props);

    const { initialValues } = props;

    this.state = {
      order: initialValues,
    };
  }

  addPartecipant = (partecipant) => {
    const { order } = this.state;
    const { partecipants } = order.items[0];

    const modifiedOrder = {
      ...order,
      items: [
        {
          ...order.items[0],
          partecipants: [...partecipants, partecipant],
        },
        ...order.items.slice(1),
      ],
    };

    this.updateOrder(modifiedOrder);
  };

  removePartecipant = (partecipant) => {
    const { order } = this.state;
    const { partecipants } = order.items[0];
    const partecipantIdx = partecipants.indexOf(partecipant);

    const modifiedOrder = {
      ...order,
      items: [
        {
          ...order.items[0],
          partecipants: [
            ...partecipants.slice(0, partecipantIdx),
            ...partecipants.slice(partecipantIdx + 1),
          ],
        },
        ...order.items.slice(1),
      ],
    };

    this.updateOrder(modifiedOrder);
  };

  addPartecipations = (newPartecipations, partecipant, callback) => {
    const { order } = this.state;
    const { partecipants } = order.items[0];
    const partecipantIdx = partecipants.indexOf(partecipant);
    const { partecipations } = partecipants[partecipantIdx];

    const modifiedOrder = {
      ...order,
      items: [
        {
          ...order.items[0],
          partecipants: [
            ...partecipants.slice(0, partecipantIdx),
            {
              ...partecipants[partecipantIdx],
              partecipations: [...partecipations, ...newPartecipations],
            },
            ...partecipants.slice(partecipantIdx + 1),
          ],
        },
        ...order.items.slice(1),
      ],
    };

    this.updateOrder(modifiedOrder);

    callback();
  };

  removePartecipations = (eventPartecipations, partecipant) => {
    const { order } = this.state;
    const { partecipants } = order.items[0];
    const toRemove = [].concat(eventPartecipations || []).map(pa => pa.uuid);
    const partecipantIdx = partecipants.indexOf(partecipant);
    const { partecipations } = partecipants[partecipantIdx];

    const modifiedOrder = {
      ...order,
      items: [
        {
          ...order.items[0],
          partecipants: [
            ...partecipants.slice(0, partecipantIdx),
            {
              ...partecipants[partecipantIdx],
              partecipations: partecipations.filter(pa => toRemove.indexOf(pa.uuid) < 0),
            },
            ...partecipants.slice(partecipantIdx + 1),
          ],
        },
        ...order.items.slice(1),
      ],
    };

    this.updateOrder(modifiedOrder);
  };

  updateOrder = async (order) => {
    try {
      const orderId = order.id;

      const response = await Axios.put(appUrl(`/orders/${orderId}`), order);
      this.setState({
        order: response.data,
      });
    } catch (err) {
      this.setState({
        loading: false,
        error:
          err.response !== undefined
            ? `${err.response.data.message}`
            : err.message,
      });
    }
  };

  addCustomOrderItem = (item) => {
    const { order } = this.state;

    const modifiedOrder = {
      ...order,
      items: [...order.items, item],
    };

    this.updateOrder(modifiedOrder);
  };

  removeCustomOrderItem = (item) => {
    const { order } = this.state;
    const itemIdx = order.items.indexOf(item);

    const modifiedOrder = {
      ...order,
      items: [...order.items.slice(0, itemIdx), ...order.items.slice(itemIdx + 1)],
    };

    this.updateOrder(modifiedOrder);
  };

  render() {
    const {
      order,
      error,
      loading,
    } = this.state;
    const { onSuccess, readOnly } = this.props;

    return (
      <Fragment>
        {loading === false && error && (
          <ErrorNotification
            clearError={() => this.setState({ error: null })}
            errors={{ failed: error }}
          />
        )}
        <div className="columns">
          <div className="column">
            {order.items[0].partecipants.map(p => (
              <CartOrderPartecipant
                key={p.uuid}
                partecipant={p}
                order={order}
                handleRemovePartecipations={this.removePartecipations}
                handleAddPartecipations={this.addPartecipations}
                handleRemovePartecipant={this.removePartecipant}
                readOnly={readOnly}
              />
            ))}
            {!readOnly && (
              <div className="field is-grouped is-grouped-right">
                <Modal
                  buttonClassName="is-primary"
                  buttonLabel="pages.shared.orders.partecipants.add"
                  render={({ handleClose }) => (
                    <Fragment>
                      <header className="modal-card-head">
                        <p className="modal-card-title">
                          <Translated k="pages.shared.orders.partecipants.add" />
                        </p>
                        <button
                          type="button"
                          className="delete"
                          aria-label="close"
                          onClick={handleClose}
                        />
                      </header>
                      <OrderPartecipantEditForm
                        onSuccess={(p) => {
                          this.addPartecipant(p);
                          handleClose();
                        }}
                        onCancel={handleClose}
                      />
                    </Fragment>
                  )}
                  renderTrigger={({ handleOpen }) => (
                    <ButtonIcon
                      handleClick={handleOpen}
                      icon="fa-plus"
                      label="pages.shared.orders.partecipants.add"
                      small
                    />
                  )}
                  card
                />
              </div>
            )}
            {order.items.map(
              item => item.type === 'custom' && (
              <CustomOrderItem
                key={item.uuid}
                data={item}
                handleRemoveItem={this.removeCustomOrderItem}
              />
              ),
            )}
            {!readOnly && (
              <div className="field is-grouped is-grouped-right">
                <Modal
                  buttonClassName="is-primary"
                  buttonLabel="pages.back.orders.details.cart.item.add"
                  render={({ handleClose }) => (
                    <CustomItemAdd
                      handleClose={handleClose}
                      handleAddItem={this.addCustomOrderItem}
                      handleSuccess={onSuccess}
                    />
                  )}
                  renderTrigger={({ handleOpen }) => (
                    <ButtonIcon
                      handleClick={handleOpen}
                      icon="fa-plus"
                      label="pages.back.orders.details.cart.item.add"
                      small
                    />
                  )}
                  card
                />
              </div>
            )}
          </div>
          <div className="column is-one-third">
            <OrderPricing order={order} />
          </div>
        </div>
      </Fragment>
    );
  }
}

export default CartForm;
