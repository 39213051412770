import React from 'react';
import Dropzone from 'react-dropzone';
import classNames from 'classnames';

const InputUpload = ({
  upload, accept, text, isLoading,
}) => (
  <Dropzone
    onDrop={upload}
    accept={accept}
    disableClick
  >
    {({ getRootProps, getInputProps, open }) => (
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <button
          type="button"
          className={classNames('button is-primary', {
            'is-loading': isLoading,
          })}
          onClick={() => open()}
        >
          {text}
        </button>
      </div>
    )}
  </Dropzone>
);

export default InputUpload;
