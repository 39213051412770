import React, { Fragment } from 'react';
import { Formik } from 'formik';
import Axios from 'axios';
import * as Yup from 'yup';
import InputPassword from '../base/form/InputPassword';
import Translated from '../base/elements/Translated';
import appUrl from '../utils/remote';
import constants from '../utils/constants';
import SubmitButton from '../base/form/SubmitButton';
import ErrorNotification from '../base/elements/ErrorNotification';

const ChangePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .min(constants.passwordLength, 'general.validation.password.length')
    .required('general.validation.required')
    .default(''),
  newPassword: Yup.string()
    .min(constants.passwordLength, 'general.validation.password.length')
    .notOneOf([Yup.ref('oldPassword')], 'general.validation.password.matchWithOld')
    .required('general.validation.required')
    .default(''),
  newPasswordRepeat: Yup.string()
    .min(constants.passwordLength, 'general.validation.password.length')
    .oneOf([Yup.ref('newPassword')], 'general.validation.password.match')
    .required('general.validation.required')
    .default(''),
});

const ChangePasswordFormBack = ({ onSuccess, handleClose }) => (
  <Formik
    initialValues={ChangePasswordSchema.cast({})}
    validationSchema={ChangePasswordSchema}
    onSubmit={async ({ oldPassword, newPassword }, actions) => {
      try {
        await Axios.put(appUrl('/auth/passwordChange'), { oldPassword, newPassword });
        onSuccess();
      } catch (err) {
        actions.setErrors({
          failed: err.response !== undefined ? `${err.response.data.message}` : err.message,
        });
      }
      actions.setSubmitting(false);
    }}
    render={({
      dirty, isSubmitting, handleSubmit, ...others
    }) => (
      <Fragment>
        <header className="modal-card-head">
          <p className="modal-card-title">
            <Translated k="model.user.passwordChange" />
          </p>
          <button type="button" className="delete" aria-label="close" onClick={handleClose} />
        </header>
        <form onSubmit={handleSubmit}>
          <section className="modal-card-body">
            <ErrorNotification {...others} />
            <InputPassword name="oldPassword" label="model.user.password" {...others} />
            <InputPassword name="newPassword" label="pages.auth.form.newPassword" {...others} />
            <InputPassword
              name="newPasswordRepeat"
              label="pages.auth.form.passwordRepeat"
              {...others}
            />
          </section>
          <footer className="modal-card-foot" style={{ justifyContent: 'flex-end' }}>
            <SubmitButton dirty={dirty} isSubmitting={isSubmitting} />
            <button type="button" className="button" disabled={isSubmitting} onClick={handleClose}>
              <Translated k="general.close" />
            </button>
          </footer>
        </form>
      </Fragment>
    )}
  />
);

export default ChangePasswordFormBack;
