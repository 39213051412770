/* eslint
jsx-a11y/click-events-have-key-events: "off",
jsx-a11y/no-noninteractive-element-to-interactive-role: "off"
*/
import React from 'react';
import classNames from 'classnames';
import Translated from '../../elements/Translated';

// TODO se è unreachable tooltip
// TODO se posso arrivarci, cliccabile e con listener
const ClickableStep = ({
  id,
  handleClick,
  last,
  className,
  iconName,
  totalSteps,
  hideExtraData = true,
}) => (
  <li
    role="button"
    onClick={() => handleClick(id)}
    className={`steps-segment clickable ${className}`}
  >
    <span className="steps-marker">
      <span className="icon">
        <i className={`fas ${iconName}`} />
      </span>
    </span>
    <div className="steps-content">
      <Translated k={`pages.shared.orders.steps.${id}`} />
    </div>
    {!hideExtraData && last && id !== totalSteps && (
      <div className="extra-data is-size-7">
        <Translated k="pages.shared.orders.steps.unreachable" />
      </div>
    )}
  </li>
);

const UnreachableStep = ({ id, iconName }) => (
  <li className="steps-segment is-dashed">
    <span className="steps-marker">
      <span className="icon">
        <i className={`fas ${iconName}`} />
      </span>
    </span>
    <div className="steps-content">
      <Translated k={`pages.shared.orders.steps.${id}`} />
    </div>
  </li>
);

const Step = ({
  id, activeId, reachableId, iconName, handleClick, totalSteps,
}) => (reachableId >= id ? (
  <ClickableStep
    id={id}
    handleClick={handleClick}
    iconName={iconName}
    className={classNames({
      'is-active': activeId === id,
      'is-dashed': reachableId <= id,
    })}
    last={reachableId === id}
    totalSteps={totalSteps}
  />
) : (
  <UnreachableStep id={id} iconName={iconName} />
));

export default Step;
