import React from 'react';
import { Switch, Route } from 'react-router-dom';
import HomePage from '../pages/front/HomePage';
import NotFound from '../pages/NotFound';
import OrderEdit from '../pages/front/OrderEdit';
import OrderCompletePage from '../pages/front/OrderCompletePage';
import OrderListPage from '../pages/front/OrderListPage';

const FrontOfficeRouter = () => (
  <Switch>
    <Route path="/" exact component={HomePage} />
    <Route path="/orders/:id([0-9]*)" exact component={OrderEdit} />
    <Route path="/complete" exact component={OrderCompletePage} />
    <Route path="/orders" exact component={OrderListPage} />
    <Route component={NotFound} />
  </Switch>
);

export default FrontOfficeRouter;
