import React from 'react';
import WithRemoteData from '../../utils/WithRemoteData';
import InputSelect from './InputSelect';

const RemoteInputSelect = ({ url, ...others }) => (
  <WithRemoteData
    url={url}
    render={({ loading, data }) => (
      <InputSelect
        loading={loading}
        options={
          loading === false && data
            ? data.map(({ value, label }) => ({ value, label: label || value }))
            : []
        }
        translateLabel
        {...others}
      />
    )}
  />
);

export default RemoteInputSelect;
