/* eslint react/prefer-stateless-function: "off" */
import React, { Component } from 'react';
import classNames from 'classnames';
import OrderPartecipation from './OrderPartecipation';
import Translated from '../../../base/elements/Translated';
import MoneyOutput from '../../../base/elements/MoneyOutput';
import OrderPartecipationEditForm from './OrderPartecipationEditForm';
import Modal from '../../../base/components/modal/Modal';
import ButtonIcon from '../../../base/elements/ButtonIcon';
import Title from '../../../base/elements/Title';
import ErrorNotification from '../../../base/elements/ErrorNotification';
import { AppContext } from '../../../App';

const getEventIds = (partecipations) => {
  const result = [];
  partecipations.forEach((p) => {
    if (result.indexOf(p.eventPackage.eventId) < 0) {
      result.push(p.eventPackage.eventId);
    }
  });

  result.sort();
  return result;
};

class OrderPartecipant extends Component {
  constructor(props) {
    super(props);

    const { partecipant } = this.props;

    this.state = {
      totalAmount: partecipant.totalAmount,
      error: null,
    };
  }

  componentWillReceiveProps = ({ partecipant }) => {
    this.updateTotal(partecipant);
  };

  updateTotal = async (partecipant) => {
    this.setState({
      totalAmount: partecipant.totalAmount,
    });
  };

  render() {
    const {
      partecipant,
      order,
      handleAddPartecipations,
      handleRemovePartecipations,
      readOnly,
      small = false,
    } = this.props;

    const { totalAmount, error } = this.state;

    const { partecipations } = partecipant;
    const eventIds = getEventIds(partecipations);

    return (
      <div className="box has-background-light">
        <ErrorNotification
          clearError={() => this.setState({ error: null })}
          errors={{ failed: error }}
        />
        <div className="columns compact">
          <div className="column">
            <Title
              size={3}
              label={`${partecipant.person.firstName} ${partecipant.person.lastName}`}
              untranslated
            />
          </div>
        </div>
        {partecipations.length > 0 && <hr className="compact has-background-grey-lighter" />}
        {eventIds.length > 0
          && eventIds.map(eventId => (
            <OrderPartecipation
              key={eventId}
              partecipations={partecipations.filter(p => p.eventPackage.eventId === eventId)}
              partecipant={partecipant}
              handleRemove={handleRemovePartecipations}
              readOnly={readOnly}
              small={small}
            />
          ))}
        <hr className="compact has-background-grey-lighter" />
        <div className="columns compact has-text-weight-bold" style={{ paddingLeft: '1rem' }}>
          <div className="column is-four-fifths">
            <div className="columns">
              <div
                className={classNames('column', {
                  'is-two-thirds': small,
                })}
              >
                <Translated k="general.total" />
              </div>
              <div className="column">
                <MoneyOutput money={totalAmount} />
              </div>
            </div>
          </div>
        </div>
        <AppContext.Consumer>
          {({ isBackOffice }) => (
            <div>
              {readOnly !== true && (
              <div className="form-buttons">
                <Modal
                  buttonClassName="is-default"
                  buttonLabel="pages.shared.orders.partecipation.add"
                  render={({ handleClose }) => (
                    <OrderPartecipationEditForm
                      eventTypeId={order.items[0].eventType.id}
                      partecipant={partecipant}
                      handleCancel={handleClose}
                      handleSubmit={handleAddPartecipations}
                      updateTotal={this.updateTotal}
                      totalAmount={totalAmount}
                      isBackOffice={isBackOffice}
                    />
                  )}
                  renderTrigger={({ handleOpen }) => (
                    <ButtonIcon
                      handleClick={handleOpen}
                      icon="fa-plus"
                      label="pages.shared.orders.partecipation.add"
                      medium
                      primary
                    />
                  )}
                  card
                />
              </div>
              )}
            </div>
          )
      }
        </AppContext.Consumer>
      </div>
    );
  }
}

export default OrderPartecipant;
