import React from 'react';
import Translated from '../../../base/elements/Translated';
import CrudList from '../../../utils/CrudList';
import FilterForm from '../../../forms/FilterForm';
import InputText from '../../../base/form/InputText';
import InputSelect from '../../../base/form/InputSelect';
import Pagination from '../../../base/components/pagination/Pagination';
import OrderableColumn from '../../../base/elements/OrderableColumn';
import ButtonModal from '../../../base/components/modal/ButtonModal';
import PartecipantEmailExport from './PartecipantEmailExport';
import DateOutput from '../../../base/elements/DateOutput';
import RemoteEntityInputSelect from '../../../base/form/RemoteEntityInputSelect';
import Title from '../../../base/elements/Title';
import IconModal from '../../../base/components/modal/IconModal';
import PartecipantDetails from './PartecipantDetails';
import BooleanOutput from '../../../base/elements/BooleanOutput';
import appUrl, {downloadFile} from "../../../utils/remote";
import buildUrl from "../../../utils/filter";
import InputNumber from "../../../base/form/InputNumber";


const PartecipantList = () => (
  <CrudList
    initialSorting={{sort: '', asc: true}}
    initialFilter={{fullSearch: '', maxResults: 10}}
    restUrl="/eventPackagePartecipations"
    render={({
               loading,
               data,
               error,
               params,
               filters,
               initialFilter,
               handleNavigation,
               updateFilters,
               changeOrdering,
               refreshResults,
             }) => (
      <div className="section main-page">
        <div className="container">
          <div className="level">
            <div className="level-left">
              <Title size={3} label="model.partecipant.entityNamePlural"/>
            </div>
            <div className="level-right">
              <div className="columns">
                <div className="column">
                  <button
                    type="button"
                    onClick={() => {
                      downloadFile('iscrizioni', buildUrl(appUrl('/eventPackagePartecipations/export/xls'), filters), "vnd.ms-excel");
                    }}
                    className="button">
                    <Translated k="pages.back.partecipants.exportXls.title"/>
                  </button>
                </div>
                <div className="column">
                  <ButtonModal
                    buttonLabel="pages.back.partecipants.exportEmails.title"
                    render={({handleClose}) => (
                      <PartecipantEmailExport handleClose={handleClose} filters={filters}/>
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="section-title">
            <FilterForm
              onSubmit={updateFilters}
              initialValues={initialFilter}
              render={({...props}) => (
                <div>
                  <div className="columns">
                    <div className="column is-three-fifths">
                      <InputText
                        name="fullSearch"
                        label="general.filters.fulltextSearch"
                        {...props}
                        disableAutoComplete
                      />
                    </div>
                    <div className="column">
                      <InputNumber name="year" label="general.filters.year" {...props} />
                    </div>
                    <div className="column">
                      <InputSelect
                        name="maxResults"
                        label="general.filters.numOfResults"
                        options={[
                          {value: 10, label: 10},
                          {value: 20, label: 20},
                          {value: 50, label: 50},
                        ]}
                        isSearchable={false}
                        {...props}
                      />
                    </div>
                  </div>
                  <div>
                    <div className="columns">
                      <div className="column">
                        <RemoteEntityInputSelect
                          name="typeId"
                          label="model.eventType.entityName"
                          url="/eventTypes"
                          isClearable
                          {...props}
                        />
                      </div>
                      <div className="column is-two-fifths">
                        <RemoteEntityInputSelect
                          name="eventIds"
                          label="model.event.entityName"
                          url="/events"
                          filters={{
                            typeId: props.values.typeId,
                            year: props.values.year
                          }}
                          isDisabled={!props.values.typeId}
                          isClearable
                          isMulti
                          {...props}
                        />
                      </div>
                      <div className="column is-two-fifths">
                        <RemoteEntityInputSelect
                          name="subEventIds"
                          label="model.eventPackage.entityName"
                          url="/eventPackages"
                          filters={{eventIds: props.values.eventIds}}
                          isDisabled={(Array.isArray(props.values.eventIds) && props.values.eventIds.length === 0) || !props.values.eventIds}
                          isClearable
                          isMulti
                          {...props}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            />
          </div>
          {loading === false && error && <div className="notification is-danger">{error}</div>}
          <div className="table-container">
            {loading === true && <div className="loading"/>}
            <table className="table is-hoverable is-bordered is-fullwidth">
              <thead className="has-background-white-ter">
              <tr>
                <OrderableColumn name="FIRSTNAME" handleClick={changeOrdering} {...params}>
                  <Translated k="model.person.firstName"/>
                </OrderableColumn>
                <OrderableColumn name="LASTNAME" handleClick={changeOrdering} {...params}>
                  <Translated k="model.person.lastName"/>
                </OrderableColumn>
                <OrderableColumn name="EVENT_TYPE" handleClick={changeOrdering} {...params}>
                  <Translated k="model.eventType.entityName"/>
                </OrderableColumn>
                <OrderableColumn name="EVENT" handleClick={changeOrdering} {...params}>
                  <Translated k="model.event.entityName"/>
                </OrderableColumn>
                <OrderableColumn name="EVENT_PACKAGE" handleClick={changeOrdering} {...params}>
                  <Translated k="model.eventPackage.entityName"/>
                </OrderableColumn>
                <OrderableColumn name="CREATION_DATE" handleClick={changeOrdering} {...params}>
                  <Translated k="model.order.creationDate"/>
                </OrderableColumn>
                <OrderableColumn name="USER" handleClick={changeOrdering} {...params}>
                  <Translated k="model.order.author"/>
                </OrderableColumn>
                <OrderableColumn name="RELATIONSHIP" handleClick={changeOrdering} {...params}>
                  <Translated k="model.partecipant.relationship"/>
                </OrderableColumn>
                <OrderableColumn name="IMAGES_CONSENT" handleClick={changeOrdering} {...params}>
                  <Translated k="general.privacy.imagesConsentShort"/>
                </OrderableColumn>
                <th>
                  <Translated k="general.actions"/>
                </th>
              </tr>
              </thead>
              <tbody>
              {data
              && data.elements.map(e => (
                <tr key={e.id}>
                  <td>{e.partecipant.person.firstName}</td>
                  <td>{e.partecipant.person.lastName}</td>
                  <td>{e.typeName}</td>
                  <td>{e.eventPackage.eventName}</td>
                  <td>{e.eventPackage.name}</td>
                  <td>
                    <DateOutput value={e.order.creationDate}/>
                  </td>
                  <td>
                    {e.order.author.person.firstName}
                    {' '}
                    {e.order.author.person.lastName}
                    <br/>
                    {e.order.author.person.email}
                  </td>
                  <td>{e.partecipant.relationship}</td>
                  <td><BooleanOutput value={e.partecipant.person.imagesConsent}/></td>
                  <td>
                    <div className="buttons">
                      <IconModal
                        icon="fa-search"
                        tooltipText="general.view"
                        render={({handleClose}) => (
                          <PartecipantDetails
                            handleClose={handleClose}
                            handleSuccess={refreshResults}
                            partecipant={e.partecipant}
                            order={e.order}
                          />
                        )}
                        card
                      />
                    </div>
                  </td>
                </tr>
              ))}
              </tbody>
            </table>
          </div>
          <Pagination
            loading={loading}
            data={data}
            startPosition={params.startPosition}
            maxResults={filters.maxResults}
            handleNavigation={handleNavigation}
          />
        </div>
      </div>
    )}
  />
);

export default PartecipantList;
