/* eslint react/prefer-stateless-function: "off" */
import React, { Component } from 'react';
import classNames from 'classnames';
import Title from '../../base/elements/Title';
import MoneyOutput from '../../base/elements/MoneyOutput';
import Translated from '../../base/elements/Translated';
import CartOrderPartecipationFo from './CartOrderPartecipationFo';

const getEventIds = (partecipations) => {
  const result = [];
  partecipations.forEach((p) => {
    if (result.indexOf(p.eventPackage.eventId) < 0) {
      result.push(p.eventPackage.eventId);
    }
  });

  result.sort();
  return result;
};

class CartOrderPartecipantFo extends Component {
  render() {
    const {
      partecipant,
      handleRemovePartecipations,
      small = false,
    } = this.props;
    const { partecipations } = partecipant;
    const eventIds = getEventIds(partecipations);

    return (
      <div className="box has-background-light">
        <div className="columns compact">
          <div className="column">
            <Title
              size={4}
              label={`${partecipant.person.firstName} ${partecipant.person.lastName}`}
              untranslated
            />
          </div>
        </div>
        {partecipations.length > 0 && <hr className="compact has-background-grey-lighter" />}
        {eventIds.length > 0
          && eventIds.map(eventId => (
            <CartOrderPartecipationFo
              key={eventId}
              partecipations={partecipations.filter(p => p.eventPackage.eventId === eventId)}
              partecipant={partecipant}
              handleRemove={handleRemovePartecipations}
              small={small}
            />
          ))}
        <hr className="compact has-background-grey-lighter" />
        <div className="columns compact has-text-weight-bold" style={{ paddingLeft: '1rem' }}>
          <div className="column is-four-fifths">
            <div className="columns">
              <div
                className={classNames('column', {
                  'is-two-thirds': small,
                })}
              >
                <Translated k="general.total" />
              </div>
              <div className="column">
                <MoneyOutput money={partecipant.totalAmount} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CartOrderPartecipantFo;
