import React, {Fragment} from 'react';
import WithRemoteData from '../../../utils/WithRemoteData';
import Translated from '../../../base/elements/Translated';
import BankAccountForm from "../../../forms/BankAccountForm";

const BankAccountEdit = ({ handleSuccess, handleClose, resourceId }) => (
    <WithRemoteData
        url={`/bankAccounts/${resourceId}`}
        render={({ loading, data }) => loading === false
            && data !== null && (
                <Fragment>
                    <header className="modal-card-head">
                        <p className="modal-card-title">
                            <Translated k="pages.back.bankAccounts.edit" />
                        </p>
                        <button type="button" className="delete" aria-label="close" onClick={handleClose} />
                    </header>
                    <BankAccountForm
                        onCancel={handleClose}
                        onSuccess={() => {
                            handleSuccess();
                            handleClose();
                        }}
                        initialValues={data}
                        resourceId={resourceId}
                    />
                </Fragment>
            )
        }
    />
);

export default BankAccountEdit;
