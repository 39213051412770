import React, {Fragment} from 'react';
import WithRemoteData from '../../../utils/WithRemoteData';
import Translated from '../../../base/elements/Translated';
import ColumnOutput from '../../../base/elements/ColumnOutput';
import ButtonModal from '../../../base/components/modal/ButtonModal';
import BankAccountEdit from "./BankAccountEdit";

const BankAccountDetails = ({resourceId, handleClose, handleSuccess}) => (
    <WithRemoteData
        url={`/bankAccounts/${resourceId}`}
        render={({loading, data, refresh}) => loading === false
            && data !== null && (
                <Fragment>
                    <header className="modal-card-head">
                        <p className="modal-card-title">
                            <Translated k="pages.back.bankAccounts.details"/>
                        </p>
                        <button type="button" className="delete" aria-label="close" onClick={handleClose}/>
                    </header>
                    <section className="modal-card-body">
                        <div className="columns">
                            <div className="column">
                                <div className="columns">
                                    <ColumnOutput label="model.bankAccount.iban" render={() => data.iban}/>
                                </div>
                                <div className="columns">
                                    <ColumnOutput label="model.bankAccount.name" render={() => data.name}/>
                                </div>
                            </div>
                        </div>
                    </section>
                    <footer className="modal-card-foot" style={{justifyContent: 'flex-end'}}>
                        <ButtonModal
                            buttonClassName="is-primary"
                            buttonLabel="general.edit"
                            render={innerModalData => (
                                <BankAccountEdit
                                    resourceId={resourceId}
                                    handleClose={innerModalData.handleClose}
                                    handleSuccess={() => {refresh(); handleSuccess();}}
                                />
                            )}
                            card
                        />
                        <button type="button" className="button" onClick={handleClose}>
                            <Translated k="general.close"/>
                        </button>
                    </footer>
                </Fragment>
            )
        }
    />
);

export default BankAccountDetails;
