import React from 'react';
import Modal from './Modal';
import Translated from '../../elements/Translated';

const ButtonModal = ({ buttonClassName, buttonLabel, disabled = false, ...others }) => (
  <Modal
    renderTrigger={({ handleOpen }) => (
      <button type="button" className={`button ${buttonClassName}`} onClick={handleOpen} disabled={disabled}>
        <Translated k={buttonLabel} />
      </button>
    )}
    {...others}
  />
);

export default ButtonModal;
