import React, { Component, Fragment } from 'react';
import Axios from 'axios';
import SharedOrderEdit from '../../shared/orders/OrderEditSteps';
import appUrl from '../../../utils/remote';
import Translated from '../../../base/elements/Translated';
import OrderAuthorForm from '../../../forms/orders/backoffice/OrderAuthorForm';

class OrderAdd extends Component {
  state = {
    selectedUser: null,
    orderId: null,
  };

  createOrder = async (formData, actions) => {
    try {
      const { data } = await Axios.post(
        appUrl(`/fo/orders?eventTypeId=${formData.eventTypeId}`),
        {},
        {
          headers: {
            'x-impersonate-user': formData.userId,
          },
        },
      );

      this.setState(
        {
          orderId: data.id,
          selectedUser: formData.userId,
        },
        () => actions.setSubmitting(false),
      );
    } catch (err) {
      actions.setErrors({ failed: (err.response !== undefined) ? `${err.response.data.message}` : err.message });
      actions.setSubmitting(false);
    }
  };

  render() {
    const { handleClose, refreshResults } = this.props;
    const { selectedUser, orderId } = this.state;

    return (
      <Fragment>
        <header className="modal-card-head">
          <p className="modal-card-title">
            <Translated k="pages.back.orders.add" />
          </p>
          <button type="button" className="delete" aria-label="close" onClick={handleClose} />
        </header>
        <section className="modal-card-body">
          {orderId !== null ? (
            <SharedOrderEdit id={orderId} impersonatedUser={selectedUser} isInsideModal />
          ) : (
            <OrderAuthorForm onSubmit={this.createOrder} />
          )}
        </section>
        <footer className="modal-card-foot" style={{ justifyContent: 'flex-end' }}>
          <button type="button" className="button" onClick={() => { handleClose(); refreshResults(); }}>
            <Translated k="general.close" />
          </button>
        </footer>
      </Fragment>
    );
  }
}

export default OrderAdd;
