import React from 'react';
import { Formik } from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
import SubmitButton from '../../../base/form/SubmitButton';
import ErrorNotification from '../../../base/elements/ErrorNotification';
import InputDate from '../../../base/form/InputDate';
import Translated from '../../../base/elements/Translated';
import appUrl from '../../../utils/remote';
import InputTextarea from '../../../base/form/InputTextarea';
import momentDate from '../../utils/momentSchema';
import constants from '../../../utils/constants';
import MoneyInput from '../../../base/elements/MoneyInput';
import priceSchema from "../../utils/priceSchema";

const PaymentFormSchema = Yup.object().shape({
  date: momentDate().required('general.validation.required'),
  amount: priceSchema,
});

const PaymentForm = ({
  onSuccess, onCancel, initialValues = {}, resourceId, paymentId,
}) => (
  <Formik
    initialValues={PaymentFormSchema.cast(initialValues)}
    validationSchema={PaymentFormSchema}
    onSubmit={async (data, actions) => {
      try {
        if (paymentId) {
          await Axios.put(appUrl(`/orders/${resourceId}/payments/${paymentId}`), data);
        } else {
          await Axios.post(appUrl(`/orders/${resourceId}/payments`), data);
        }
        onSuccess();
      } catch (err) {
        actions.setErrors({ failed: (err.response !== undefined) ? `${err.response.data.message}` : err.message });
      }
      actions.setSubmitting(false);
    }}
    render={({
      dirty, isSubmitting, handleSubmit, setErrors, ...others
    }) => (
      <form onSubmit={handleSubmit}>
        <section className="modal-card-body">
          <ErrorNotification clearError={() => setErrors({ failed: null })} {...others} />
          <InputDate name="date" label="pages.back.orders.details.billing.date" {...others} />
          <MoneyInput
            name="amount.value"
            label="pages.back.orders.details.billing.amount"
            disableAutoComplete
            leftLabel="€"
            moreThan={constants.priceMoreThan}
            {...others}
          />
          <InputTextarea
            name="notes"
            label="pages.back.orders.details.billing.notes"
            {...others}
          />
        </section>
        <footer className="modal-card-foot" style={{ justifyContent: 'flex-end' }}>
          <SubmitButton dirty={dirty} isSubmitting={isSubmitting} />
          <button type="button" className="button" onClick={onCancel}>
            <Translated k="general.close" />
          </button>
        </footer>
      </form>
    )}
  />
);

export default PaymentForm;
