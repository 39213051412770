import React, { Fragment } from 'react';
import classNames from 'classnames';
import Step from './Step';

const Steps = ({ steps, ...others }) => (
  <Fragment>
    <ul className="steps is-hidden-touch has-content-centered is-medium">
      {steps.map(s => (
        <Step id={s.id} key={s.id} iconName={s.iconName} {...others} />
      ))}
    </ul>
    <ul className="steps is-thin is-hidden-desktop is-horizontal">
      {steps.map(s => (
        <li
          key={s.id}
          className={classNames('steps-segment', {
            'is-primary': others.activeId === s.id,
          })}
        >
          <span className="steps-marker" />
        </li>
      ))}
    </ul>
  </Fragment>
);

export default Steps;
