import React, {Component} from 'react';
import Get from 'lodash.get';
import Select from 'react-select';
import classNames from 'classnames';
import Translated from '../elements/Translated';
import {AppContext} from '../../App';
import Required from '../elements/Required';

class InputSelect extends Component {
  handleChange = (selected) => {
    const {name, setFieldValue} = this.props;
    setFieldValue(name, this.getValues(selected));
  };

  getValues = (selected) => {
    if (selected) {
      if (Array.isArray(selected)) {
        return selected.map(s => s.value);
      } else {
        return selected.value;
      }
    }

    return selected;
  }

  handleBlur = () => {
    const {name, setFieldTouched, handleValueChange} = this.props;
    setFieldTouched(name, true);

    if (handleValueChange) {
      handleValueChange();
    }
  };

  extractRefValue = (v, options) => {
    if (v instanceof Object && v.uuid !== undefined) {
      return options.find(o => o.v.uuid === v.uuid);
    } else {
      return options.find(o => o.value === v);
    }
  }

  doTranslate = (selectedValue, translate) => {
    if (Array.isArray(selectedValue)) {
      return selectedValue.map(v => this.doTranslateObject(v, translate));
    }
    return this.doTranslateObject(selectedValue, translate);
  }

  doTranslateObject(obj, translate) {
    return {...obj, label: translate(obj.label)}
  }

  render() {
    const {
      name,
      label,
      values,
      loading,
      errors,
      touched,
      options,
      translateLabel,
      small,
      isSearchable = true,
      isClearable = false,
      isMulti,
      required,
      isDisabled,
    } = this.props;

    let selectedValue;

    const foundValue = Get(values, name);

    if (Array.isArray(foundValue)) {
      selectedValue = foundValue.map(v => this.extractRefValue(v, options));
    } else {
      selectedValue = this.extractRefValue(foundValue, options);
    }


    return (
      <AppContext.Consumer>
        {({translate}) => (
          <div className="field">
            {label && (
              <label
                className={classNames('label', {
                  'is-small': small,
                })}
              >
                <Translated k={label}/>
                <Required required={required}/>
              </label>
            )}
            <div className="control is-expanded">
              <div
                className={classNames('is-fullwidth', {
                  'is-loading': loading,
                  'is-danger': Get(errors, name) && Get(touched, name),
                  'is-small': small,
                })}
              >
                <Select
                  options={
                    translateLabel === true
                      ? options.map(o => ({
                        value: o.value,
                        label: translate(o.label),
                      }))
                      : options
                  }
                  name={name}
                  onChange={this.handleChange}
                  onBlur={this.handleBlur}
                  value={
                    selectedValue && translateLabel === true
                      ? this.doTranslate(selectedValue, translate)
                      : selectedValue
                  }
                  isClearable={isClearable}
                  isSearchable={isSearchable}
                  isLoading={loading}
                  isMulti={isMulti}
                  placeholder={isSearchable ? translate('general.select') : ''}
                  isDisabled={isDisabled}
                  className={classNames('label', {
                    'is-small': small,
                  })}
                />
              </div>
            </div>
            {Get(errors, name) && Get(touched, name) && (
              <p className="help is-danger">
                <Translated k={Get(errors, name)}/>
              </p>
            )}
          </div>
        )}
      </AppContext.Consumer>
    );
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.isDisabled !== prevProps.isDisabled) {
      this.props.setFieldValue(this.props.name, this.props.isMulti ? [] : undefined);
    }
  }

}


export default InputSelect;
