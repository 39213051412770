import React from 'react';
import * as Yup from 'yup';
import {FieldArray} from 'formik';
import InputText from '../../../base/form/InputText';
import InputNumber from '../../../base/form/InputNumber';
import Translated from '../../../base/elements/Translated';
import MoneyInput from '../../../base/elements/MoneyInput';
import RemoteInputSelect from "../../../base/form/RemoteInputSelect";

export const InvoiceRowSchema = Yup.object().shape({});

const removeRow = (resourceId, data, handleUpdateTotal, remove) => {
  handleUpdateTotal();
  remove();
};

const InvoiceRowForm = ({
  values, resourceId, handleUpdateTotal, ...others
}) => (
  <FieldArray
    name="rows"
    render={arrayHelpers => (
      <div>
        {values.rows.map((p, i) => (
          <div key={p.uuid} className="box">
            <nav className="level compact">
              <div className="level-left" />
              <div className="level-right">
                <p className="level-item">
                  <button
                    type="button"
                    onClick={() => removeRow(resourceId, values, handleUpdateTotal,
                      () => arrayHelpers.remove(i))
                            }
                    className="button is-small is-danger"
                  >
                    <Translated k="general.delete" />
                  </button>
                </p>
              </div>
            </nav>
            <div className="columns">
              <div className="column">
                <InputText
                  values={values}
                  name={`rows.${i}.description`}
                  label="pages.back.orders.details.billing.row.description"
                  disableAutoComplete
                  small
                  {...others}
                />
              </div>
              <div className="column">
                <MoneyInput
                  values={values}
                  name={`rows.${i}.rowAmount.value`}
                  label="pages.back.orders.details.billing.row.amount"
                  leftLabel="€"
                  disableAutoComplete
                  small
                  {...others}
                  handleBlur={handleUpdateTotal}
                />
              </div>
            </div>
            <div className="columns">
              <div className="column">
                <InputNumber
                  values={values}
                  name={`rows.${i}.quantity`}
                  label="pages.back.orders.details.billing.row.quantity"
                  disableAutoComplete
                  small
                  {...others}
                  handleBlur={handleUpdateTotal}
                />
              </div>
              <div className="column">
                <MoneyInput
                  values={values}
                  name={`rows.${i}.vat.value`}
                  label="pages.back.orders.details.billing.row.vat"
                  disableAutoComplete
                  leftLabel="%"
                  small
                  {...others}
                  handleBlur={handleUpdateTotal}
                />
              </div>
              <div className="column">
                <RemoteInputSelect
                    values={values}
                    name={`rows.${i}.vat.description`}
                    label="model.vat.description"
                    url="/events/vatExemptions"
                    small
                    {...others}
                />
              </div>
            </div>
          </div>
        ))}
      </div>
    )}
  />
);

export default InvoiceRowForm;
