import React, {Fragment} from 'react';
import Translated from "../../../base/elements/Translated";
import BankAccountForm from "../../../forms/BankAccountForm";

const BankAccountAdd = ({ handleSuccess, handleClose }) => (
    <Fragment>
        <header className="modal-card-head">
            <p className="modal-card-title">
                <Translated k="pages.back.bankAccounts.add" />
            </p>
            <button type="button" className="delete" aria-label="close" onClick={handleClose} />
        </header>
        <BankAccountForm
            onCancel={handleClose}
            onSuccess={() => {
                handleSuccess();
                handleClose();
            }}
        />
    </Fragment>
);

export default BankAccountAdd;