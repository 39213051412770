import React, { Component, Fragment } from 'react';
import classNames from 'classnames';
import Title from '../../base/elements/Title';
import MoneyOutput from '../../base/elements/MoneyOutput';
import Translated from '../../base/elements/Translated';

class CartOrderPartecipationFo extends Component {
  render() {
    const {
      partecipations, small = false,
    } = this.props;

    return (
      partecipations.length > 0 && (
        <div className="columns compact is-vcentered order-partecipation-column">
          <div className="column is-four-fifths">
            <div className="columns compact">
              <div className="column">
                <Title size={5} label={partecipations[0].eventPackage.eventName} untranslated />
              </div>
            </div>
            {partecipations.map(p => (
              <Fragment key={p.uuid}>
                <div className="columns compact">
                  <div
                    className={classNames('column', {
                      'is-two-thirds': small,
                    })}
                  >
                    <span className="is-size-6">{p.name}</span>
                    {p.quantity > 1 && (
                      <span className="padded-left-less is-size-6">{`(x ${p.quantity})`}</span>
                    )}
                  </div>
                  <div className="column">
                    <MoneyOutput money={p.packageAmount} multiplier={p.quantity} />
                    {p.quantity > 1 && (
                      <div className="is-size-7">
                        {'('}
                        <span className="has-text-weight-bold">
                          <MoneyOutput money={p.packageAmount} />
                        </span>
                        <span className="padded-left-less">
                          <Translated k="general.each" />
                        </span>
                        {')'}
                      </div>
                    )}
                  </div>
                </div>
                {p.options.map(o => (
                  <div className="columns" key={o.uuid}>
                    <div
                      className={classNames('column', {
                        'is-two-thirds': small,
                      })}
                    >
                      <ul>
                        <li className="padded-left">{o.name}</li>
                      </ul>
                    </div>
                    <div className="column">
                      <MoneyOutput money={o.packageOptionAmount} multiplier={p.quantity} />
                      {o.onSitePayment && (
                        <div className="is-size-7 has-text-weight-bold">
                          <Translated k="model.eventPackageOption.onSitePayment" />
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </Fragment>
            ))}
          </div>
        </div>
      )
    );
  }
}

export default CartOrderPartecipationFo;
