import React, { Component } from 'react';
import Get from 'lodash.get';
import classNames from 'classnames';
import Translated from '../elements/Translated';
import Required from '../elements/Required';

class InputBoolean extends Component {
  handleChange = (value) => {
    const { name, setFieldValue } = this.props;
    setFieldValue(name, value ? value.target.checked : false);
  };

  handleBlur = () => {
    const { name, setFieldTouched } = this.props;
    setFieldTouched(name, true);
  };

  render() {
    const {
      name, label, values, errors, touched, small, required,
    } = this.props;

    return (
      <div className="field">
        {label && (
          <label
            className={classNames('label', {
              'is-small': small,
            })}
          >
            <Translated k={label} />
            <Required required={required} />
          </label>
        )}
        <div className="control">
          <label className="checkbox">
            <input
              type="checkbox"
              name={name}
              onChange={this.handleChange}
              onBlur={this.handleBlur}
              checked={Get(values, name) === true}
            />
          </label>
        </div>
        {Get(errors, name) && Get(touched, name) && (
          <p className="help is-danger">
            <Translated k={Get(errors, name)} />
          </p>
        )}
      </div>
    );
  }
}

export default InputBoolean;
