import React, { Fragment } from 'react';
import { AppContext } from '../../App';

const currencies = {
  EUR: '€',
  USD: '$',
};

const MoneyOutput = ({ money, multiplier = 1 }) => (
  <AppContext.Consumer>
    {({ translate }) => (
      <Fragment>
        {`${(money.value.toFixed(money.fractionDigits) * multiplier)
          .toFixed(money.fractionDigits)
          .replace(/\./g, translate('general.separator'))} 
    ${currencies[money.currency]}`}
      </Fragment>
    )}
  </AppContext.Consumer>
);

export default MoneyOutput;
