import React, { Fragment } from 'react';
import Title from '../../../base/elements/Title';
import ColumnOutput from '../../../base/elements/ColumnOutput';
import Translated from '../../../base/elements/Translated';
import DateOutput from '../../../base/elements/DateOutput';
import ButtonModal from '../../../base/components/modal/ButtonModal';
import PartecipantEdit from './PartecipantEdit';
import ContactsDetails from '../orders/contacts/ContactsDetails';
import ContactEdit from '../orders/contacts/ContactEdit';
import PersonDetails from '../people/PersonDetails';
import BooleanOutput from '../../../base/elements/BooleanOutput';

const PartecipantDetails = ({
  partecipant, order, handleClose, handleSuccess,
}) => (
  <Fragment>
    <header className="modal-card-head">
      <p className="modal-card-title">
        <Translated k="pages.back.orders.details.description.title" />
      </p>
      <button type="button" className="delete" aria-label="close" onClick={handleClose} />
    </header>
    <section className="modal-card-body">
      <div>
        <Title label="pages.back.orders.details.description.user" size={4} section />
        <div className="box">
          <PersonDetails data={order.author.person} />
        </div>
        <Title label="pages.back.people.details.title" size={4} section />
        <div className="box">
          <div className="columns">
            <ColumnOutput
              label="model.person.firstName"
              render={() => partecipant.person.firstName}
            />
            <ColumnOutput
              label="model.person.lastName"
              render={() => partecipant.person.lastName}
            />
            <ColumnOutput
              label="model.partecipant.relationship"
              render={() => partecipant.relationship}
            />
          </div>
          <div className="columns">
            <ColumnOutput
              label="model.person.dob"
              render={() => <DateOutput value={partecipant.person.dateOfBirth} />}
            />
            <ColumnOutput label="model.person.ssnCode" render={() => partecipant.person.ssnCode} />
            <ColumnOutput
              label="model.person.gender"
              render={() => partecipant.person.personGender}
            />
          </div>

          <div className="columns">
            <ColumnOutput
              label="model.person.allergies"
              render={() => partecipant.person.allergies.join(', ')}
            />
            <ColumnOutput
              label="model.person.medicalCares"
              render={() => partecipant.person.medicalCares.join(', ')}
            />
            <ColumnOutput
              label="model.person.englishLevel.entityName"
              render={() => (
                <Translated k={`model.person.englishLevel.${partecipant.person.englishLevel}`} />
              )}
            />
          </div>
          <div className="columns">
            <ColumnOutput label="general.privacy.imagesConsentShort" render={() => <BooleanOutput value={partecipant.person.imagesConsent} />} />
            <ColumnOutput
              label="model.person.additionalInfo"
              render={() => partecipant.person.additionalInfo}
            />
          </div>
        </div>
        <div className="field is-grouped is-grouped-right">
          <ButtonModal
            buttonClassName="is-default"
            buttonLabel="pages.back.people.edit"
            render={props => (
              <PartecipantEdit
                handleSuccess={handleSuccess}
                handleClose={props.handleClose}
                person={partecipant.person}
                partecipantId={partecipant.id}
              />
            )}
            card
          />
        </div>

        <Title label="model.contact.title" size={4} section />
        <ContactsDetails
          contacts={order.items[0].emergencyContacts}
          order={order}
          handleSuccess={handleSuccess}
        />
        <div className="field is-grouped is-grouped-right">
          <ButtonModal
            buttonClassName="is-default"
            buttonLabel="pages.back.contacts.add"
            render={props => (
              <ContactEdit
                handleSuccess={handleSuccess}
                handleClose={props.handleClose}
                order={order}
              />
            )}
            card
          />
        </div>
      </div>
    </section>
    <footer className="modal-card-foot" style={{ justifyContent: 'flex-end' }}>
      <button type="button" className="button" onClick={handleClose}>
        <Translated k="general.close" />
      </button>
    </footer>
  </Fragment>
);

export default PartecipantDetails;
