import React from 'react';
import { NavLink } from 'react-router-dom';
import Translated from '../../elements/Translated';

const Link = ({ to, label }) => (
  <NavLink to={to} exact className="navbar-item" activeClassName="navbar-link-active">
    <Translated k={label} />
  </NavLink>
);

export default Link;
