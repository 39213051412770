import React, {Component, Fragment} from 'react';
import WithRemoteData from '../../../utils/WithRemoteData';
import Translated from '../../../base/elements/Translated';
import ColumnOutput from '../../../base/elements/ColumnOutput';
import ButtonModal from '../../../base/components/modal/ButtonModal';
import EventTypeEdit from './EventTypeEdit';
import appUrl from '../../../utils/remote';
import Axios from "axios";

class EventTypeDetails extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    async getPath() {
        let response = await Axios.get(appUrl(`/event-type/path`)).then(result => result);
        this.setState({...this.state, path: response});
    }

    componentDidMount() {
        this.getPath();
    }

    render() {
        const path = this.state.path;
        return(
            <WithRemoteData
                url={`/eventTypes/${this.props.resourceId}`}
                render={({loading, data, refresh}) => loading === false
                    && data !== null && (
                        <Fragment>
                            <header className="modal-card-head">
                                <p className="modal-card-title">
                                    <Translated k="pages.back.eventTypes.details"/>
                                </p>
                                <button type="button" className="delete" aria-label="close" onClick={this.props.handleClose}/>
                            </header>
                            <section className="modal-card-body">
                                <div className="columns">
                                    <div className="column">
                                        <div className="columns">
                                            <ColumnOutput label="model.eventType.name" render={() => data.name}/>
                                        </div>
                                        <div className="columns">
                                            <ColumnOutput
                                                label="model.eventType.description"
                                                render={() => data.description}
                                            />
                                        </div>
                                        <div className="columns">
                                            <ColumnOutput label="model.eventType.defaultInvoiceSuffix" render={() => data.defaultInvoiceSuffix}/>
                                        </div>
                                        <div className="columns">
                                            <ColumnOutput label="model.eventType.defaul.link" render={() => (path && path.data) +"/purchase?eventTypeId=" + data.id}/>
                                        </div>
                                    </div>
                                    <div className="column is-one-third">
                                        <div className="columns">
                                            <ColumnOutput
                                                label="model.event.attachment"
                                                render={() => data.uploadedImage && data.uploadedImage.attachmentName && (
                                                    <Fragment>
                                                        {data.uploadedImage.attachmentName}
                                                        <div
                                                            className="card large"
                                                            style={{width: '300px'}}
                                                        >
                                                            <div
                                                                className="card-image card-header has-background-grey-lighter event-type-image">
                                                                <figure className="image event-type-image">
                                                                    <img
                                                                        src={appUrl(`/attachments/${data.uploadedImage.attachmentUuid}`)}
                                                                        alt={data.uploadedImage.attachmentName}/>
                                                                </figure>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                )}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </section>
                            <footer className="modal-card-foot" style={{justifyContent: 'flex-end'}}>
                                <ButtonModal
                                    buttonClassName="is-primary"
                                    buttonLabel="general.edit"
                                    render={innerModalData => (
                                        <EventTypeEdit
                                            resourceId={this.props.resourceId}
                                            handleClose={innerModalData.handleClose}
                                            handleSuccess={refresh}
                                        />
                                    )}
                                    card
                                />
                                <button type="button" className="button" onClick={this.props.handleClose}>
                                    <Translated k="general.close"/>
                                </button>
                            </footer>
                        </Fragment>
                    )
                }
            />
        )}
}

export default EventTypeDetails;
