import React, {Fragment} from 'react';
import {Formik} from 'formik';
import * as Yup from 'yup';
import Axios from 'axios';
import 'moment/locale/it';
import Get from 'lodash.get';
import appUrl from '../utils/remote';
import ErrorNotification from '../base/elements/ErrorNotification';
import InputText from '../base/form/InputText';
import Translated from '../base/elements/Translated';
import InputDate from '../base/form/InputDate';
import InputSelect from '../base/form/InputSelect';
import RemoteInputRadio from '../base/form/RemoteInputRadio';
import momentDate from './utils/momentSchema';
import SubmitButton from '../base/form/SubmitButton';
import InputRadio from '../base/form/InputRadio';
import CodiceFiscale from "codice-fiscale-js";
import RemoteInputSelect from "../base/form/RemoteInputSelect";

function checkCf(cf) {
  try {
    new CodiceFiscale(cf);
  } catch (e) {
    return false;
  }

  return true;
}

export const PeopleSchema = Yup.object().shape({
  firstName: Yup.string()
    .default('')
    .when('personType', {
      is: 'PHYSICAL',
      then: Yup.string().required('general.validation.required'),
    }),
  lastName: Yup.string()
    .default('')
    .when('personType', {
      is: 'PHYSICAL',
      then: Yup.string().required('general.validation.required'),
    }),
  registeredName: Yup.string()
    .default('')
    .when('personType', {
      is: 'JURIDICAL',
      then: Yup.string().required('general.validation.required'),
    }),
  personType: Yup.string()
    .required('general.validation.required')
    .default('PHYSICAL'),
  dateOfBirth: momentDate(),
  email: Yup.string()
    .email('general.validation.email')
    .required('general.validation.required')
    .default(''),
  pec: Yup.string()
    .email('general.validation.email')
    .default(''),
  address: Yup.object().shape({
    street: Yup.string(),
    city: Yup.string(),
    country: Yup.string(),
    code: Yup.string(),
    district: Yup.string(),
    number: Yup.string(),
  }),
  vatId: Yup.string()
    .default('')
    .when('personType', {
      is: 'JURIDICAL',
      then: Yup.string().required('general.validation.required'),
    }),
  ssnCode: Yup.string()
    .default('')
    .when(['isForeigner', 'personType'], {
      is: (isForeigner, personType) => isForeigner === 'false' && personType === 'PHYSICAL',
      then: Yup.string().required('general.validation.required').test(
        'cf-validation',
        'general.validation.ssn.error',
        checkCf
      ),
    }),
  personCountry: Yup.string()
    .when(['isForeigner'], {
      is: (isForeigner) => isForeigner === 'true',
      then: Yup.string().required('general.validation.required'),
    }),
  isForeigner: Yup.string()
    .required('general.validation.required')
    .default('false'),
});

export const MandatoryPeopleSchema = Yup.object().shape({
  firstName: Yup.string()
    .default('')
    .when('personType', {
      is: 'PHYSICAL',
      then: Yup.string().required('general.validation.required'),
    }),
  lastName: Yup.string()
    .default('')
    .when('personType', {
      is: 'PHYSICAL',
      then: Yup.string().required('general.validation.required'),
    }),
  registeredName: Yup.string()
    .default('')
    .when('personType', {
      is: 'JURIDICAL',
      then: Yup.string().required('general.validation.required'),
    }),
  personType: Yup.string()
    .required('general.validation.required')
    .default('PHYSICAL'),
  dateOfBirth: momentDate()
    .when('personType', {
      is: 'PHYSICAL',
      then: momentDate().required('general.validation.required'),
    }),
  email: Yup.string()
    .email('general.validation.email')
    .required('general.validation.required')
    .default(''),
  personGender: Yup.string().nullable()
    .default(null)
    .when('personType', {
      is: 'PHYSICAL',
      then: Yup.string().required('general.validation.required'),
    }),
  pec: Yup.string()
    .email('general.validation.email')
    .default(''),
  address: Yup.object().shape({
    street: Yup.string().required('general.validation.required'),
    city: Yup.string().required('general.validation.required'),
    country: Yup.string().required('general.validation.required'),
    code: Yup.string().required('general.validation.required'),
    district: Yup.string().required('general.validation.required'),
    number: Yup.string().required('general.validation.required'),
  }),
  vatId: Yup.string()
    .default('')
    .when('personType', {
      is: 'JURIDICAL',
      then: Yup.string().required('general.validation.required'),
    }),
  ssnCode: Yup.string()
    .default('')
    .when(['isForeigner', 'personType'], {
      is: (isForeigner, personType) => isForeigner === 'false' && personType === 'PHYSICAL',
      then: Yup.string().required('general.validation.required').test(
        'cf-validation',
        'general.validation.ssn.error',
        checkCf
      ),
    }),
  personCountry: Yup.string()
    .when(['isForeigner'], {
      is: (isForeigner) => isForeigner === 'true',
      then: Yup.string().required('general.validation.required'),
    }),
  isForeigner: Yup.string()
    .required('general.validation.required')
    .default('false'),
});

export const PeopleFormFields = ({
                                   disableBaseFields,
                                   baseName = '',
                                   setFieldValue,
                                   mandatoryPeopleFields,
                                   ...others
                                 }) => (
  <div>
    {disableBaseFields !== true && (
      <RemoteInputRadio
        url="/fo/partecipants/personTypes"
        label="model.person.personType.entityName"
        name={`${baseName}personType`}
        setFieldValue={setFieldValue}
        required
        {...others}
      />
    )}
    <div className="columns">
      {Get(others.values, `${baseName}personType`) === 'PHYSICAL' && (
        <Fragment>
          <div className="column">
            <InputText
              name={`${baseName}firstName`}
              label="model.person.firstName"
              disableAutoComplete
              setFieldValue={setFieldValue}
              required
              {...others}
            />
          </div>
          <div className="column">
            <InputText
              name={`${baseName}lastName`}
              label="model.person.lastName"
              disableAutoComplete
              setFieldValue={setFieldValue}
              required
              {...others}
            />
          </div>
        </Fragment>
      )}
      {Get(others.values, `${baseName}personType`) === 'JURIDICAL' && (
        <div className="column">
          <InputText
            name={`${baseName}registeredName`}
            label="model.person.registeredName"
            disableAutoComplete
            setFieldValue={setFieldValue}
            required
            {...others}
          />
        </div>
      )}
    </div>
    <div className="columns">
      <div className="column">
        <InputText
          name={`${baseName}phoneNumber`}
          label="model.person.phoneNumber"
          disableAutoComplete
          setFieldValue={setFieldValue}
          required={mandatoryPeopleFields}
          {...others}
        />
      </div>
      <div className="column">
        <InputText
          name={`${baseName}phoneNumber2`}
          label="model.person.phoneNumber2"
          disableAutoComplete
          setFieldValue={setFieldValue}
          {...others}
        />
      </div>
      <div className="column">
        <InputText
          name={`${baseName}phoneNumber3`}
          label="model.person.phoneNumber3"
          disableAutoComplete
          setFieldValue={setFieldValue}
          {...others}
        />
      </div>
      <div className="column">
        <InputText
          name={`${baseName}phoneNumber4`}
          label="model.person.phoneNumber4"
          disableAutoComplete
          setFieldValue={setFieldValue}
          {...others}
        />
      </div>
      <div className="column">
        <InputText
          name={`${baseName}email`}
          label="model.person.email"
          disableAutoComplete
          setFieldValue={setFieldValue}
          required
          {...others}
        />
      </div>
    </div>
    <div className="columns">
      <div className="column">
          {Get(others.values, `${baseName}personType`) === 'PHYSICAL' && <InputDate
          name={`${baseName}dateOfBirth`}
          label="model.person.dob"
          setFieldValue={setFieldValue}
          required={mandatoryPeopleFields}
          {...others}
        />
          }
      </div>
      <div className="column">
          {Get(others.values, `${baseName}personType`) === 'PHYSICAL' && <InputSelect
          name={`${baseName}personGender`}
          label="model.person.gender"
          options={[{value: 'M', label: 'M'}, {value: 'F', label: 'F'}]}
          isSearchable={false}
          setFieldValue={setFieldValue}
          required={mandatoryPeopleFields}
          {...others}
        />}
      </div>
    </div>
    <div className="columns">
      <div className="column">
        <InputRadio
          label="model.person.nationality.name"
          name={`${baseName}isForeigner`}
          options={[{label: 'general.yes', value: 'false'}, {label: 'general.no', value: 'true'}]}
          setFieldValue={setFieldValue}
          afterChange={() => setFieldValue(`${baseName}ssnCode`, '')}
          required
          {...others}
        />
      </div>
      <div className="column">
        {Get(others.values, `${baseName}isForeigner`) === 'true' ?
          <RemoteInputSelect
            setFieldValue={setFieldValue}
            name={`${baseName}personCountry`}
            label="model.person.personCountry"
            url="/fo/partecipants/countries?excludeItaly=true"
            required={mandatoryPeopleFields}
            {...others}
          />
          :
          <InputText
            name={`${baseName}ssnCode`}
            label="model.person.ssnCode"
            disabled={Get(others.values, `${baseName}isForeigner`) === 'true'}
            setFieldValue={setFieldValue}
            disableAutoComplete
            required
            {...others}
          />
        }
      </div>
    </div>
    <div className="columns">
      {Get(others.values, `${baseName}personType`) === 'JURIDICAL' && (
        <Fragment>
          <div className="column">
            <InputText
              name={`${baseName}vatId`}
              label="model.person.vatId"
              disableAutoComplete
              setFieldValue={setFieldValue}
              required
              {...others}
            />
          </div>
          <div className="column">
            <InputText
              name={`${baseName}sdiCode`}
              label="model.person.sdiCode"
              disableAutoComplete
              setFieldValue={setFieldValue}
              required={mandatoryPeopleFields}
              {...others}
            />
          </div>
          <div className="column">
            <InputText
              name={`${baseName}pec`}
              label="model.person.pec"
              disableAutoComplete
              setFieldValue={setFieldValue}
              required={mandatoryPeopleFields}
              {...others}
            />
          </div>
        </Fragment>
      )}
    </div>
    <div className="columns">
      <div className="column">
        <InputText
          name={`${baseName}address.street`}
          label="model.person.address.street"
          disableAutoComplete
          setFieldValue={setFieldValue}
          required={mandatoryPeopleFields}
          {...others}
        />
      </div>
      <div className="column">
        <InputText
          name={`${baseName}address.number`}
          label="model.person.address.number"
          disableAutoComplete
          setFieldValue={setFieldValue}
          required={mandatoryPeopleFields}
          {...others}
        />
      </div>
      <div className="column">
        <InputText
          name={`${baseName}address.district`}
          label="model.person.address.district"
          disableAutoComplete
          setFieldValue={setFieldValue}
          required={mandatoryPeopleFields}
          {...others}
        />
      </div>
    </div>
    <div className="columns">
      <div className="column">
        <InputText
          name={`${baseName}address.code`}
          label="model.person.address.code"
          disableAutoComplete
          setFieldValue={setFieldValue}
          required={mandatoryPeopleFields}
          {...others}
        />
      </div>
      <div className="column">
        <InputText
          name={`${baseName}address.city`}
          label="model.person.address.city"
          disableAutoComplete
          setFieldValue={setFieldValue}
          required={mandatoryPeopleFields}
          {...others}
        />
      </div>
      <div className="column">
        <RemoteInputSelect
          setFieldValue={setFieldValue}
          name={`${baseName}address.country`}
          label="model.person.address.country"
          url="/fo/partecipants/countries"
          required={mandatoryPeopleFields}
          {...others}
        />
      </div>
    </div>
  </div>
);

const PeopleForm = ({
                      onSuccess,
                      onCancel,
                      initialValues = {},
                      resourcePath,
                    }) => (
  <Formik
    initialValues={PeopleSchema.cast(initialValues)}
    validationSchema={PeopleSchema}
    onSubmit={async ({personNationality, ...person}, actions) => {
      try {
        await Axios.put(appUrl(`${resourcePath}/person`), {...person});

        onSuccess();
      } catch (err) {
        actions.setErrors({failed: (err.response !== undefined) ? `${err.response.data.message}` : err.message});
      }
      actions.setSubmitting(false);
    }}
    render={({
               dirty, isSubmitting, handleSubmit, setErrors, ...others
             }) => (
      <form onSubmit={handleSubmit}>
        <section className="modal-card-body">
          <ErrorNotification clearError={() => setErrors({failed: null})} {...others} />
          <PeopleFormFields {...others} />
        </section>
        <footer className="modal-card-foot" style={{justifyContent: 'flex-end'}}>
          <SubmitButton dirty={dirty} isSubmitting={isSubmitting}/>
          <button type="button" className="button" onClick={onCancel}>
            <Translated k="general.close"/>
          </button>
        </footer>
      </form>
    )}
  />
);

export default PeopleForm;
