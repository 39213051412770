import Axios from "axios";
import download from "downloadjs";

const appUrl = relative => `/canadian-web/rest${relative}`;

export const downloadFile =
   async (filePrefix, filePath, fileType) => {
      try {
         const response = await Axios.get(filePath, {
            responseType: 'blob',
         });
         const {data} = response;
         const moment = require('moment');
         download(data, `${filePrefix + '-' + moment().format("YYYY-MM-DD")}.xlsx`, `application/${fileType}`);
      } catch (err) {
         alert(err.message + " - " + err.stack);
      }
   };

export default appUrl;
