import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import Translated from '../base/elements/Translated';

class CookieDisclaimer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      cookies: new Cookies(),
    };
  }

  addCookie = () => {
    const cookie = new Cookies();
    cookie.set('accepted', 'true', { path: '/' });

    this.setState({ cookies: cookie });
  }

  render() {
    const { cookies } = this.state;


    return cookies.get('accepted') ? <div /> : (
      <div
        className="notification is-primary"
        style={{
          zIndex: '9999', display: 'flex', alignItems: 'center', position: 'fixed', bottom: '0', width: '100%',
        }}
      >
        <span style={{ flexGrow: '1' }}><Translated k="pages.auth.cookie.message" /></span>
        <button type="button" className="button is-primary" style={{ border: '1px solid white' }} onClick={this.addCookie}><Translated k="pages.auth.cookie.confirm" /></button>
      </div>
    );
  }
}

export default CookieDisclaimer;
