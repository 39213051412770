import React, { Fragment } from 'react';
import OrderTotalRow from './paymentMethods/OrderTotalRow';

export default function OrderPricing({ order }) {
  return (
    <Fragment>
      {order.items
        .filter((_, index) => index > 0)
        .map(item => (
          <OrderTotalRow
            size={6}
            label={item.description}
            amount={item.totalAmount}
            translated={false}
          />
        ))}
      <OrderTotalRow
        size={5}
        label="pages.shared.orders.partecipation.total"
        amount={order.completeAmount}
      />
      <OrderTotalRow
        size={6}
        label="pages.shared.orders.partecipation.totalWithoutOnSitePayments"
        amount={order.totalAmount}
      />
      <OrderTotalRow
        size={6}
        label="pages.shared.orders.partecipation.onSitePayments"
        amount={order.extraAmount}
      />
      <OrderTotalRow
        size={6}
        label="model.eventPackage.deposit"
        amount={order.totalDeposit}
      />
      <OrderTotalRow
        size={4}
        label="model.eventPackage.firstPayment"
        amount={order.firstPayment}
      />

    </Fragment>
  );
}
