import React, {Component} from 'react';
import classNames from 'classnames';
import Account from './Account';
import Brand from './Brand';
import Links from './Links';

class Navbar extends Component {
  state = {
    open: false,
  };

  toggle = () => {
    const { open } = this.state;
    this.setState({ open: !open });
  };

  render() {
    const { open } = this.state;

    return (
      <nav className="navbar is-fixed-top is-primary" role="navigation" aria-label="main navigation">
        <div className="container is-fluid" style={{marginRight: "100px", marginLeft:"100px"}}>
          <div className="navbar-brand">
            <Brand />

            <button
              type="button"
              className={classNames('navbar-burger burger button is-white', {
                'is-active': open,
              })}
              aria-label="menu"
              aria-expanded="false"
              data-target="navbarBasicExample"
              onClick={this.toggle}
            >
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </button>
          </div>

          <div
            className={classNames('navbar-menu', {
              'is-active': open,
            })}
          >
            <div className="navbar-start">
              <Links />
            </div>

            <div className="navbar-end">
              <Account />
            </div>
          </div>
        </div>
      </nav>
    );
  }
}

export default Navbar;
