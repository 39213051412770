import { Component } from 'react';

class WithError extends Component {
  state = {
    error: null,
  };

  showError = (errorMessage) => {
    this.setState({ error: errorMessage });
  }

  clearError = () => {
    this.setState({ error: null });
  }

  render() {
    const { render } = this.props;
    const { error } = this.state;

    return render({
      error,
      showError: this.showError,
      clearError: this.clearError,
    });
  }
}

export default WithError;
