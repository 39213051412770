import React from 'react';
import { Formik } from 'formik';
import Axios from 'axios';
import * as Yup from 'yup';
import appUrl from '../utils/remote';
import ErrorNotification from '../base/elements/ErrorNotification';
import SubmitButton from '../base/form/SubmitButton';
import Translated from '../base/elements/Translated';
import InputText from '../base/form/InputText';

const ContactSchema = Yup.object().shape({
  name: Yup.string()
    .required('general.validation.required')
    .default(''),
  phone: Yup.string()
    .required('general.validation.required')
    .default(''),
  notes: Yup.string().default(''),
});

const ContactForm = ({
  onSuccess, onCancel, order, initialValues = {},
}) => (
  <Formik
    initialValues={ContactSchema.cast(initialValues)}
    validationSchema={ContactSchema}
    onSubmit={async (data, actions) => {
      try {
        const resourceId = order.id;
        const contactId = initialValues.uuid;

        let modifiedOrder = order;
        if (contactId === undefined) {
          modifiedOrder = {
            ...order,
            items: [
              {
                ...order.items[0],
                emergencyContacts: [...order.items[0].emergencyContacts, data],
              },
              ...order.items.slice(1),
            ],
          };
        } else {
          const idx = order.items[0].emergencyContacts.map(sp => sp.uuid).indexOf(contactId);

          modifiedOrder = {
            ...order,
            items: [
              {
                ...order.items[0],
                emergencyContacts: [
                  ...order.items[0].emergencyContacts.slice(0, idx),
                  data,
                  ...order.items[0].emergencyContacts.slice(idx + 1),
                ],
              },
              ...order.items.slice(1),
            ],
          };
        }

        await Axios.put(appUrl(`/orders/${resourceId}`), modifiedOrder);

        onSuccess();
      } catch (err) {
        actions.setErrors({
          failed: err.response !== undefined ? `${err.response.data.message}` : err.message,
        });
      }
      actions.setSubmitting(false);
    }}
    render={({
      dirty, isSubmitting, handleSubmit, setErrors, ...others
    }) => (
      <form onSubmit={handleSubmit}>
        <section className="modal-card-body">
          <ErrorNotification clearError={() => setErrors({ failed: null })} {...others} />
          <InputText
            name="name"
            label="model.contact.name"
            {...others}
          />
          <InputText
            name="phone"
            label="model.contact.phone"
            {...others}
          />
          <InputText
            name="notes"
            label="model.contact.notes"
            {...others}
          />
        </section>
        <footer className="modal-card-foot" style={{ justifyContent: 'flex-end' }}>
          <SubmitButton dirty={dirty} isSubmitting={isSubmitting} />
          <button type="button" className="button" onClick={onCancel}>
            <Translated k="general.close" />
          </button>
        </footer>
      </form>
    )}
  />
);

export default ContactForm;
