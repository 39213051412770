import React from 'react';
import WithRemoteData from '../../utils/WithRemoteData';
import InputSelect from './InputSelect';

const RemoteEntityInputSelect = ({url, isMulti, isDisabled, filters, ...others}) => {
  return (
    <WithRemoteData
      url={url}
      filters={filters}
      isDisabled={isDisabled}
      render={({loading, data}) => (
        <InputSelect
          loading={loading}
          isMulti={isMulti}
          isDisabled={isDisabled}
          options={
            loading === false && data !== null
              ? data.elements.map(({id, name}) => ({value: id, label: name}))
              : []
          }
          {...others}
        />
      )}
    />
  )
}

export default RemoteEntityInputSelect;
