import React, { Component } from 'react';
import Axios from 'axios';
import appUrl from '../../utils/remote';

class LogoutPage extends Component {
  componentDidMount() {
    this.doLogout();
  }

  doLogout = async () => {
    await Axios.post(appUrl('/auth/logout'));
    window.location = '/';
  }

  render() {
    return (
      <div className="modal is-active">
        <div className="modal-background" />
        <div className="modal-content" />
      </div>
    );
  }
}

export default LogoutPage;
