import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import queryString from 'query-string';
import LoginForm from '../../forms/LoginForm';
import Logo from '../../../images/logo-piccolo-canadian-island.png';
import {AppContext} from '../../App';
import Axios from 'axios';
import appUrl from '../../utils/remote';
import Translated, {UnsafeTranslated} from '../../base/elements/Translated';
import LanguageSelector from './LanguageSelector';
import Title from '../../base/elements/Title';
import Background from "../../../images/home1_slide1.jpg";

class PurchaseLoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    useQuery() {
        return new URLSearchParams(this.props.location.search);
    }

    async getEventType(eventId) {
        let response = await Axios.get(appUrl(`/event-type/name/${eventId}`)).then(result => result.data);
        this.setState({...this.state, eventType: response});
    }

    componentDidMount() {
        this.getEventType(this.useQuery().get('eventTypeId'));
    }

    onLogin(loadUser) {
        loadUser();
        this.props.history.push("/");
    }

    render() {
        const eventType = this.state.eventType;
        return (
            <div>
            <nav className="is-fixed-top is-primary has-text-centered" >
                <img style={{width: '300px'}} src={Logo} alt="Canadian Island"/>
            </nav>
                <div className="section main-page has-text-centered"
                     style={{
                         backgroundRepeat: 'no-repeat',
                         backgroundImage: `url(${Background})`,
                         backgroundSize: 'cover',
                         backgroundPosition: 'center center',
                         width: '100%',
                         height: '400px',
                         opacity: 1,
                         visibility: 'inherit',
                         zIndex: 20}}>
                    <span>
                        <strong className="has-text-white"
                        style={{fontWeight: 'bold',
                            fontSize: '60px',
                            textTransform: 'uppercase'}}>
                            <Title size={1} section label="pages.auth.shop" labelParams={[eventType && eventType.name]}/>
                        </strong>
                    </span>
                </div>
                <div className="hero-body">
                    <div className="container">
                        <AppContext.Consumer>
                            {({loadUser}) => (
                                <div>
                                   <div className="columns">
                                        <div className="column is-5">
                                            <p style={{fontSize: 'x-large'}}>
                                                <Translated k="pages.auth.registered.user" /><br/><Translated k="pages.auth.registered.purchase" /></p>
                                                <LoginForm onSuccess={() => this.onLogin(loadUser)} submitColor='#fc5640' />
                                                <p className="has-text-centered " style={{ verticalAlign: "middle", marginTop: "10px" }}>
                                                    <Link to={`/recovery?locale=${queryString.parse(this.props.location.search).locale || 'it-IT'}`} className="has-text-grey">
                                                        <Translated k="pages.auth.forgotPassword" />
                                                    </Link>
                                                </p>
                                        </div>
                                        <div className="column is-offset-2 ">
                                            <p style={{fontSize: 'x-large'}}><Translated k="pages.auth.not.registered.user" /></p>
                                            <button className="login-button">
                                                <p className="has-text-centered">
                                                    <Link to={`/signup?locale=${queryString.parse(this.props.location.search).locale || 'it-IT'}`} className="has-text-white has-text-weight-bold" >
                                                        <Translated k="pages.auth.not.registered.purchase" />
                                                    </Link>
                                                </p>
                                            </button>
                                        </div>
                                   </div>
                                       <div className="columns is-centered columns-content">
                                           <div className="column is-half is-offset-one-third">
                                               <LanguageSelector />
                                           </div>
                                       </div>
                                       <div className="columns is-centered columns-content">
                                           <div className="column is-full has-text-centered">
                                               <UnsafeTranslated k="general.help" />
                                           </div>
                                       </div>


                                </div>
                            )}
                        </AppContext.Consumer>
                    </div>
                </div>

            </div>

        )
    }
}

export default withRouter(PurchaseLoginPage);