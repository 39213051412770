/* eslint react/prefer-stateless-function: "off" */
import React, {Component} from 'react';
import classNames from 'classnames';
import Axios from 'axios';
import Translated from '../../../../base/elements/Translated';
import MoneyOutput from '../../../../base/elements/MoneyOutput';
import Modal from '../../../../base/components/modal/Modal';
import ButtonIcon from '../../../../base/elements/ButtonIcon';
import Title from '../../../../base/elements/Title';
import CartOrderPartecipation from './CartOrderPartecipation';
import OrderPartecipationEditForm from '../../../../forms/orders/partecipations/OrderPartecipationEditForm';
import ErrorNotification from '../../../../base/elements/ErrorNotification';
import appUrl from '../../../../utils/remote';
import ButtonModal from "../../../../base/components/modal/ButtonModal";
import DeleteModal from "./DeleteModal";

const getEventIds = (partecipations) => {
  const result = [];
  partecipations.forEach((p) => {
    if (result.indexOf(p.eventPackage.eventId) < 0) {
      result.push(p.eventPackage.eventId);
    }
  });

  result.sort();
  return result;
};

class CartOrderPartecipant extends Component {
  constructor(props) {
    super(props);

    const {partecipant} = this.props;

    this.state = {
      totalAmount: partecipant.totalAmount,
      error: null,
    };
  }

  componentWillReceiveProps = ({partecipant}) => {
    this.updateTotal(partecipant);
  };

  updateTotal = async (partecipant) => {
    try {
      const response = await Axios.put(
        appUrl('/calculator/updatePartecipant'),
        partecipant,
      );

      this.setState({
        totalAmount: response.data.totalAmount,
      });
    } catch (err) {
      this.setState({
        error: err.message,
      });
    }
  };

  render() {
    const {
      partecipant,
      order,
      handleAddPartecipations,
      handleRemovePartecipations,
      handleRemovePartecipant,
      readOnly,
      small = false,
    } = this.props;
    const {partecipations} = partecipant;
    const eventIds = getEventIds(partecipations);

    const {totalAmount, error} = this.state;

    return (
      <div className="box">
        <ErrorNotification
          clearError={() => this.setState({error: null})}
          errors={{failed: error}}
        />
        <div className="columns compact">
          <div className="column is-four-fifths">
            <Title
              size={4}
              label={`${partecipant.person.firstName} ${partecipant.person.lastName}`}
              untranslated
            />
          </div>
          {partecipations.length === 0 && (
            <div className="column">
              {readOnly !== true && (
                <ButtonModal
                  buttonClassName="button is-danger is-small"
                  buttonLabel="pages.shared.orders.partecipants.remove"
                  render={({handleClose}) => (
                    <DeleteModal
                      handleRemove={() => handleRemovePartecipant(partecipant)}
                      handleClose={handleClose}
                    />)
                  }>
                </ButtonModal>
              )}
            </div>
          )}
        </div>
        {partecipations.length > 0 && <hr className="compact has-background-grey-lighter"/>}
        {eventIds.length > 0
          && eventIds.map(eventId => (
            <CartOrderPartecipation
              key={eventId}
              partecipations={partecipations.filter(p => p.eventPackage.eventId === eventId)}
              partecipant={partecipant}
              handleRemove={handleRemovePartecipations}
              readOnly={readOnly}
              small={small}
            />
          ))}
        <hr className="compact has-background-grey-lighter"/>
        <div className="columns compact has-text-weight-bold" style={{paddingLeft: '1rem'}}>
          <div className="column is-four-fifths">
            <div className="columns">
              <div
                className={classNames('column', {
                  'is-two-thirds': small,
                })}
              >
                <Translated k="general.total"/>
              </div>
              <div className="column">
                <MoneyOutput money={partecipant.totalAmount}/>
              </div>
            </div>
          </div>
        </div>
        {readOnly !== true && (
          <div className="form-buttons">
            <Modal
              buttonClassName="is-default"
              buttonLabel="pages.shared.orders.partecipation.add"
              render={({handleClose}) => (
                <OrderPartecipationEditForm
                  eventTypeId={order.items[0].eventType.id}
                  partecipant={partecipant}
                  handleCancel={handleClose}
                  handleSubmit={handleAddPartecipations}
                  updateTotal={this.updateTotal}
                  totalAmount={totalAmount}
                  isBackOffice={() => true}
                />
              )}
              renderTrigger={({handleOpen}) => (
                <ButtonIcon
                  handleClick={handleOpen}
                  icon="fa-plus"
                  label="pages.shared.orders.partecipation.add"
                  small
                />
              )}
              card
            />
          </div>
        )}
      </div>
    );
  }
}

export default CartOrderPartecipant;
