import React from 'react';
import Translated from '../../../base/elements/Translated';
import MoneyOutput from '../../../base/elements/MoneyOutput';

const OrderTotalRow = ({
  size, label, amount, translated = true,
}) => (
  <div className={`is-size-${size}`}>
    {translated ? <Translated k={label} /> : <span>{label}</span>}
    {':'}
    <span className="padded-left-less has-text-weight-semibold">
      <MoneyOutput money={amount} />
    </span>
  </div>
);

export default OrderTotalRow;
