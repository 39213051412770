import React, { Fragment } from 'react';
import WithRemoteData from '../../../utils/WithRemoteData';
import Translated from '../../../base/elements/Translated';
import IconModal from '../../../base/components/modal/IconModal';
import UserEdit from './UserEdit';
import ColumnOutput from '../../../base/elements/ColumnOutput';
import Title from '../../../base/elements/Title';
import PersonDetails from '../people/PersonDetails';

const UserDetails = ({ resourceId, handleClose, handleSuccess }) => (
  <WithRemoteData
    url={`/users/${resourceId}`}
    render={({ loading, data, refresh }) => loading === false
      && data !== null && (
        <Fragment>
          <header className="modal-card-head">
            <p className="modal-card-title">
              <Translated k="pages.back.users.details.title" />
            </p>
            <button type="button" className="delete" aria-label="close" onClick={handleClose} />
          </header>
          <section className="modal-card-body">
            <div className="level">
              <Title size={5} label="pages.back.users.details.user" />
            </div>
            <div className="columns is-vcentered">
              <ColumnOutput label="model.user.username" render={() => data.username} />
              <ColumnOutput
                label="model.user.roles.entityName"
                render={() => data.roles.join(' ')}
              />
              <div className="column">
                <IconModal
                  icon="fa-pen"
                  tooltipText="general.edit"
                  render={props => (
                    <UserEdit
                      handleClose={props.handleClose}
                      handleSuccess={() => { refresh(); handleSuccess(); }}
                      resourceId={data.id}
                    />
                  )}
                />
              </div>
            </div>
            <hr />
            <div className="level">
              <Title size={5} label="pages.back.users.details.person" />
            </div>
            <PersonDetails data={data.person} />
          </section>
          <footer className="modal-card-foot" style={{ justifyContent: 'flex-end' }}>
            <button type="button" className="button" onClick={handleClose}>
              <Translated k="general.close" />
            </button>
          </footer>
        </Fragment>
    )
    }
  />
);

export default UserDetails;
