import React, { Fragment } from 'react';
import ColumnOutput from '../../../base/elements/ColumnOutput';
import MoneyOutput from '../../../base/elements/MoneyOutput';
import BooleanOutput from '../../../base/elements/BooleanOutput';
import OutputNumber from '../../../base/elements/OutputNumber';

// FIXME da finire
const EventPackageOptionsDetails = ({ data }) => (
  <Fragment>
    <div className="columns compact is-vcentered">
      <ColumnOutput label="model.eventPackageOption.name" render={() => data.name} />
      <ColumnOutput
        label="model.eventPackageOption.price"
        render={() => <MoneyOutput money={data.totalAmount} />}
      />
      <ColumnOutput label="model.vat.entityName" render={() => <OutputNumber value={data.vat.value} symbol="%" />} />
      {data.onSitePayment
      && (
      <ColumnOutput
        label="model.eventPackageOption.onSitePayment"
        render={() => <BooleanOutput value={data.onSitePayment} />}
      />
      )}
    </div>
    <hr className="compact" />
  </Fragment>
);

export default EventPackageOptionsDetails;
