import React from 'react';
import Translated from '../../../../base/elements/Translated';
import Icon from '../../../../base/elements/Icon';
import MoneyOutput from '../../../../base/elements/MoneyOutput';
import OutputNumber from '../../../../base/elements/OutputNumber';

const CustomOrderItem = ({ data, handleRemoveItem }) => (
  <div className="box has-background-light">
    <div className="columns">
      <div className="column is-four-fifths">
        <div className="columns">
          <div className="column compact">{data.description}</div>
          <div className="column">
            <MoneyOutput money={data.totalAmount} />
            <div className="is-size-7">
              (
              <Translated k="pages.back.orders.details.cart.vat" />
              {' '}
              <OutputNumber value={data.vat.value} symbol="%" />
              )
            </div>
          </div>
        </div>
      </div>
      <div className="column">
        <button
          type="button"
          className="button is-danger is-small"
          onClick={() => handleRemoveItem(data)}
        >
          <span className="icon">
            <Icon name="fa-trash" />
          </span>
          <span>
            <Translated k="pages.back.orders.details.cart.item.remove" />
          </span>
        </button>
      </div>
    </div>
    <hr className="compact has-background-grey-lighter" />
    <div className="columns compact has-text-weight-bold" style={{ paddingLeft: '1rem' }}>
      <div className="column is-four-fifths">
        <div className="columns">
          <div className="column">
            <Translated k="general.total" />
          </div>
          <div className="column">
            <MoneyOutput money={data.totalAmount} />
          </div>
        </div>
      </div>
    </div>
  </div>
);
export default CustomOrderItem;
