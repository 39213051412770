import React from 'react';
import { withRouter } from 'react-router-dom';
import Title from '../../base/elements/Title';
import Logo from '../../../images/canadian-logo.png';
import Translated, { UnsafeTranslated } from '../../base/elements/Translated';

const OrderCompletePage = ({ history }) => (
  <div className="section main-page">
    <div className="has-background-white login-card box">
      <figure className="avatar has-text-centered login-logo">
        <img src={Logo} alt="Canadian Island" />
      </figure>
      <div className="container">
        <Title size={3} label="pages.front.order.complete" section centered />
      </div>
      <div className="notification is-info is-light has-text-centered">
        <UnsafeTranslated k="pages.front.order.completedInfo" />
      </div>
      <div className="has-text-centered">
        <button
          type="button"
          className="button is-primary"
          style={{ marginRight: '0.5em' }}
          onClick={() => history.push('/')}
        >
          <Translated k="pages.front.order.backToHome" />
        </button>
        <button type="button" className="button is-primary" onClick={() => history.push('/orders')}>
          <Translated k="pages.front.order.ordersList" />
        </button>
      </div>
    </div>
  </div>
);

export default withRouter(OrderCompletePage);
