import React, { Fragment } from 'react';
import classNames from 'classnames';


const ModalStateless = ({
  render, card = false, visible, handleClose,
}) => (
  <Fragment>
    <div className={classNames('modal', {
      'is-active': visible,
    })}
    >
      <div className="modal-background" />
      {card === true ? (
        <div className="modal-card">{visible && render({ handleClose })}</div>
      ) : (
        <Fragment>
          <div className="modal-content">{visible && render({ handleClose })}</div>
          <button
            type="button"
            className="modal-close is-large"
            aria-label="close"
            onClick={handleClose}
          />
        </Fragment>
      )}
    </div>
  </Fragment>
);

export default ModalStateless;
