import React, { Fragment } from 'react';
import Axios from 'axios';
import appUrl from '../../../utils/remote';
import Translated from '../../../base/elements/Translated';
import Title from '../../../base/elements/Title';
import WithError from '../../../utils/WithError';
import ErrorNotification from '../../../base/elements/ErrorNotification';

const removeOrder = async (orderId, onSuccess, onCancel, initialValues, logical, showError) => {
  if (logical) {
    try {
      await Axios.put(appUrl(`/fo/orders/${orderId}/delete`), initialValues);
      onSuccess();
      onCancel();
    } catch (err) {
      showError(err.message);
    }
  } else {
    try {
      await Axios.delete(appUrl(`/orders/${orderId}`));
      onSuccess();
      onCancel();
    } catch (err) {
      showError(err.message);
    }
  }
};

const OrderDelete = ({
  orderId, onCancel, onSuccess, logical, initialValues,
}) => (
  <WithError
    render={({ error, showError, clearError }) => (
      <Fragment>
        <div className="message is-warning">
          <div className="message-body">
            <ErrorNotification errors={{ failed: error }} clearError={clearError} />
            <div className="level">
              <Title size={3} label="pages.back.orders.delete.title" />
            </div>
            <p style={{ marginBottom: '1.5rem' }}>
              <Translated k="pages.back.orders.delete.message" />
            </p>
            <div className="field is-grouped is-grouped-right">
              <div className="control">
                <button
                  type="button"
                  className="button is-primary"
                  onClick={() => removeOrder(orderId, onSuccess, onCancel, initialValues, logical, showError)
                  }
                >
                  <Translated k="general.confirm" />
                </button>
              </div>
              <div className="control">
                <button type="button" className="button" onClick={onCancel}>
                  <Translated k="general.close" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    )}
  />
);

export default OrderDelete;
