import React from 'react';
import CrudList from "../../../utils/CrudList";
import Title from "../../../base/elements/Title";
import ButtonModal from "../../../base/components/modal/ButtonModal";
import FilterForm from "../../../forms/FilterForm";
import InputText from "../../../base/form/InputText";
import InputSelect from "../../../base/form/InputSelect";
import OrderableColumn from "../../../base/elements/OrderableColumn";
import Translated from "../../../base/elements/Translated";
import IconModal from "../../../base/components/modal/IconModal";
import Pagination from "../../../base/components/pagination/Pagination";
import BankAccountAdd from "./BankAccountAdd";
import BankAccountDetails from "./BankAccountDetails";
import BankAccountEdit from "./BankAccountEdit";
import BankAccountDelete from "./BankAccountDelete";

const BankAccountList = () => (
    <CrudList
        initialSorting={{ sort: 'IBAN', asc: true }}
        initialFilter={{ fullSearch: '', maxResults: 10 }}
        restUrl="/bankAccounts"
        render={({
                     loading,
                     data,
                     error,
                     params,
                     filters,
                     initialFilter,
                     handleNavigation,
                     changeOrdering,
                     updateFilters,
                     refreshResults,
                     removeEntity,
                 }) => (
            <div className="section main-page">
                <div className="container">
                    <div className="level">
                        <div className="level-left">
                            <Title size={3} label="model.bankAccount.entityNamePlural" />
                        </div>
                        <div className="level-right">
                            <ButtonModal
                                buttonClassName="is-primary"
                                buttonLabel="pages.back.bankAccounts.add"
                                render={({ handleClose }) => (
                                    <BankAccountAdd handleClose={handleClose} handleSuccess={refreshResults} />
                                )}
                            />
                        </div>
                    </div>
                    <div className="section-title">
                        <FilterForm
                            onSubmit={updateFilters}
                            initialValues={initialFilter}
                            render={({ ...props }) => (
                                <div className="columns">
                                    <div className="column is-two-thirds">
                                        <InputText
                                            name="fullSearch"
                                            label="general.filters.fulltextSearch"
                                            {...props}
                                            disableAutoComplete
                                        />
                                    </div>
                                    <div className="column">
                                        <InputSelect
                                            name="maxResults"
                                            label="general.filters.numOfResults"
                                            options={[
                                                { value: 10, label: 10 },
                                                { value: 20, label: 20 },
                                                { value: 50, label: 50 },
                                            ]}
                                            isSearchable={false}
                                            {...props}
                                        />
                                    </div>
                                </div>
                            )}
                        />
                    </div>
                    {loading === false && error && <div className="notification is-danger">{error}</div>}
                    <div className="table-container">
                        {loading === true && <div className="loading" />}
                        <table className="table is-hoverable is-bordered is-fullwidth">
                            <thead className="has-background-white-ter">
                            <tr>
                                <OrderableColumn
                                    style={{ width: '40%' }}
                                    name="IBAN"
                                    handleClick={changeOrdering}
                                    {...params}
                                >
                                    <Translated k="model.bankAccount.iban" />
                                </OrderableColumn>
                                <OrderableColumn
                                    style={{ width: '40%' }}
                                    name="IBAN"
                                    handleClick={changeOrdering}
                                    {...params}
                                >
                                    <Translated k="model.bankAccount.name" />
                                </OrderableColumn>
                                <th>
                                    <Translated k="general.actions" />
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {data
                                && data.elements.map(e => (
                                    <tr key={e.id}>
                                        <td>{e.iban}</td>
                                        <td>{e.name}</td>
                                        <td>
                                            <div className="buttons">
                                                <IconModal
                                                    icon="fa-search"
                                                    tooltipText="general.view"
                                                    render={({ handleClose }) => (
                                                        <BankAccountDetails
                                                            handleClose={handleClose}
                                                            handleSuccess={refreshResults}
                                                            resourceId={e.id}
                                                        />
                                                    )}
                                                    card
                                                />
                                                <IconModal
                                                    icon="fa-pen"
                                                    tooltipText="general.edit"
                                                    render={({ handleClose }) => (
                                                        <BankAccountEdit
                                                            handleClose={handleClose}
                                                            handleSuccess={refreshResults}
                                                            resourceId={e.id}
                                                        />
                                                    )}
                                                    card
                                                />
                                                <IconModal
                                                    icon="fa-trash-alt"
                                                    tooltipText="general.delete"
                                                    render={({ handleClose }) => (
                                                        <BankAccountDelete
                                                            name={e.name}
                                                            handleConfirm={() => {
                                                                removeEntity(e.id);
                                                                handleClose();
                                                            }}
                                                            handleClose={handleClose}
                                                        />
                                                    )}
                                                    card
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                    <Pagination
                        loading={loading}
                        data={data}
                        startPosition={params.startPosition}
                        maxResults={filters.maxResults}
                        handleNavigation={handleNavigation}
                    />
                </div>
            </div>
        )}
    />
);

export default BankAccountList;