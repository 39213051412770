import React, { Fragment } from 'react';
import EventTypeForm from '../../../forms/EventTypeForm';
import WithRemoteData from '../../../utils/WithRemoteData';
import Translated from '../../../base/elements/Translated';

const EventTypeEdit = ({ handleSuccess, handleClose, resourceId }) => (
  <WithRemoteData
    url={`/eventTypes/${resourceId}`}
    render={({ loading, data }) => loading === false
      && data !== null && (
        <Fragment>
          <header className="modal-card-head">
            <p className="modal-card-title">
              <Translated k="pages.back.eventTypes.edit" />
            </p>
            <button type="button" className="delete" aria-label="close" onClick={handleClose} />
          </header>
          <EventTypeForm
            onCancel={handleClose}
            onSuccess={() => {
              handleSuccess();
              handleClose();
            }}
            initialValues={data}
            resourceId={resourceId}
          />
        </Fragment>
    )
    }
  />
);

export default EventTypeEdit;
