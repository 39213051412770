import Axios from 'axios';
import React, {Component, Fragment} from 'react';
import classNames from 'classnames';
import ErrorNotification from '../../../base/elements/ErrorNotification';
import Title from '../../../base/elements/Title';
import Translated from '../../../base/elements/Translated';
import appUrl from '../../../utils/remote';
import WithRemoteData from '../../../utils/WithRemoteData';
import OrderStepsNavigation from '../OrderStepsNavigation';
import OrderPartecipant from '../partecipations/OrderPartecipant';
import PayPalButton from './PayPalButton';
import OrderPricing from '../OrderPricing';

class OrderPaymentMethodsForm extends Component {
  constructor(props) {
    super(props);

    const { initialValues } = this.props;

    this.state = {
      order: initialValues,
      submitting: false,
    };
  }

  submitAndNext = async () => {
    const { order } = this.state;

    this.setState({
      errorPaymentMethod: !order.paymentMethod
        ? 'general.validation.required'
        : null,
      errorPaymentType: !order.paymentType
        ? 'general.validation.required'
        : null,
    });

    if (order.paymentMethod && order.paymentType) {
      try {
        this.setState({
          errorPaymentType: null,
          errorPaymentMethod: null,
          submitting: true,
        });
        const { onSuccess, getRequestOptions } = this.props;
        const resourceId = order.id;
        const data = {
          ...order,
        };
        const result = await Axios.put(
          appUrl(`/fo/orders/${resourceId}`),
          {
            ...data,
            orderStatus: 'COMPLETED',
          },
          getRequestOptions(),
        );
        onSuccess(result.data);
      } catch (err) {
        this.setState({
          error:
            err.response !== undefined
              ? `${err.response.data.message}`
              : err.message,
        });
      }
    }
  };

  updatePaymentType = (paymentType) => {
    const { order } = this.state;
    this.setState({ errorPaymentType: null });

    const data = {
      ...order,
      paymentType,
    };

    this.setState({ order: data }, () => this.updatePaymentMethod(order.paymentMethod));
  };

  updatePaymentMethod = async (paymentMethod) => {
    try {
      const { order } = this.state;
      this.setState({ errorPaymentMethod: null });

      const data = {
        ...order,
        paymentMethod,
      };
      const { getRequestOptions } = this.props;
      const response = await Axios.put(appUrl(`/fo/orders/${order.id}`), data, getRequestOptions());
      this.setState({
        order: response.data,
      });
    } catch (err) {
      this.setState({
        error:
          err.response !== undefined
            ? `${err.response.data.message}`
            : err.message,
      });
    }
  };

  render() {
    const {
      error,
      order,
      errorPaymentMethod,
      errorPaymentType,
      submitting,
    } = this.state;
    const { onCancel } = this.props;

    return (
      <Fragment>
        <OrderStepsNavigation
          renderCenter={() => (
            <Title size={4} centered label="pages.shared.orders.steps.6" />
          )}
          onClickLeft={onCancel}
          showLeft
          disabledLeft={submitting}
          last
        />
        {error && (
          <ErrorNotification
            clearError={() => this.setState({ error: null })}
            errors={{ failed: error }}
          />
        )}
        <div className="columns">
          <div className="column">
            {order.items[0].partecipants.map(p => (
              <OrderPartecipant
                key={p.uuid}
                partecipant={p}
                order={order}
                small
                readOnly
              />
            ))}
          </div>
          <div className="column is-one-quarter">
            <div>
              <Title
                size={5}
                section
                label="model.order.paymentType.entityName"
              />
              <WithRemoteData
                url="/fo/orders/paymentTypes"
                render={({ loading, data }) => loading === false
                  && data !== null && (
                    <div className="field has-addons">
                      {data.map(d => (
                        <p key={d.label} className="control">
                          <button
                            type="button"
                            className={classNames('button', {
                              'is-info': d.value === order.paymentType,
                            })}
                            onClick={() => this.updatePaymentType(d.value)}
                            disabled={
                              d.value === 'DEPOSIT'
                              && !order.totalDeposit
                              && order.totalDeposit <= 0
                            }
                          >
                            <span>
                              <Translated k={d.label} />
                            </span>
                          </button>
                        </p>
                      ))}
                    </div>
                )
                }
              />
              <div>
                {errorPaymentType && (
                  <p className="help is-danger">
                    <Translated k={errorPaymentType} />
                  </p>
                )}
              </div>
              <Title
                size={5}
                section
                label="model.order.paymentMethod.entityName"
              />
              <WithRemoteData
                url="/fo/orders/paymentMethods"
                render={({ loading, data }) => loading === false
                  && data !== null
                  && data.map(d => (
                    <label key={d.label} className="radio vertical">
                      <input
                        type="radio"
                        name="paymentMethod"
                        id={d.value}
                        value={d.value}
                        checked={d.value === order.paymentMethod}
                        onChange={() => this.updatePaymentMethod(d.value)}
                        className="radio-button"
                        disabled={!order.paymentType}
                      />
                      <Translated k={d.label} />
                    </label>
                  ))
                }
              />
              <div id="payment-button-container">
                {order.paymentMethod && order.paymentMethod !== 'PAYPAL' && (
                  <button
                    style={{ width: '100%' }}
                    type="button"
                    className="button is-primary"
                    onClick={this.submitAndNext}
                    disabled={submitting}
                  >
                    <Translated k="general.complete" />
                  </button>
                )}
                {order.paymentMethod === 'PAYPAL' && (
                  <PayPalButton
                    billingHolder={order.billingHolder}
                    completeTotal={
                      order.paymentType === 'DEPOSIT' ? order.totalDeposit : order.totalAmount
                    }
                    notes={`${order.id} - ${order.items[0].eventType.name}`}
                    onSuccess={this.submitAndNext}
                    disabledLeft={submitting}
                  />
                )}
                <div>
                  {errorPaymentMethod && (
                    <p className="help is-danger">
                      <Translated k={errorPaymentMethod} />
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
          <div className="column">
            <div className="box">
              <OrderPricing order={order} />
            </div>
          </div>
        </div>
        <OrderStepsNavigation
          onClickLeft={onCancel}
          disabledLeft={submitting}
          showLeft
          last
        />
      </Fragment>
    );
  }
}

export default OrderPaymentMethodsForm;
