import React, {Component} from 'react';

import * as Yup from "yup";
import {Formik} from "formik";
import Axios from "axios";
import appUrl from "../utils/remote";
import ErrorNotification from "../base/elements/ErrorNotification";
import InputText from "../base/form/InputText";
import Translated from "../base/elements/Translated";
import SubmitButton from "../base/form/SubmitButton";

const BankAccountSchema = Yup.object().shape({
    iban: Yup.string()
        .required('general.validation.required')
        .default(''),
    name: Yup.string()
        .required('general.validation.required')
        .default(''),
});

class BankAccountForm extends Component {
    constructor(props) {
        super(props);

        this.state = {
            accept: 'image/*',
            error: null,
        };
    }

    render() {
        const {
            onSuccess, onCancel, resourceId, initialValues = {},
        } = this.props;

        const { error, isLoading } = this.state;

        return (
            <Formik
                initialValues={BankAccountSchema.cast(initialValues)}
                validationSchema={BankAccountSchema}
                onSubmit={async (data, actions) => {
                    try {
                        const response = {
                            ...data,
                        };
                        if (resourceId) {
                            await Axios.put(appUrl(`/bankAccounts/${resourceId}`), response);
                        } else {
                            await Axios.post(appUrl('/bankAccounts'), response);
                        }
                        onSuccess();
                    } catch (err) {
                        actions.setErrors({
                            failed: err.response !== undefined ? `${err.response.data.message}` : err.message,
                        });
                    }
                    actions.setSubmitting(false);
                }}
                render={({
                             dirty, isSubmitting, handleSubmit, setErrors, setFieldValue, ...others
                         }) => (
                    <form onSubmit={handleSubmit}>
                        <section className="modal-card-body">
                            <ErrorNotification clearError={() => setErrors({ failed: null })} {...others} />
                            <ErrorNotification
                                clearError={() => this.setState({ error: null })}
                                errors={{ failed: error }}
                            />
                            <InputText name="iban" label="model.bankAccount.iban" disableAutoComplete {...others} />
                            <InputText name="name" label="model.bankAccount.name" {...others} />
                        </section>
                        <footer className="modal-card-foot" style={{ justifyContent: 'flex-end' }}>
                            <SubmitButton dirty={dirty} isSubmitting={isSubmitting} isLoading={isLoading} />
                            <button type="button" className="button" onClick={onCancel}>
                                <Translated k="general.close" />
                            </button>
                        </footer>
                    </form>
                )}
            />
        );
    }
}

export default BankAccountForm;