import React, {Fragment} from 'react';
import Translated from '../../../../base/elements/Translated';
import DateOutput from '../../../../base/elements/DateOutput';
import IconModal from '../../../../base/components/modal/IconModal';
import ButtonModal from '../../../../base/components/modal/ButtonModal';
import InvoiceAdd from './InvoiceAdd';
import InvoiceDetails from './InvoiceDetails';
import InvoiceDepositAdd from './InvoiceDepositAdd';
import InvoiceDelete from './InvoiceDelete';
import MoneyOutput from '../../../../base/elements/MoneyOutput';
import DownloadButton from '../../../../base/elements/DownloadButton';
import ErrorNotification from '../../../../base/elements/ErrorNotification';
import WithError from '../../../../utils/WithError';

const InvoiceOverviewDetails = ({ data, handleSuccess, readOnly }) => (
  <WithError
    render={({ error, showError, clearError }) => (
      <Fragment>
        <ErrorNotification errors={{ failed: error }} clearError={clearError} />
        <table className="table is-hoverable is-bordered is-fullwidth">
          <thead className="has-background-white-ter">
            <tr>
              <th>
                <Translated k="pages.back.orders.details.billing.holder" />
              </th>
              <th>
                <Translated k="pages.back.orders.details.billing.number" />
              </th>
              <th>
                <Translated k="pages.back.orders.details.billing.suffix" />
              </th>
              <th>
                <Translated k="pages.back.orders.details.billing.date" />
              </th>
              <th>
                <Translated k="pages.back.orders.details.billing.amount" />
              </th>
              <th>
                <Translated k="pages.back.orders.details.billing.download" />
              </th>
            </tr>
          </thead>
          <tbody>
            {data
              && data.invoices.map(i => (
                <tr key={i.uuid}>
                  <td>{i.billingHolder.description}</td>
                  <td>{i.invoiceNumber}</td>
                  <td>{i.invoiceSuffix}</td>
                  <td>
                    <DateOutput value={i.date} />
                  </td>
                  <td>
                    <MoneyOutput money={i.invoiceTotal} />
                  </td>
                  <td>
                    <div className="buttons">
                      {!readOnly && (
                        <IconModal
                          icon="fa-search"
                          tooltipText="general.view"
                          render={props => (
                            <InvoiceDetails
                              handleClose={props.handleClose}
                              onSuccess={handleSuccess}
                              resourceId={data.id}
                              invoiceId={i.id}
                            />
                          )}
                          card
                        />
                      )}
                      <DownloadButton
                        fileType="pdf"
                        filePath={`/fo/orders/${data.id}/invoices/${i.id}/pdf`}
                        tooltipLabel="pages.back.orders.details.billing.downloadPaperInvoice"
                        iconName="fa-file-download"
                        notifyError={showError}
                      />
                      {!readOnly && (
                        <DownloadButton
                          fileType="xml"
                          filePath={`/orders/${data.id}/invoices/${i.id}/xml`}
                          tooltipLabel="pages.back.orders.details.billing.downloadDigitalInvoice"
                          iconName="fa-cloud-download-alt"
                          notifyError={showError}
                        />
                      )}
                      {!readOnly && (
                        <IconModal
                          icon="fa-trash-alt"
                          tooltipText="general.delete"
                          render={props => (
                            <InvoiceDelete
                              onCancel={props.handleClose}
                              initialValues={i}
                              orderId={data.id}
                              invoiceId={i.id}
                              onSuccess={handleSuccess}
                            />
                          )}
                          card
                        />
                      )}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        {!readOnly && data.orderStatus === 'COMPLETED' && (
          <div className="field is-grouped is-grouped-right">
            <ButtonModal
              buttonClassName="is-default"
              buttonLabel="pages.back.orders.details.billing.createDepositInvoice"
              render={props => (
                <InvoiceDepositAdd
                  handleSuccess={handleSuccess}
                  handleClose={props.handleClose}
                  order={data}
                />
              )}
            />
            <ButtonModal
              buttonClassName="is-default"
              buttonLabel="pages.back.orders.details.billing.createFinalInvoice"
              render={props => (
                <InvoiceAdd
                  handleSuccess={handleSuccess}
                  handleClose={props.handleClose}
                  billingHolder={data.billingHolder}
                  order={data}
                />
              )}
            />
          </div>
        )}
      </Fragment>
    )}
  />
);

export default InvoiceOverviewDetails;
