import React from 'react';
import {FieldArray, Formik} from 'formik';
import Axios from 'axios';
import * as Yup from 'yup';
import ErrorNotification from '../../../base/elements/ErrorNotification';
import OrderStepsNavigation from '../OrderStepsNavigation';
import appUrl from '../../../utils/remote';
import Title from '../../../base/elements/Title';
import momentDate from '../../utils/momentSchema';
import PartecipantFormFields from '../../../pages/back/partecipants/PartecipantFormFields';
import {UnsafeTranslated} from '../../../base/elements/Translated';

import CodiceFiscale from "codice-fiscale-js";

function checkCf (cf) {
    if (cf) {
        try {
            new CodiceFiscale(cf);
        } catch (e) {
            return false;
        }
    }
    return true;
}

export const PartecipantSchema = Yup.object().shape({
  firstName: Yup.string().default('').required('general.validation.required'),
  lastName: Yup.string().default('').required('general.validation.required'),
  personType: Yup.string()
    .default('PHYSICAL'),
  dateOfBirth: momentDate()
    .required('general.validation.required')
    .default(null),
  personGender: Yup.string()
    .default('')
    .required('general.validation.required'),
  imagesConsent: Yup.string()
    .default('')
    .required('general.validation.required'),
    ssnCode: Yup.string()
        .default('')
        .test(
            'cf-validation',
             'general.validation.ssn.error',
             checkCf
         ),
});

const OrderPartecipantSchema = Yup.object().shape({
  items: Yup.array()
    .of(
      Yup.object().shape({
        partecipants: Yup.array()
          .of(
            Yup.object().shape({
              person: PartecipantSchema,
            }),
          )
          .default([]),
      }),
    )
    .default([]),
});


const OrderPeopleForm = ({
  onSuccess, onCancel, resourceId, initialValues = {}, requestOptions,
}) => (
  <Formik
    initialValues={OrderPartecipantSchema.cast(initialValues)}
    onSubmit={async (data, actions) => {
      try {
        const response = await Axios.put(appUrl(`/fo/orders/${resourceId}`), { ...data, items: [data.items[0]] }, requestOptions);
        onSuccess(response.data);
      } catch (err) {
        actions.setErrors({ failed: (err.response !== undefined) ? `${err.response.data.message}` : err.message });
      }
      actions.setSubmitting(false);
    }}
    validationSchema={OrderPartecipantSchema}
    render={({
      dirty, isSubmitting, handleSubmit, setErrors, ...others
    }) => (
      <form onSubmit={handleSubmit}>
        <OrderStepsNavigation
          renderCenter={() => <Title info size={4} centered label="pages.shared.orders.steps.3" />}
          onClickLeft={onCancel}
          showLeft
          showRight
        />
        <div className="notification is-info is-light media">
          <i className="media-left fas fa-info-circle" />
          <div className="media-content">
            <UnsafeTranslated k="pages.shared.orders.steps.description.3" />
          </div>
        </div>

        <ErrorNotification clearError={() => setErrors({ failed: null })} {...others} />
        <FieldArray
          name="partecipants"
          render={() => others.values.items[0].partecipants.map((pa, i) => (
            <div className="box has-background-light" key={pa.uuid}>
              <Title
                size={4}
                label={`${others.values.items[0].partecipants[i].person.firstName} ${
                  others.values.items[0].partecipants[i].person.lastName
                }`}
                section
                untranslated
              />
              <PartecipantFormFields
                {...others}
                baseName={`items.0.partecipants.${i}.person.`}
                idx={i}
              />
            </div>
          ))
          }
        />
        <OrderStepsNavigation onClickLeft={onCancel} showLeft showRight />
      </form>
    )}
  />
);

export default OrderPeopleForm;
