import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import queryString from 'query-string';
import Logo from '../../../images/canadian-logo.png';
import Translated from '../../base/elements/Translated';
import LanguageSelector from './LanguageSelector';
import Title from '../../base/elements/Title';
import RecoveryPasswordForm from '../../forms/RecoveryPasswordForm';

class RecoveryPasswordPage extends Component {
  state = {
    passwordReset: false,
  };

  onSuccess = () => {
    this.setState({ passwordReset: true });
  };

  render = () => {
    const { passwordReset } = this.state;
    const { location } = this.props;
    return (
      <div className="has-background-white login-card column is-4 is-offset-4">
        <figure className="avatar has-text-centered login-logo">
          <img src={Logo} alt="Canadian Island" />
        </figure>
        {passwordReset === false && (
          <Fragment>
            <Title size={4} section label="pages.auth.recoveryPassword" />
            <RecoveryPasswordForm onSuccess={this.onSuccess} />
          </Fragment>
        )}
        {passwordReset && (
          <p className="has-text-centered">
            <strong>
              <Translated k="model.user.email.newPassword" />
            </strong>
          </p>
        )}
        <hr />
        <p className="has-text-centered" style={{ fontWeight: 700 }}>
          <Link to={`/?locale=${queryString.parse(location.search).locale || 'it-IT'}`} className="has-text-grey">
            <Translated k="pages.auth.login" />
          </Link>
          &nbsp;·&nbsp;
          <Link to={`/signup?locale=${queryString.parse(location.search).locale || 'it-IT'}`} className="has-text-grey">
            <Translated k="pages.auth.signUp" />
          </Link>
        </p>
        <div className="columns is-centered columns-content">
          <div className="column is-half">
            <LanguageSelector />
          </div>
        </div>
      </div>
    );
  };
}

export default RecoveryPasswordPage;
