import React, {Component, Fragment} from 'react';
import classNames from 'classnames';
import MoneyOutput from '../../../../base/elements/MoneyOutput';
import Translated from '../../../../base/elements/Translated';
import Title from '../../../../base/elements/Title';
import OutputNumber from '../../../../base/elements/OutputNumber';
import ButtonModal from '../../../../base/components/modal/ButtonModal';
import DeleteModal from "./DeleteModal";

class CartOrderPartecipation extends Component {
  render() {
    const {
      partecipations,
      partecipant,
      handleRemove,
      readOnly,
      small = false,
    } = this.props;

    return (
      partecipations.length > 0 && (
        <div className="columns compact is-vcentered order-partecipation-column">
          <div className="column is-four-fifths">
            <div className="columns compact">
              <div className="column">
                <Title size={5} label={partecipations[0].eventPackage.eventName} untranslated/>
              </div>
            </div>
            {partecipations.map(p => (
              <Fragment key={p.uuid}>
                <div className="columns compact">
                  <div
                    className={classNames('column', {
                      'is-two-thirds': small,
                    })}
                  >
                    <span className="is-size-6">{p.name}</span>
                    {p.quantity > 1 && (
                      <span className="padded-left-less is-size-6">{`(x ${p.quantity})`}</span>
                    )}
                  </div>
                  <div className="column">
                    <MoneyOutput money={p.eventPackage.totalPrice} multiplier={p.quantity}/>
                    {p.quantity > 1 && (
                      <div className="is-size-7">
                        {'('}
                        <span className="has-text-weight-bold">
                          <MoneyOutput money={p.eventPackage.totalPrice}/>
                        </span>
                        <span className="padded-left-less">
                          <Translated k="general.each"/>
                        </span>
                        {')'}
                      </div>
                    )}
                    <div className="is-size-7">
                      (
                      <Translated k="pages.back.orders.details.cart.vat"/>
                      {' '}
                      <OutputNumber value={p.vat.value} symbol="%"/>
                      )
                    </div>
                  </div>
                </div>
                {p.options.map(o => (
                  <div className="columns" key={o.uuid}>
                    <div
                      className={classNames('column', {
                        'is-two-thirds': small,
                      })}
                    >
                      <ul>
                        <li className="padded-left">{o.name}</li>
                      </ul>
                    </div>
                    <div className="column">
                      <MoneyOutput money={o.packageOptionAmount} multiplier={p.quantity}/>
                      {o.onSitePayment && (
                        <div className="is-size-7 has-text-weight-bold">
                          <Translated k="model.eventPackageOption.onSitePayment"/>
                        </div>
                      )}
                      <div className="is-size-7">
                        (
                        <Translated k="pages.back.orders.details.cart.vat"/>
                        {' '}
                        <OutputNumber value={o.vat.value} symbol="%"/>
                        )
                      </div>
                    </div>
                  </div>
                ))}
              </Fragment>
            ))}
          </div>
          <div className="column">
            {readOnly !== true && (
              <ButtonModal
                buttonClassName="button is-danger is-small"
                buttonLabel="pages.shared.orders.partecipation.remove"
                render={({handleClose}) => (
                  <DeleteModal
                    handleRemove={() => handleRemove(partecipations, partecipant)}
                    handleClose={handleClose}
                  />
                )}
              />
            )}
          </div>
        </div>
      )
    );
  }
}

export default CartOrderPartecipation;
