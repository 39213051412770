import React, { Fragment } from 'react';
import Link from './Link';
import { AppContext } from '../../../App';

const Links = () => (
  <Fragment>
    <AppContext.Consumer>
      {({ isBackOffice }) => (isBackOffice() ? (
        <Fragment>
          <Link to="/users" label="general.navbar.users" />
          <Link to="/customers" label="general.navbar.customers" />
          <Link to="/eventTypes" label="general.navbar.eventTypes" />
          <Link to="/events" label="general.navbar.events" />
          <Link to="/partecipants" label="general.navbar.partecipants" />
          <Link to="/orders" label="general.navbar.orders" />
        </Fragment>
      ) : (
        <Fragment>
          <Link to="/" label="general.navbar.home" />
          <Link to="/orders" label="pages.front.order.ordersList" />
        </Fragment>
      ))
      }
    </AppContext.Consumer>
  </Fragment>
);

export default Links;
