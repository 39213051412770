import React, {Fragment} from 'react';
import Axios from 'axios';
import ColumnOutput from '../../../base/elements/ColumnOutput';
import DateOutput from '../../../base/elements/DateOutput';
import Translated from '../../../base/elements/Translated';
import Title from '../../../base/elements/Title';
import OrderBillingDetailsEditForm from '../../../forms/orders/billing/OrderBillingDetailsEditForm';
import appUrl from '../../../utils/remote';
import WithError from '../../../utils/WithError';
import ErrorNotification from '../../../base/elements/ErrorNotification';
import ButtonModal from '../../../base/components/modal/ButtonModal';

const updateBillingHolder = async (data, b, handleSuccess, showError) => {
  try {
    const order = {
      ...data,
      billingHolder: b,
    };
    await Axios.put(appUrl(`/orders/${data.id}`), order);
    handleSuccess();
  } catch (err) {
    showError(err.message);
  }
};

const BillingInformationOverviewDetails = ({ data, handleSuccess, readOnly }) => (
  <WithError
    render={({ error, showError, clearError }) => (
      <Fragment>
        <ErrorNotification errors={{ failed: error }} clearError={clearError} />

        <Title label="pages.back.orders.details.billing.billingData" size={4} section />
        {data.billingHolder && (
          <Fragment>
            <div className="box">
              <div className="columns">
                <ColumnOutput
                  label="model.person.personType.entityName"
                  render={() => (
                    <Translated k={`model.person.personType.${data.billingHolder.personType}`} />
                  )}
                />
              </div>
              <div className="columns">
                <ColumnOutput
                  label="model.person.firstName"
                  render={() => data.billingHolder.firstName}
                />
                <ColumnOutput
                  label="model.person.lastName"
                  render={() => data.billingHolder.lastName}
                />
                {data.billingHolder.personType === 'JURIDICAL' ? (
                  <ColumnOutput
                    label="model.person.registeredName"
                    render={() => data.billingHolder.registeredName}
                  />
                ) : (
                  <div className="column" />
                )}
              </div>

              <div className="columns">
                <ColumnOutput
                  label="model.person.dob"
                  render={() => <DateOutput value={data.billingHolder.dateOfBirth} />}
                />
                <ColumnOutput
                  label="model.person.gender"
                  render={() => data.billingHolder.personGender}
                />
                <ColumnOutput
                  label="model.person.ssnCode"
                  render={() => data.billingHolder.ssnCode}
                />
              </div>
              <div className="columns">
                <ColumnOutput label="model.person.email" render={() => data.billingHolder.email} />
                <ColumnOutput
                  label="model.person.phoneNumber"
                  render={() => data.billingHolder.phoneNumber}
                />
              </div>
              {data.billingHolder.personType === 'JURIDICAL' && (
                <div className="columns">
                  <ColumnOutput
                    label="model.person.vatId"
                    render={() => data.billingHolder.vatId}
                  />
                  <ColumnOutput
                    label="model.person.sdiCode"
                    render={() => data.billingHolder.sdiCode}
                  />
                </div>
              )}
              <div className="columns">
                <ColumnOutput
                  label="model.person.address.street"
                  render={() => (data.billingHolder.address !== undefined
                    ? data.billingHolder.address.street
                    : '')
                  }
                />
                <ColumnOutput
                  label="model.person.address.number"
                  render={() => (data.billingHolder.address !== undefined ? data.billingHolder.address.number : '')
                  }
                />
                <ColumnOutput
                  label="model.person.address.district"
                  render={() => (data.billingHolder.address !== undefined ? data.billingHolder.address.district : '')
                  }
                />
              </div>
              <div className="columns">
                <ColumnOutput
                  label="model.person.address.code"
                  render={() => (data.billingHolder.address !== undefined
                    ? data.billingHolder.address.code
                    : '')
                  }
                />
                <ColumnOutput
                  label="model.person.address.city"
                  render={() => (data.billingHolder.address !== undefined ? data.billingHolder.address.city : '')
                  }
                />
                <ColumnOutput
                  label="model.person.address.country"
                  render={() => (data.billingHolder.address !== undefined ? <Translated k={`model.country.${data.billingHolder.address.country}`} /> : '')
                  }
                />
              </div>
            </div>

            <div className="field is-grouped is-grouped-right">
              {!readOnly && (
                <ButtonModal
                  buttonClassName="is-default"
                  buttonLabel="pages.shared.orders.billingDetails.edit"
                  render={({ handleClose }) => (
                    <Fragment>
                      <header className="modal-card-head">
                        <p className="modal-card-title">
                          <Translated k="pages.shared.orders.billingDetails.edit" />
                        </p>
                        <button
                          type="button"
                          className="delete"
                          aria-label="close"
                          onClick={handleClose}
                        />
                      </header>
                      <OrderBillingDetailsEditForm
                        onCancel={handleClose}
                        initialValues={data.billingHolder}
                        onSuccess={(billingHolder) => {
                          updateBillingHolder(data, billingHolder, handleSuccess, showError);
                          handleClose();
                        }}
                      />
                    </Fragment>
                  )}
                />
              )}
            </div>
          </Fragment>
        )}
      </Fragment>
    )}
  />
);

export default BillingInformationOverviewDetails;
